/**
 * This should not be a constant but since we're still in testing stages
 * these are subject to change (or just test URLs)
 */
export const MONS_DOMAINS = [
  'alpha.cbms.agl.amazon.dev',
  'beta.cbms.agl.amazon.dev',
  'gamma.cbms.agl.amazon.dev',
];

export const isMonsEnv = (): boolean => {
  const domain = window.location.hostname;
  return MONS_DOMAINS.includes(domain);
};
