import React, { useState } from 'react';
import { Button, Form, message, Modal, Result, Spin } from 'antd';
import { Document, Page, pdfjs } from 'react-pdf';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { useQuery, useMutation, gql } from '@apollo/client';
import { SignBox } from '@xbcb/display-components';
import {
  DocumentSignRequestStatus,
  DocumentSignatureMethod,
} from '@xbcb/document-types';
import { Attributions } from '@xbcb/static-text-components';
import { showValidationErrors, shouldUpdate } from '@xbcb/ui-utils';
import StampBasedSignFields from '../StampBasedSignFields';
import {
  StyledContentDiv,
  StyledContentMainSignDiv,
  StyledSignFormDiv,
  PDFDocumentWrapper,
  StyledBottomContentSignDiv,
} from './styles';
import { cleanString } from '@xbcb/js-utils';
import { FormattedPoaType, PoaType } from '@xbcb/shared-types';
import { officerTitleWarning } from '@xbcb/form-item-components';
import { invalidName } from '../lib/invalidName';

// for react-pdf to work properly, we need to enable a PDF.js worker
// https://github.com/wojtekmaj/react-pdf/issues/321, https://github.com/wojtekmaj/react-pdf#enable-pdfjs-worker
// TODO include PDFjs in our bundle to remove reliance on a 3rd party CDN;
// if still relying on the CDN in the future, create a checksum of the package contents
// and verify against it here to prevent any malicious code from being loaded
// Using https and changed to pdf.worker.min.js which is half the size
// https://github.com/wojtekmaj/react-pdf/issues/321#issuecomment-568281683 https://github.com/wojtekmaj/react-pdf#create-react-app
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const englishCharacterRegex = new RegExp("^[\\w\\s,().'-]*$");

interface StampBasedSignProps {
  id: string;
  attribution: string;
}

const UPDATE_DOCUMENT_SIGN_REQUEST_WITH_SIGNATURE = gql`
  mutation UpdateDocumentSignRequestWithSignature(
    $version: Int!
    $id: ID!
    $signature: DocumentSignatureInput!
    $signerName: String!
    $signerTitle: String!
  ) {
    updateStampDocumentSignRequestWithSignature(
      version: $version
      id: $id
      signature: $signature
      signerName: $signerName
      signerTitle: $signerTitle
    ) {
      record {
        id
      }
    }
  }
`;

const GET_STAMP_DOCUMENT_SIGN_REQUEST = gql`
  query GetStampDocumentSignRequest($id: ID!) {
    stampDocumentSignRequest(id: $id) {
      id
      version
      status
      deleted {
        time
      }
      signerName
      signerTitle
      expireTime
      sourceDocument {
        id
        deleted {
          time
        }
        content {
          downloadLink
        }
      }
    }
  }
`;

const StampBasedSign = ({ id, attribution }: StampBasedSignProps) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  // currently using this to conditionally display Submit button and disable input fields
  const [isDocumentLoaded, setDocumentLoaded] = useState(false);
  // signature method number strings: 1 = draw, 2 = type, 3 = upload
  const [signatureMethod, setSignatureMethod] = useState(
    DocumentSignatureMethod.TYPE,
  );
  // the typed signature if user signs using the type method
  const [typedSignature, setTypedSignature] = useState('');
  const [form] = Form.useForm();

  // Validate immediately to highlight required fields in red
  form.validateFields();

  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(GET_STAMP_DOCUMENT_SIGN_REQUEST, {
    variables: { id },
  });
  const [
    updateStampDocumentSignRequest,
    { loading: mutationLoading, error: mutationError, data: mutationData },
  ] = useMutation(UPDATE_DOCUMENT_SIGN_REQUEST_WITH_SIGNATURE);
  /* if (loading) return <p>Loading ...</p>; */
  const {
    version,
    status,
    deleted,
    sourceDocument,
    expireTime,
    signerName,
    signerTitle,
  } = queryData?.stampDocumentSignRequest || {};

  // prepopulate the signerName and Title, if queried data is valid
  const signerNameValue = cleanString(signerName);
  const signerTitleValue = cleanString(signerTitle);

  if (signerNameValue && !invalidName(signerNameValue)) {
    form.setFieldsValue({ signerName: signerNameValue });
  }
  const invalidSignerTitle = officerTitleWarning(
    signerTitleValue,
    FormattedPoaType[PoaType.MASTER_POA],
  );
  if (signerTitleValue && !invalidSignerTitle) {
    form.setFieldsValue({ signerTitle: signerTitleValue });
  }

  const onDocumentLoadSuccess = (pdf: PDFDocumentProxy) => {
    setDocumentLoaded(true);
    setNumPages(pdf.numPages);
  };
  const onNextPageClick = () => {
    const nextPageNumber = pageNumber + 1;
    if (nextPageNumber <= numPages) {
      setPageNumber(nextPageNumber);
    }
  };
  const onPrevPageClick = () => {
    const prevPageNumber = pageNumber - 1;
    if (prevPageNumber > 0) {
      setPageNumber(prevPageNumber);
    }
  };
  const showPageButtons = () => {
    return (
      <>
        <Button type="primary" onClick={onPrevPageClick}>
          Prev Page
        </Button>{' '}
        <Button type="primary" onClick={onNextPageClick}>
          Next Page
        </Button>
      </>
    );
  };
  const submitSignature = async () => {
    const finalSignature = form.getFieldValue('signature');
    if (!finalSignature) {
      message.error('Please sign the document first.');
    } else {
      // https://ant.design/components/form/#validateFields-return-sample
      try {
        const values = await form.validateFields([
          'signerName',
          'signerTitle',
          'signature',
        ]);
        const { signature: signatureData, signerName, signerTitle } = values;
        if (
          !(
            englishCharacterRegex.test(signerName) &&
            englishCharacterRegex.test(signerTitle)
          )
        ) {
          Modal.warning({
            title:
              'Officer name and title should be entered in English language only.',
          });
        } else {
          try {
            await updateStampDocumentSignRequest({
              variables: {
                id,
                version,
                signerName,
                signerTitle,
                signature: {
                  method: signatureMethod,
                  typedSignature,
                  data: signatureData,
                },
              },
            });
          } catch (updateError) {
            // error page will render because mutationError will be defined
          }
        }
      } catch (e) {
        const errorInfo = e as any;
        const errorFields = errorInfo.errorFields;
        const errorMessages: string[] = [];
        errorFields.forEach((errorField: any) => {
          const errorMessage = (errorField.errors as string[]).join(', ');
          errorMessages.push(errorMessage);
        });
        showValidationErrors([
          { title: 'Invalid Fields', messages: errorMessages },
        ]);
      }
    }
  };
  // sign function is triggered when the user enters their signature on the SignBox
  const sign = (imageData: any, method: any, typed: any) => {
    form.setFieldsValue({ signature: imageData });
    if (typed !== typedSignature) {
      setTypedSignature(typed);
    }
    if (method !== signatureMethod) {
      setSignatureMethod(method);
    }
  };

  let errorMessage = 'There was an error with this document';
  if (expireTime < new Date().toISOString()) {
    errorMessage = 'This link has expired, please request a new link.';
  }

  return (
    <Form form={form}>
      <StyledContentDiv>
        {queryLoading ? (
          <Spin size="large" />
        ) : queryError ||
          mutationError ||
          expireTime < new Date().toISOString() ||
          status === DocumentSignRequestStatus.VOID ||
          deleted ||
          sourceDocument?.deleted ? (
          <Result status="error" title="Error" subTitle={errorMessage} />
        ) : status === DocumentSignRequestStatus.SIGNED || mutationData ? (
          <Result
            status="success"
            title="Success!"
            subTitle="The document has been signed"
          />
        ) : (
          <StyledContentMainSignDiv>
            <StampBasedSignFields
              disabled={!isDocumentLoaded} // in legacy, the original indicator isFetching was meant for tracking the progress of executing sign queries, which is a TODO
              form={form}
            />
            <StyledSignFormDiv>
              <PDFDocumentWrapper>
                {numPages > 1 && showPageButtons()}
                <Document
                  file={
                    queryData?.stampDocumentSignRequest?.sourceDocument?.content
                      ?.downloadLink
                  }
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadError={(error) =>
                    message.error(
                      `Failed to load PDF file with reason: ${error.message}`,
                    )
                  }
                >
                  <Page height={1200} pageNumber={pageNumber} />
                </Document>
              </PDFDocumentWrapper>
              <Form.Item
                shouldUpdate={shouldUpdate([
                  ['signerTitle'],
                  ['signerName'],
                  ['signature'],
                ])}
                noStyle
              >
                {() => <SignBox submitSignature={sign} form={form} />}
              </Form.Item>
            </StyledSignFormDiv>
            <StyledBottomContentSignDiv>
              {isDocumentLoaded && (
                <Button
                  className="signature-submit"
                  type="primary"
                  size="large"
                  onClick={submitSignature}
                >
                  Submit
                </Button>
              )}
              <div className="space-top">
                <Attributions attribution={attribution} />
              </div>
            </StyledBottomContentSignDiv>
          </StyledContentMainSignDiv>
        )}
      </StyledContentDiv>
    </Form>
  );
};

export default StampBasedSign;
