import React from 'react';
import { GraphQLResponse } from 'apollo-server-types';
import moment from 'moment';
import { message, Modal, Popover, Tooltip } from 'antd';
import BugOutlined from '@ant-design/icons/BugOutlined';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import FileDoneOutlined from '@ant-design/icons/FileDoneOutlined';
import ThunderboltOutlined from '@ant-design/icons/ThunderboltOutlined';
import { ApolloError, useMutation } from '@apollo/client';
import { documentFragments } from '@xbcb/document-queries';
import {
  DocumentAntivirusScanStatus,
  DocumentPasswordStatus,
  DocumentTag,
} from '@xbcb/document-types';
import {
  deleteOneMutation,
  getRecordFromResponse,
  SearchQuery,
  SearchType,
  createSearchQueryVariables,
} from '@xbcb/shared-queries';
import { RecordType, Tag } from '@xbcb/shared-types';
import { reportError, useModal } from '@xbcb/ui-utils';
import { Datum, DocumentDisSubmissions } from '@xbcb/display-components';
import { KebabMenu } from '@xbcb/button-components';
import { formatBytes } from '@xbcb/js-utils';
import {
  mutateRecord,
  retryMutation,
  updateQueryInCache,
} from '@xbcb/apollo-client';
import {
  StyledButton,
  StyledDocumentActionsDiv,
  StyledDeleteOutlined,
  StyledLockOutlined,
} from './styles';
import { useParams } from 'react-router-dom';
import { DocumentPermissions, ModalKey } from '@xbcb/ui-types';

export interface DocumentActionsProps {
  document: any;
  handleEdit: any;
  isUploading?: boolean;
  readOnly?: boolean;
  setLoading: any;
  searchDeletedDocuments?: boolean;
  searchQueryTags: Tag[];
  showInquireWorkOrderKebabMenuItem?: boolean;
  showInvoiceLineCountKebabMenuItem?: boolean;
  // Intentionally takes operatorId so that it is not tied to one way of
  // determining the operatorId. For example, in NewAppUi we often get it from
  // the currentUser but in ImportSign we get it from the queried document
  operatorId: string;
  // Intentionally takes permissions so that it is not tied to one way of
  // determining the permissions. For example, in NewAppUi we often get it from
  // the currentUser but in ImportSign we always allow full permissions since there
  // is not a signed in user
  permissions: DocumentPermissions;
  documentTags: DocumentTag[];
}

const getScaleDocumentUrl = (scaleDocumentId: string): string => {
  return `https://dashboard.scale.com/audit?unique_id=${scaleDocumentId}`;
};

const DocumentActions = ({
  document,
  handleEdit,
  isUploading,
  readOnly,
  setLoading,
  searchDeletedDocuments,
  searchQueryTags,
  showInquireWorkOrderKebabMenuItem,
  showInvoiceLineCountKebabMenuItem,
  operatorId,
  permissions,
  documentTags,
}: DocumentActionsProps) => {
  const {
    antivirusScanStatus,
    created,
    deleted,
    extension,
    fileName,
    id,
    passwordStatus,
    size,
    updated,
    version,
    disSubmissions,
    extractedDocumentContent,
  } = document;

  const { recordId } = useParams<{ recordId: string }>();
  const isUsEntryPage = recordId
    ? recordId.startsWith('usConsumptionEntry')
    : false;

  const isEuEntryPage = recordId ? recordId.includes('CustomsEntry') : false;
  const isUsCommercialInvoicesTagged =
    documentTags.includes(DocumentTag.COMMERCIAL_INVOICE) && isUsEntryPage;
  const { openModal: openDocumentModal, setModal: setDocumentModal } = useModal(
    ModalKey.SEND_DOCUMENT,
  );
  const {
    openModal: openInquireWorkOrderModal,
    setModal: setInquireWorkOrdertModal,
  } = useModal(ModalKey.INQUIRE_WORK_ORDER);

  const {
    openModal: openInvoiceLineCountModal,
    setModal: setInvoiceLineCountModal,
  } = useModal(ModalKey.INVOICE_LINE_COUNT);

  const searchQuery = SearchQuery({
    recordName: RecordType.DOCUMENT,
    fields: '...documentFields',
    fragments: documentFragments,
  });
  const sortOptions = [
    {
      field: 'createdTime',
    },
  ];
  const params = { operatorId, tags: searchQueryTags, sortOptions };
  const searchQueryVariables = createSearchQueryVariables({
    deletedTimeExists: searchDeletedDocuments,
    ...params,
  });
  const deletedRecordSearchQueryVariables = createSearchQueryVariables({
    deletedTimeExists: true,
    ...params,
  });

  const updateCacheAndPrintSuccess = (response: GraphQLResponse) => {
    const record = getRecordFromResponse(
      response,
      'delete',
      RecordType.DOCUMENT,
    );
    updateQueryInCache({
      crudType: 'create',
      gqlQueryString: searchQuery,
      record,
      recordType: RecordType.DOCUMENT,
      updatedQueryType: 'search',
      variables: deletedRecordSearchQueryVariables,
    });
    message.success(
      `${fileName}.${extension.toLowerCase()} has been successfully deleted.`,
      5.0,
    );
    setLoading(false);
  };
  const scaleDocumentId: string =
    extractedDocumentContent?.externalDocumentReference?.id;

  const optimisticUpdateParams = {
    gqlQueryString: searchQuery,
    updatedQueryType: 'search' as SearchType,
    variables: searchQueryVariables,
  };
  const [deleteDocument] = useMutation(
    deleteOneMutation({
      recordName: RecordType.DOCUMENT,
      fields: '...documentFields',
      fragments: documentFragments,
    }),
    {
      onError: async (error: ApolloError) => {
        try {
          const response = await retryMutation({
            fields: '...documentFields',
            fragments: documentFragments,
            id,
            mutation: deleteDocument,
            mutationType: 'delete',
            mutationVariables: { id },
            recordType: RecordType.DOCUMENT,
            optimisticUpdateParams,
          });
          if (response) {
            updateCacheAndPrintSuccess(response);
          }
        } catch (e) {
          message.error(
            'Sorry, an error has occurred. Please try again later.',
            5.0,
          );
          setLoading(false);
        }
      },
    },
  );

  const handleDelete = async () => {
    Modal.confirm({
      title: (
        <div>
          <StyledDeleteOutlined /> Are you sure you want to delete this
          document?
        </div>
      ),
      content: fileName,
      width: '512px',
      okText: 'Yes',
      okButtonProps: {
        danger: true,
      },
      cancelText: 'No',
      maskClosable: true,
      onOk: async () => {
        setLoading(true);
        try {
          const response = await mutateRecord({
            mutationType: 'delete',
            mutation: deleteDocument,
            mutationVariables: {
              id,
              version,
            },
            overwriteOptimisticRecordFields: document,
            optimisticUpdateParams,
            recordType: RecordType.DOCUMENT,
          });
          if (response) {
            updateCacheAndPrintSuccess(response);
          }
        } catch (e) {
          reportError(e);
          message.error(
            'Sorry, an error has occurred. Please try again later.',
            5.0,
          );
          setLoading(false);
        }
      },
    });
  };

  const handleSendDocument = () => {
    setDocumentModal({ document });
    openDocumentModal();
  };
  const handleInquireWorkOrder = () => {
    setInquireWorkOrdertModal({ document });
    openInquireWorkOrderModal();
  };
  const handleInvoiceLineCount = () => {
    setInvoiceLineCountModal({ document });
    openInvoiceLineCountModal();
  };

  const deleteItem = {
    label: 'Delete',
    action: handleDelete,
  };
  const editItem = {
    label: 'Edit',
    action: handleEdit,
  };
  const kebabItems = [];

  if (permissions.UPDATE) {
    kebabItems.push(editItem);
  }
  // if (antivirusScanStatus === DocumentAntivirusScanStatus.CLEAN) {
  //   kebabItems.push(editItem);
  // }
  if (showInvoiceLineCountKebabMenuItem && isUsCommercialInvoicesTagged) {
    const invoiceLineCount = {
      label: 'Invoice line count',
      action: handleInvoiceLineCount,
    };

    kebabItems.push(invoiceLineCount);
  }

  const isEuCustomsEntryConfirmationDocumentTag =
    documentTags.includes(DocumentTag.CUSTOMS_ENTRY) ||
    documentTags.includes(DocumentTag.CUSTOMS_ENTRY_DRAFT);

  if (
    isUsEntryPage ||
    (isEuEntryPage && isEuCustomsEntryConfirmationDocumentTag)
  ) {
    const sendDocument = {
      label: isEuEntryPage ? 'Send to Seller' : 'Send to CBP',
      action: handleSendDocument,
    };

    kebabItems.push(sendDocument);
  }
  if (showInquireWorkOrderKebabMenuItem) {
    const inquireWorkOrder = {
      label: 'Inquire',
      action: handleInquireWorkOrder,
    };
    kebabItems.push(inquireWorkOrder);
  }
  if (permissions.DELETE) {
    kebabItems.push(deleteItem);
  }

  const uploadedAt =
    created?.time && moment(created.time).format('MM/DD/YY h:mm a');
  const updatedAt =
    updated?.time && moment(updated.time).format('MM/DD/YY h:mm a');
  const displaySize = size && formatBytes(size);

  const passwordIcon = (
    <Tooltip placement="bottom" title="Requires a password.">
      <StyledLockOutlined />
    </Tooltip>
  );
  const infectedIcon = (
    <Tooltip
      placement="bottom"
      title="This file was found to have viruses. You won't be able to download it."
    >
      <StyledButton icon={<BugOutlined />} shape="circle" danger />
    </Tooltip>
  );
  const extraDisInfo = (
    <DocumentDisSubmissions disSubmissions={disSubmissions} />
  );
  const extraInfo = (
    <table>
      <tbody>
        {uploadedAt && (
          <Datum key="uploaded" label="Uploaded" value={uploadedAt} />
        )}
        {created?.client?.name && (
          <Datum
            key="createdByName"
            label="Uploader"
            value={created.client.name}
          />
        )}
        {updatedAt !== uploadedAt && (
          <Datum key="updated" label="Updated" value={updatedAt} />
        )}
        {displaySize && <Datum key="size" label="Size" value={displaySize} />}
      </tbody>
    </table>
  );
  return (
    <StyledDocumentActionsDiv>
      {scaleDocumentId && (
        <StyledButton
          $color="black"
          icon={<ThunderboltOutlined twoToneColor="black" />}
          href={getScaleDocumentUrl(scaleDocumentId)}
        />
      )}
      {passwordStatus === DocumentPasswordStatus.PROTECTED && passwordIcon}
      {antivirusScanStatus === DocumentAntivirusScanStatus.INFECTED &&
        infectedIcon}
      {disSubmissions && (
        <Popover content={extraDisInfo} placement="bottom">
          <StyledButton
            $color="black"
            icon={<FileDoneOutlined />}
            shape="circle"
          />
        </Popover>
      )}

      <Popover content={extraInfo} placement="bottom">
        <StyledButton
          $color="black"
          icon={<InfoCircleOutlined />}
          shape="circle"
        />
      </Popover>
      {!readOnly && !deleted && kebabItems.length ? (
        <KebabMenu items={kebabItems} loading={isUploading} />
      ) : null}
    </StyledDocumentActionsDiv>
  );
};

export default DocumentActions;
