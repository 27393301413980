import React from 'react';
import { message } from 'antd';
import { SelectProps, SelectValue } from 'antd/lib/select';
import { primaryTheme } from '@xbcb/ui-styles';
import { selectFilter, show } from '@xbcb/ui-utils';
import { Select } from '../ClickSelect';
import FormItem from '../FormItem';
import { FormInstance } from 'antd/lib/form';
import {
  CssSize,
  NamePath,
  createDataCyValue,
  DataCyPrefix,
  DataCySuffix,
} from '@xbcb/ui-types';

export interface SimpleSelectProps<ValueType extends SelectValue = SelectValue>
  extends SelectProps<ValueType> {
  form: FormInstance;
  localNamePath: NamePath;
  fullNamePath: NamePath;
  readOnly?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  map?: any;
  selectOptions?: (string | number)[];
  max?: number;
  label?: string;
  dropdownMatchSelectWidth?: boolean | number;
  $itemSize?: CssSize | string;
  required?: boolean;
  showArrow?: boolean;
  showSearch?: boolean;
  inline?: boolean;
  initialValue?: string | string[] | number | number[];
  dataCySuffix?: DataCySuffix;
}

const Option = Select.Option;

const SimpleSelect: React.FC<SimpleSelectProps> = ({
  form,
  localNamePath,
  fullNamePath,
  readOnly,
  multiple,
  map,
  selectOptions,
  $itemSize = primaryTheme.size.tiny,
  required = true,
  label,
  max,
  inline,
  initialValue,
  dataCySuffix,
  ...threadedProps
}) => {
  const optionMap = (option: any) => {
    if (typeof map === 'function') {
      return map(option);
    } else if (typeof map === 'object') {
      return map[option];
    }
    return option;
  };
  if (!selectOptions) selectOptions = Object.keys(map);
  const options = selectOptions.map((option: string | number) => {
    return (
      <Option
        key={option}
        value={option}
        data-cy={createDataCyValue(
          DataCyPrefix.SIMPLE_SELECT_OPTION,
          option as string,
        )}
      >
        {optionMap(option)}
      </Option>
    );
  });

  const getValueFromEvent =
    multiple && max
      ? (value: any) => {
          if (value && value.length > max) {
            message.warning(`Maximum ${max} items allowed`);
            return value.slice(0, max);
          } else {
            return value;
          }
        }
      : undefined;

  return show({ readOnly, form, field: fullNamePath }) ? (
    <FormItem
      $itemSize={$itemSize}
      name={localNamePath}
      rules={[{ required, message: ' ' }]}
      getValueFromEvent={getValueFromEvent}
      $readOnly={readOnly}
      label={label}
      $inline={inline}
      initialValue={initialValue}
      data-cy={createDataCyValue(DataCyPrefix.SIMPLE_SELECT, dataCySuffix)}
    >
      <Select
        notFoundContent="None Found"
        filterOption={selectFilter}
        mode={multiple ? 'multiple' : undefined}
        {...threadedProps}
      >
        {options}
      </Select>
    </FormItem>
  ) : null;
};

export default SimpleSelect;
