import React from 'react';
import { Input, Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { capitalCase } from 'change-case';
import { FormItem, Email, PhoneNumber } from '@xbcb/form-item-components';
import {
  CssSize,
  NamePath,
  DataCySuffix,
  DataCyPrefix,
  createDataCyValue,
} from '@xbcb/ui-types';
import { isRequired, shouldUpdate } from '@xbcb/ui-utils';

const titleFieldName = 'title';
const nameFieldName = 'name';
const emailFieldName = 'email';
const phoneFieldName = 'phone';

type PointOfContactFields =
  | typeof titleFieldName
  | typeof nameFieldName
  | typeof emailFieldName
  | typeof phoneFieldName;

type RequiredMap = {
  [key in PointOfContactFields]?: boolean;
};

type PointOfContactProps = {
  readOnly?: boolean;
  disabled?: boolean;
  form: FormInstance;
  allRequired?: boolean;
  enforceNamePattern?: boolean;
  fullNamePath: NamePath;
  localNamePath: NamePath;
  requiredMap?: RequiredMap;
  defaultCountryCode?: string;
  allowClearCountryCode?: boolean;
  hideTitle?: boolean;
  dataCySuffix?: DataCySuffix;
};

const PointOfContact: React.FC<PointOfContactProps> = ({
  readOnly,
  disabled,
  form,
  allRequired,
  enforceNamePattern,
  fullNamePath,
  localNamePath,
  requiredMap,
  defaultCountryCode,
  allowClearCountryCode = true,
  hideTitle,
  dataCySuffix,
}) => {
  const getIsRequired = (fieldName: PointOfContactFields) =>
    allRequired || requiredMap?.[fieldName];

  // Fields that the name field depends on (aka if any of these fields are set,
  // name is required)
  const nameDependentFields = [
    [...fullNamePath, titleFieldName],
    [...fullNamePath, emailFieldName],
    // We know `PhoneNumber` will force `number` to be required if need
    // be, thus simply check if this field is required if the number was
    // filled out
    [...fullNamePath, phoneFieldName, 'number'],
  ];
  return (
    <>
      {!hideTitle && (
        <div>
          <FormItem
            name={[...localNamePath, titleFieldName]}
            label={capitalCase(titleFieldName)}
            rules={[{ required: getIsRequired(titleFieldName), message: ' ' }]}
            $itemSize={CssSize.SHORT}
            $inline
            $readOnly={readOnly}
            data-cy={createDataCyValue(DataCyPrefix.TITLE, dataCySuffix)}
          >
            <Input disabled={disabled} />
          </FormItem>
        </div>
      )}
      <Form.Item noStyle shouldUpdate={shouldUpdate(nameDependentFields)}>
        {() => (
          <FormItem
            name={[...localNamePath, nameFieldName]}
            label={`Full ${capitalCase(nameFieldName)}`}
            rules={[
              {
                required:
                  // Required if the default required (getIsRequired) is true
                  // or if any of the fields it depends on
                  // (nameDependentFields) are currently set
                  getIsRequired(nameFieldName) ||
                  isRequired(form, nameDependentFields),
                message: ' ',
              },
              {
                pattern: enforceNamePattern
                  ? /^\w+\s+\w+(\s+\w+)?$/
                  : undefined,
              },
            ]}
            $itemSize={CssSize.SHORT}
            $inline
            $readOnly={readOnly}
            data-cy={createDataCyValue(DataCyPrefix.NAME, dataCySuffix)}
          >
            <Input disabled={disabled} />
          </FormItem>
        )}
      </Form.Item>
      <div>
        <Email
          readOnly={readOnly}
          disabled={disabled}
          required={getIsRequired(emailFieldName)}
          localNamePath={[...localNamePath, emailFieldName]}
          $inline
          $spaceBottom={false}
          dataCySuffix={dataCySuffix}
        />
        <PhoneNumber
          readOnly={readOnly}
          disabled={disabled}
          form={form}
          localNamePath={[...localNamePath, phoneFieldName]}
          fullNamePath={[...fullNamePath, phoneFieldName]}
          required={getIsRequired(phoneFieldName)}
          $inline
          $spaceBottom={false}
          defaultCountryCode={defaultCountryCode}
          allowClearCountryCode={allowClearCountryCode}
          dataCySuffix={dataCySuffix}
        />
      </div>
    </>
  );
};

export default PointOfContact;
