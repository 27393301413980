export const externalSystemAwsIntegrationStatusDescription =
  'The status of the AWS integration with the External System.';
export enum ExternalSystemAwsIntegrationStatus {
  INACTIVE = 'INACTIVE', // set on create by the authority if account ID is not provided
  PROCESSING = 'PROCESSING', // set on create by the authority if account ID is provided
  ACTIVE = 'ACTIVE', // set by Client Workflow after AWS resources have been created
}

export const productTypeDescription = `The product type`;
export enum ProductType {
  PREMIUM = 'PREMIUM',
  NON_PREMIUM = 'NON_PREMIUM',
  HOT = 'HOT',
  NON_HOT = 'NON_HOT',
  REFILE = 'REFILE',
  NON_REFILE = 'NON_REFILE',
}

export * from './permission';
export * from './onboardingRequest';
export * from './notification';
export * from './linkAction';
export * from './changeLog';
export * from './constants';
export * from './status';
