import React, { useState } from 'react';
import { Spin } from 'antd';
import { camelCase } from 'change-case';
import { isEqual, uniqWith } from 'lodash';
import { Tag, ObjectType, AccountType } from '@xbcb/shared-types';
import { Document, WorkOrderInquiry } from '@xbcb/api-gateway-client';
import { WorkOrderStatus } from '@xbcb/work-order-types';
import Doc from '../Doc';
import { EditDocModal } from '@xbcb/modal-components';
import UploadDocBox from '../UploadDocBox';
import {
  locationToObjectType,
  useModal,
  useSearchDocuments,
  retrySearchQueryUntilValid,
  usePrevious,
  getEnv,
} from '@xbcb/ui-utils';
import {
  createDataCyValue,
  DataCyPrefix,
  DataCySuffix,
  DocumentPermissions,
  ModalKey,
  UiStage,
} from '@xbcb/ui-types';
import { StyledDiv } from './styles';
import { isDocumentSupportedByScale } from '@xbcb/doc-utils/dist/isDocumentSupportedByScale';
import { DocumentTag, DocumentFileExtension } from '@xbcb/document-types';
import { documentFragments } from '@xbcb/document-queries';
import { useApolloClient } from '@apollo/client';
import { timeout } from '@xbcb/js-utils';

const areScaleSupportedDocumentsMissingExternalReference = (
  documents: Document[],
): boolean => {
  return documents.some((document) => {
    return (
      !document.externalDocumentReference &&
      isDocumentSupportedByScale(
        document.documentTags as DocumentTag[],
        document.extension as DocumentFileExtension,
      )
    );
  });
};

const findScaleSupportedDocumentIdsWithUpdatedDocumentTags = (
  previousDocuments: Document[],
  currentDocuments: Document[],
): string[] => {
  return currentDocuments
    .filter((currentDoc) => {
      if (
        !isDocumentSupportedByScale(
          currentDoc.documentTags as DocumentTag[],
          currentDoc.extension as DocumentFileExtension,
        )
      ) {
        return false;
      }

      const previousDoc = previousDocuments.find(
        (prevDoc) => prevDoc.id === currentDoc.id,
      );

      return !isEqual(currentDoc.documentTags, previousDoc?.documentTags);
    })
    .map((currentDoc) => currentDoc.id);
};

// Criteria for retrying queries in order to trigger re-render of component and show link to Scale.
// 1. Any supported Scale documents missing externalDocumentReference. This is to wait until doc is
// persisted into DMS which then updates the document with the Scale reference.
// 2. When supported docs have document tags that were updated. Document is updated into DMS with
// Scale reference.
const shouldRetrySearchQuery = (
  previousDocuments: Document[],
  currentDocuments: Document[],
  isRetrying: boolean,
): boolean => {
  const { stage } = getEnv();

  return (
    stage !== UiStage.PROD &&
    !isRetrying &&
    (areScaleSupportedDocumentsMissingExternalReference(currentDocuments) ||
      findScaleSupportedDocumentIdsWithUpdatedDocumentTags(
        previousDocuments,
        currentDocuments,
      ).length > 0)
  );
};

export const getRecordIdTag = ({
  pathname,
  recordId,
  fallbackRecordId,
  operatorId,
}: {
  pathname: string;
  recordId?: string;
  fallbackRecordId?: string;
  operatorId: string;
}) => {
  const objectType = locationToObjectType(pathname);
  const idName = objectType ? `${camelCase(objectType)}Id` : '';

  return {
    key: idName,
    // If recordId is not found in the pathname this means we are on a
    // "create" page. Thus, we typically fall back to operatorId to handle
    // the case where documents are uploaded on new records before they are
    // created (since they do not have an ID yet). But, in some rare cases
    // (like IOR onboarding) even though we are on a "create" page we know
    // the recordId because it has already been created. Thus, before we
    // fallback to the operatorId, check if a fallbackRecordId was provided
    value: recordId || fallbackRecordId || operatorId,
  };
};

// These are the props meant to be used at the "wrapper" level. They should
// likely be implemented once per service in addition (i.e. "additional") to
// the DocsCoreCommonProps (see below for more detail)
export type DocsCoreAdditionalProps = {
  showInquireWorkOrderKebabMenuItem?: boolean;
  showInvoiceLineCountKebabMenuItem?: boolean;
  // Intentionally takes operatorId so that it is not tied to one way of
  // determining the operatorId. For example, in NewAppUi we often get it from
  // the currentUser but in ImportSign we get it from the queried document
  operatorId: string;
  // Intentionally takes permissions so that it is not tied to one way of
  // determining the permissions. For example, in NewAppUi we often get it from
  // the currentUser but in ImportSign we always allow full permissions since there
  // is not a signed in user
  permissions: DocumentPermissions;
  // These tags should include at least one Tag (the recordIdTag) as well as
  // any `additionalTags` provided in the DocsCoreCommonProps. If not provided,
  // for backwards compatibility `searchQueryTags` will be used
  tagsForNewDocuments?: Tag[];
  // These tags should include at least one Tag (the recordIdTag) as well as
  // any `searchQueryTags` provided in the DocsCoreCommonProps. Thus, they are
  // required here but not in DocsCoreCommonProps
  searchQueryTags: Tag[];
  accountType?: AccountType;
};

// These are the common props that can be configured in each use case (i.e.
// passed into the wrapper component)
export type DocsCoreCommonProps = {
  fullWidth?: boolean;
  searchDeletedDocuments?: boolean;
  workOrderStatus?: WorkOrderStatus;
  workOrderInquiries?: WorkOrderInquiry[];
  // By default, we always provide a recordIdTag when creating or searching for
  // documents. If we are on a "create" page we fallback to the operatorId (see
  // the logic inside getRecordIdTag above where we create a tag with key set
  // equal to `idName`) since there would not be a recordId in the URL. But,
  // there are some edge cases where even though the pathname has create in it
  // and does not contain a recordId (for example `/us-iors/create`) we want to
  // search and create documents with the usIorId since we know it has already
  // been created. Thus, if `fallbackRecordId` is provided we will use it as a
  // fallback _if_ there isn't a `recordId`in the pathname before relying on
  // operatorId
  fallbackRecordId?: string;
  // These are additional Tags that should be added to the documents upon
  // creation. They are in _addition_ to the default recordIdTag.
  additionalTags?: Tag[];
  // These are any other tags that should be included in searchQueryTags, by
  // default we only search for the recordIdTag
  searchQueryTags?: Tag[];
};

export type DocsCoreProps = DocsCoreCommonProps & DocsCoreAdditionalProps;

const DocsCore = ({
  fullWidth,
  searchDeletedDocuments,
  workOrderStatus,
  workOrderInquiries,
  operatorId,
  permissions,
  showInquireWorkOrderKebabMenuItem,
  showInvoiceLineCountKebabMenuItem,
  tagsForNewDocuments,
  searchQueryTags,
  accountType,
}: DocsCoreProps): JSX.Element | null => {
  const [isUploading, setIsUploading] = useState(false);
  const { visible } = useModal(ModalKey.EDIT_DOCUMENT);
  const [isRetryingQuery, setIsRetryingQuery] = useState(false);
  const { documents, loading } = useSearchDocuments({
    searchDeletedDocuments,
    operatorId,
    tags: searchQueryTags,
  });
  const isShipper = accountType ? accountType === AccountType.SHIPPER : false;
  // don't show the documents with the following tags if accountType is SHIPPER
  const documentTags = [DocumentTag.INVOICE, DocumentTag.ARRIVAL_NOTICE];
  const client = useApolloClient();

  const previousDocuments = usePrevious(documents) || [];

  const handleRetrySearchQuery = (): void => {
    setIsRetryingQuery(true);
    // Validates that no document is missing externalDocumentReference to ensure all
    // documents have been persisted to DMS.
    let validator = ({ results }: { results: Document[] }) =>
      !areScaleSupportedDocumentsMissingExternalReference(results);
    if (!areScaleSupportedDocumentsMissingExternalReference(documents)) {
      // Validates that documents that got documentTag updates are no longer missing
      // extractedDocumentContent where Scale reference is added
      validator = ({ results }: { results: Document[] }) => {
        const docIdsMissingScaleReference =
          findScaleSupportedDocumentIdsWithUpdatedDocumentTags(
            previousDocuments,
            documents,
          );

        return !results.some(
          (document) =>
            docIdsMissingScaleReference.includes(document.id) &&
            !document.extractedDocumentContent,
        );
      };
    }

    /**
     * Scale reference is added while persisting document to DMS. Wait a few seconds before retrying
     * queries to give time for Scale reference to be sent to DMS to enable Scale processing.
     */
    timeout(3000).then(() => {
      retrySearchQueryUntilValid({
        fields: '...documentFields',
        operatorId,
        tags: searchQueryTags.sort((tag1, tag2) =>
          tag1.key.localeCompare(tag2.key),
        ),
        recordName: ObjectType.DOCUMENT,
        fragments: documentFragments,
        client,
        retries: 3,
        timeoutDelay: 3000,
        validator,
      }).then(() => {
        setIsRetryingQuery(false);
      });
    });
  };

  if (shouldRetrySearchQuery(previousDocuments, documents, isRetryingQuery)) {
    handleRetrySearchQuery();
  }

  return (
    <StyledDiv>
      {/* TODO let the visibility be handled through the visible prop. Ideally, the modal should be re-rendering  the destroyOnClose modal prop but it keeps displaying stale data. */}
      {visible && (
        <EditDocModal
          searchDeletedDocuments={searchDeletedDocuments}
          searchQueryTags={searchQueryTags}
          visible={visible}
          operatorId={operatorId}
        />
      )}
      {loading ? (
        <Spin />
      ) : (
        <>
          {permissions.READ &&
            documents.map(
              (document) =>
                (!isShipper ||
                  (isShipper &&
                    !documentTags?.some((documentTag) =>
                      document.documentTags?.includes(documentTag),
                    ))) && (
                  <Doc
                    key={document.id}
                    document={document}
                    isUploading={isUploading}
                    searchDeletedDocuments={searchDeletedDocuments}
                    searchQueryTags={searchQueryTags}
                    fullWidth={fullWidth}
                    showInquireWorkOrderKebabMenuItem={
                      showInquireWorkOrderKebabMenuItem
                    }
                    showInvoiceLineCountKebabMenuItem={
                      showInvoiceLineCountKebabMenuItem
                    }
                    workOrderStatus={workOrderStatus}
                    workOrderInquiries={workOrderInquiries}
                    operatorId={operatorId}
                    permissions={permissions}
                  />
                ),
            )}
          {!searchDeletedDocuments && permissions.CREATE && (
            <UploadDocBox
              key="upload"
              fullWidth={fullWidth || !documents.length}
              isUploading={isUploading}
              searchDeletedDocuments={searchDeletedDocuments}
              setIsUploading={setIsUploading}
              // Fallback to searchQueryTags if tagsForNewDocuments was not
              // provided. This is for backwards compatibility.
              tagsForNewDocuments={uniqWith(
                [...(tagsForNewDocuments || []), ...searchQueryTags],
                isEqual,
              )}
              searchQueryTags={searchQueryTags}
              multiple
              operatorId={operatorId}
              dataCy={createDataCyValue(
                DataCyPrefix.WORK_ORDER_DOCUMENTS,
                DataCySuffix.DOCUMENT_UPLOAD_INPUT,
              )}
            />
          )}
        </>
      )}
    </StyledDiv>
  );
};

export default DocsCore;
