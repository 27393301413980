import { FeatureFlagService } from '../featureFlagService';
import { Stage } from '@xbcb/core';

const MULTI_BROKER_LOGIN_FEATURE = new FeatureFlagService([
  {
    feature: 'MULTI_BROKER_LOGIN_FEATURE',
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA],
  },
]);

export { MULTI_BROKER_LOGIN_FEATURE };
