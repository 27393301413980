import React from 'react';
import { Attributions } from '@xbcb/static-text-components';
import attribution from 'assets/attribution.txt';
import { StyledH1, StyledH2, StyledEditOutlined } from './styles';

const Home = () => (
  <>
    <StyledH1>Import Sign</StyledH1>
    <StyledEditOutlined />
    <StyledH2>A better way to sign power of attorney documents.</StyledH2>
    <Attributions attribution={attribution} />
  </>
);

export default Home;
