import React from 'react';
import moment from 'moment';
import {
  SubmitUsConsumptionEntryDisTransaction,
  DisSubmission,
} from '@xbcb/api-gateway-client';
import { StyledTable } from './styles';

interface DocumentDisSubmissionsProps {
  disSubmissions: DisSubmission[];
}

const DocumentDisSubmissions = ({
  disSubmissions,
}: DocumentDisSubmissionsProps) => {
  const getTimestamp = (iso: string) =>
    moment(iso).format('MMM D YYYY, h:mm a zz');

  const buildColumns = () => {
    return [
      {
        title: 'Username',
        key: 'username',
        dataIndex: 'username',
        width: 'auto',
        render: (text: string, record: any) => {
          return <div>{text}</div>;
        },
      },
      {
        title: 'Submit Time',
        key: 'submitTime',
        dataIndex: 'submitTime',
        width: 'auto',
        render: (text: string, record: any) => {
          return <div>{getTimestamp(text)}</div>;
        },
      },
      {
        title: 'DIS Code',
        key: 'disCode',
        dataIndex: 'disCode',
        width: 'auto',
        render: (text: string, record: any) => {
          return <div>{text}</div>;
        },
      },
      {
        title: 'DIS Status',
        key: 'disStatus',
        dataIndex: 'disStatus',
        width: 'auto',
        render: (text: string, record: any) => {
          return <div>{text}</div>;
        },
      },
    ];
  };

  const buildData = () => {
    return disSubmissions?.map((disSubmission, index) => {
      const { transaction, disDocName, disStatus } = disSubmission;
      const { time, client } = (
        transaction as SubmitUsConsumptionEntryDisTransaction
      ).created;
      return {
        key: transaction.id,
        disCode: disDocName,
        disStatus,
        submitTime: time,
        username: client?.name,
      };
    });
  };

  return (
    <StyledTable
      columns={buildColumns()}
      dataSource={buildData()}
      rowKey={(record: any) => record.key}
      pagination={false}
    />
  );
};

export default DocumentDisSubmissions;
