// AWS Accounts of external clients which are allowed to integrate with and call CBMS APIs
// To be updated as per https://quip-amazon.com/884vAa2l6og0/Freight-Forwarder-CBMS-API-Integration
export const externalClientAccountMapping = {
  alpha: [],
  bravo: [],
  beta: [],
  gamma: [
    '850033265654', // Ecommerce_Logistics
    '281981020878', // EX_Freight
    '850033265654', // Fort_Lauderdale
    '997842019962', // Shippabo
    '540879983819', // Shippabo
  ],
  prod: [],
};
