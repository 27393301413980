import React from 'react';
import { Switch } from 'antd';
import { NamePath } from 'rc-field-form/lib/interface';
import { createDataCyValue, DataCyPrefix, DataCySuffix } from '@xbcb/ui-types';
import { StyledFormItem } from './styles';

interface SwitchProps {
  readOnly?: boolean;
  disabled?: boolean;
  initialValue?: boolean;
  field: NamePath;
  text?: string;
  onChange?: any;
  required?: boolean;
  label?: string;
  onWord?: string;
  offWord?: string;
  loading?: boolean;
  $inline?: boolean;
  size?: 'default' | 'small';
  className?: string;
  dataCySuffix?: DataCySuffix;
}
const INLTSwitch: React.FC<SwitchProps> = ({
  readOnly,
  disabled,
  initialValue,
  label,
  onWord = 'On',
  offWord = 'Off',
  field,
  $inline,
  /* text, TODO ADD THIS BACK! We use to put text _inside_ of the switch itself, but antd changed the typing of the Switch component, preventing us from doing it */
  onChange,
  required,
  loading,
  size,
  className,
  dataCySuffix,
}) => (
  <StyledFormItem
    name={field}
    rules={[{ required, message: ' ' }]}
    valuePropName="checked"
    label={label}
    noStyle
    $inline
    $readOnly={readOnly}
    initialValue={initialValue}
    className={className}
    data-cy={createDataCyValue(DataCyPrefix.SWITCH, dataCySuffix)}
  >
    <Switch
      disabled={disabled}
      checkedChildren={onWord}
      unCheckedChildren={offWord}
      onChange={onChange}
      loading={loading}
      size={size}
    />
  </StyledFormItem>
);

export default INLTSwitch;
