import React from 'react';
import { FormInstance } from 'antd/lib/form';
import { Vertical } from '@xbcb/shared-types';
import {
  NamePath,
  CssSize,
  createDataCyValue,
  DataCyPrefix,
} from '@xbcb/ui-types';
import { show } from '@xbcb/ui-utils';
import { Select, Option } from '../ClickSelect';
import FormItem from '../FormItem';

// TODO remove this and use getCodes
export const verticalCodes = {
  [Vertical.AGRICULTURE_PREP]: 'Agriculture & Prepared Products',
  [Vertical.APPAREL_TEXTILES]: 'Apparel, Footwear & Textiles',
  [Vertical.AUTOMOTIVE_AERO]: 'Automotive & Aerospace',
  [Vertical.BASE_METALS]: 'Base Metals',
  [Vertical.CONSUMER_MERCHANDISE]: 'Consumer Products & Mass Merchandising',
  [Vertical.ELECTRONICS]: 'Electronics',
  [Vertical.INDUSTRIAL_MANUFACTURING]: 'Industrial & Manufacturing Materials',
  [Vertical.MACHINE]: 'Machinery',
  [Vertical.PETRO_GAS_MINERALS]: 'Petroleum, Natural Gas & Minerals',
  [Vertical.PHARMA_HEALTH_CHEM]: 'Pharmaceuticals, Health & Chemicals',
};

type VerticalCodesKeys =
  | Vertical.AGRICULTURE_PREP
  | Vertical.APPAREL_TEXTILES
  | Vertical.AUTOMOTIVE_AERO
  | Vertical.BASE_METALS
  | Vertical.CONSUMER_MERCHANDISE
  | Vertical.ELECTRONICS
  | Vertical.INDUSTRIAL_MANUFACTURING
  | Vertical.MACHINE
  | Vertical.PETRO_GAS_MINERALS
  | Vertical.PHARMA_HEALTH_CHEM;

type VerticalSelectProps = {
  form: FormInstance;
  localNamePath: NamePath;
  fullNamePath?: NamePath;
  required?: boolean;
  size?: CssSize;
  disabled?: boolean;
  readOnly?: boolean;
  hideLabel?: boolean;
  multiple?: boolean;
  label?: string;
  dataCySuffix?: string;
};

const VerticalSelect = ({
  form,
  localNamePath,
  fullNamePath,
  required,
  size = CssSize.TINY_SHORT,
  disabled,
  readOnly,
  hideLabel,
  multiple,
  label = 'Vertical',
  dataCySuffix,
}: VerticalSelectProps) => {
  // TODO use `verticals` from `getCodes()?.CBP` instead of the hard coded
  // values above
  // const verticalCodes = getCodes()?.CBP?.verticals || {};
  const objectOptions = Object.keys(verticalCodes).map((code) => {
    return (
      <Option
        key={code}
        value={code}
        data-cy={createDataCyValue(DataCyPrefix.VERTICAL_SELECT_OPTION, code)}
      >
        {verticalCodes[code as VerticalCodesKeys]}
      </Option>
    );
  });
  // Use localNamePath if fullNamePath was not provided, we assume they are the same
  if (!show({ readOnly, form, field: fullNamePath || localNamePath })) {
    return null;
  }
  return (
    <FormItem
      $itemSize={size}
      name={localNamePath}
      label={hideLabel ? undefined : label}
      rules={[{ required, message: ' ' }]}
      $readOnly={readOnly}
      $inline={true}
      data-cy={createDataCyValue(DataCyPrefix.VERTICAL_SELECT, dataCySuffix)}
    >
      <Select
        disabled={disabled}
        mode={multiple ? 'multiple' : undefined}
        showSearch
        optionLabelProp="children"
        allowClear
        notFoundContent="None found"
        dropdownMatchSelectWidth={false}
        maxTagCount={1}
      >
        {objectOptions}
      </Select>
    </FormItem>
  );
};

export default VerticalSelect;
