import React from 'react';
import { Form, message } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { conformToMask } from 'text-mask-core';
import {
  IorNumberAndType,
  ANNumber,
  SimpleSelect,
  VerticalSelect,
  Checkbox,
  CBPNumberReasonSelect,
} from '@xbcb/form-item-components';
import {
  UsBusinessStructure,
  UsIorNumberType,
  UsIorCbpEntryPreferredPaymentMethod,
} from '@xbcb/party-types';
import { RecordType, AccountType } from '@xbcb/shared-types';
import { CssSize, NamePath, DataCySuffix } from '@xbcb/ui-types';
import { shouldUpdate } from '@xbcb/ui-utils';

const { INDIVIDUAL, SOLE_PROPRIETOR } = UsBusinessStructure;

export type UsCbpInfoCardProps = {
  form: FormInstance;
  disabled?: boolean;
  readOnly?: boolean;
  accountType?: AccountType;
  recordType: 'US_IOR' | 'US_CONSIGNEE';
  hideHelpMeGetOne?: boolean;
  hidePreferredCbpEntryPaymentMethod?: boolean;
  iorNumberRequired?: boolean;
  verticalRequired?: boolean;
  hideVertical?: boolean;
  businessStructureNamePath?: NamePath;
  iorNumberNamePath?: NamePath;
  dataCySuffix?: string;
};

const UsCbpInfoCard: React.FC<UsCbpInfoCardProps> = ({
  form,
  disabled,
  readOnly,
  recordType,
  accountType,
  // NOTE: This field is not stored on the usIor, right now it is only used on
  // import sign as we pass this field as `additionalFields` on the template
  // DSR and then handled by PW. Defaulting to true for backwards compatibility
  hideHelpMeGetOne = true,
  hidePreferredCbpEntryPaymentMethod,
  iorNumberRequired,
  verticalRequired,
  hideVertical,
  businessStructureNamePath = ['businessStructure'],
  iorNumberNamePath = ['iorNumber'],
  dataCySuffix,
}) => {
  const physicalAddressCountryCodeNamePath = [
    'addresses',
    'physical',
    'countryCode',
  ];
  const mailingAddressCountryCodeNamePath = [
    'addresses',
    'mailing',
    'countryCode',
  ];
  const iorNumberTypeNamePath = [...iorNumberNamePath, 'type'];
  // for US IOR
  const pmsStatusNamePath = ['pmsStatus'];
  const payerUnitNumberNamePath = ['payerUnitNumber'];
  const cbpNumberHelpFullNamePath = ['cbpNumberHelp'];
  const cbpNumberReasonsFullNamePath = ['cbpNumberReasons'];
  const preferredCbpEntryPaymentMethodNamePath = [
    'preferredCbpEntryPaymentMethod',
  ];
  return (
    <>
      <Form.Item
        shouldUpdate={shouldUpdate([
          cbpNumberHelpFullNamePath,
          businessStructureNamePath,
          physicalAddressCountryCodeNamePath,
          mailingAddressCountryCodeNamePath,
        ])}
        noStyle
      >
        {() => {
          const cbpNumberHelp = form.getFieldValue(cbpNumberHelpFullNamePath);
          const businessStructure = form.getFieldValue(
            businessStructureNamePath,
          );
          const physicalAddressCountryCode = form.getFieldValue(
            physicalAddressCountryCodeNamePath,
          );
          const mailingAddressCountryCode = form.getFieldValue(
            mailingAddressCountryCodeNamePath,
          );
          const countryCode =
            mailingAddressCountryCode || physicalAddressCountryCode;
          const countryUsesSsn = ['US', 'PR', 'VI'].includes(countryCode);
          const isIndividual = businessStructure === INDIVIDUAL;
          const isSoleProp = businessStructure === SOLE_PROPRIETOR;
          return (
            <IorNumberAndType
              form={form}
              readOnly={readOnly}
              disabled={disabled}
              accountType={accountType}
              // The number should not be changed (aka set to anything other
              // than undefined) if cbpNumberHelp is checked
              numberReadOnly={cbpNumberHelp}
              numberDisabled={cbpNumberHelp}
              localNamePath={iorNumberNamePath}
              fullNamePath={iorNumberNamePath}
              showTypes={{
                cbp: true,
                ssn: countryUsesSsn && (isIndividual || isSoleProp),
                ein: !isIndividual,
              }}
              // Even if iorNumberRequired is provided, if the user has checked
              // cbpNumberHelp then we don't require a number since this tells
              // ops that we need to get one for them. The user has satisfied
              // the required requirement by checking that checkbox
              required={iorNumberRequired && !cbpNumberHelp}
              additionalFieldsToSet={[
                { name: cbpNumberHelpFullNamePath, value: undefined },
                {
                  name: cbpNumberReasonsFullNamePath,
                  value: undefined,
                },
              ]}
            />
          );
        }}
      </Form.Item>
      {recordType === RecordType.US_IOR && (
        <>
          {!hideVertical && (
            <VerticalSelect
              form={form}
              label="Vertical"
              localNamePath={['industry']}
              disabled={disabled}
              readOnly={readOnly}
              required={verticalRequired}
              dataCySuffix={dataCySuffix}
            />
          )}
          <ANNumber
            label="Payer Unit Number"
            form={form}
            localNamePath={['payerUnitNumber']}
            fullNamePath={['payerUnitNumber']}
            max={6}
            disabled={disabled}
            readOnly={readOnly}
            pattern={/^\d{6}/}
            $itemSize={CssSize.TINY_SHORT}
            dataCySuffix={dataCySuffix}
            onBlur={(event) => {
              // Preferred payment method should fall back to indirect daily statement if it is not hidden and
              // when PUN is removed from UI
              if (!hidePreferredCbpEntryPaymentMethod && !event.target.value) {
                form.setFields([
                  {
                    name: preferredCbpEntryPaymentMethodNamePath,
                    value:
                      UsIorCbpEntryPreferredPaymentMethod.INDIRECT_DAILY_STATEMENT,
                  },
                ]);
                message.info(
                  'PUN removed: Updating preferred payment method to direct daily statement',
                  5.0,
                );
              }
            }}
            mask={(len: number) => (e: any) => {
              if (!e || !e.target) return undefined;
              const conformedValue = conformToMask(
                e.target.value,
                [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
                { guide: false },
              );
              return conformedValue.conformedValue;
            }}
          />
          {!hidePreferredCbpEntryPaymentMethod && (
            <Form.Item
              shouldUpdate={shouldUpdate([
                pmsStatusNamePath,
                payerUnitNumberNamePath,
              ])}
              noStyle
            >
              {() => {
                const pmsStatus = form.getFieldValue(pmsStatusNamePath);
                const payerUnitNumber = form.getFieldValue(
                  payerUnitNumberNamePath,
                );
                const preferredCbpEntryPaymentMethodMap: {
                  [key: string]: string;
                } = {
                  INDIRECT_DAILY_STATEMENT: 'Broker Pays Daily Statement',
                };
                if (payerUnitNumber) {
                  preferredCbpEntryPaymentMethodMap.DIRECT_DAILY_STATEMENT =
                    'IOR Pays Daily Statement';
                }
                if (pmsStatus === 'ACTIVE') {
                  preferredCbpEntryPaymentMethodMap.INDIRECT_PERIODIC_MONTHLY_STATEMENT =
                    'Broker Pays Periodic Monthly Statement';
                  if (payerUnitNumber) {
                    preferredCbpEntryPaymentMethodMap.DIRECT_PERIODIC_MONTHLY_STATEMENT =
                      'IOR Pays Periodic Monthly Statement';
                  }
                }
                return (
                  <SimpleSelect
                    inline={true}
                    $itemSize={CssSize.SHORT}
                    label="Preferred Payment Method"
                    localNamePath={preferredCbpEntryPaymentMethodNamePath}
                    fullNamePath={preferredCbpEntryPaymentMethodNamePath}
                    disabled={disabled}
                    readOnly={readOnly}
                    form={form}
                    map={preferredCbpEntryPaymentMethodMap}
                    dropdownMatchSelectWidth={false}
                    dataCySuffix={
                      DataCySuffix.PREFERRED_CBP_ENTRY_PAYMENT_METHOD
                    }
                  />
                );
              }}
            </Form.Item>
          )}
          <Form.Item
            shouldUpdate={shouldUpdate([
              iorNumberTypeNamePath,
              cbpNumberHelpFullNamePath,
            ])}
            noStyle
          >
            {() => {
              const iorNumberType = form.getFieldValue(iorNumberTypeNamePath);
              const showHelpMeGetOne =
                !hideHelpMeGetOne && iorNumberType === UsIorNumberType.CBP;
              const cbpNumberHelp = form.getFieldValue(
                cbpNumberHelpFullNamePath,
              );
              if (!showHelpMeGetOne) return null;
              return (
                <>
                  <Checkbox
                    form={form}
                    readOnly={readOnly}
                    disabled={disabled}
                    text="Help me get a CBP-assigned Number"
                    fullNamePath={cbpNumberHelpFullNamePath}
                    onChange={() =>
                      form.setFields([
                        {
                          name: [...iorNumberNamePath, 'value'],
                          value: undefined,
                        },
                        {
                          name: cbpNumberReasonsFullNamePath,
                          value: undefined,
                        },
                      ])
                    }
                    $inline
                    $spaceTop
                  />
                  {cbpNumberHelp && (
                    <CBPNumberReasonSelect
                      form={form}
                      readOnly={readOnly}
                      disabled={disabled}
                    />
                  )}
                </>
              );
            }}
          </Form.Item>
        </>
      )}
    </>
  );
};

export default UsCbpInfoCard;
