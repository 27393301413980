import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Alert, Form, Input, Modal, message } from 'antd';
import { constantCase, noCase } from 'change-case';
import { get, pick } from 'lodash';
import { ApolloError, useMutation } from '@apollo/client';
import SaveOutlined from '@ant-design/icons/SaveOutlined';
import { FormItem, TagSelect } from '@xbcb/form-item-components';
import { DocumentTagByRecordType, DocumentTag } from '@xbcb/document-types';
import { documentFragments } from '@xbcb/document-queries';
import { RecordType, Tag } from '@xbcb/shared-types';
import {
  SearchQuery,
  SearchType,
  updateOneMutation,
  createSearchQueryVariables,
} from '@xbcb/shared-queries';
import { mutateRecord, retryMutation } from '@xbcb/apollo-client';
import {
  reportError,
  shouldUpdate,
  documentInputFields,
  validateForm,
  locationToObjectType,
  useModal,
  formatDocumentTag,
} from '@xbcb/ui-utils';
import { StyledButton, StyledForm, StyledDiv } from './styles';
import { ModalKey } from '@xbcb/ui-types';
import { isEuCustomsEntry } from '../../../work-order-utils/dist/isEuCustomsEntry';

interface EditDocModalProps {
  searchDeletedDocuments?: boolean;
  // The tags that should be used to search documents with
  searchQueryTags: Tag[];
  visible?: boolean;
  // Intentionally takes operatorId so that it is not tied to one way of
  // determining the operatorId. For example, in NewAppUi we often get it from
  // the currentUser but in ImportSign we get it from the queried document
  operatorId: string;
}

const EditDocModal: React.FC<EditDocModalProps> = ({
  searchDeletedDocuments,
  searchQueryTags,
  visible,
  operatorId,
}) => {
  const document = useSelector((state) =>
    get(state, `ui.modals.${ModalKey.EDIT_DOCUMENT}`, {}),
  );

  const { extension, documentTags = [], fileName, id, version } = document;

  const formatTags = (tags: DocumentTag[]) =>
    tags.map((tag) => formatDocumentTag(tag));

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const { closeModal } = useModal(ModalKey.EDIT_DOCUMENT);
  const objectType = locationToObjectType(pathname);
  const formattedTags = formatTags(documentTags);
  let availableTags = (DocumentTagByRecordType[
    objectType as keyof typeof DocumentTagByRecordType
  ] || []) as DocumentTag[];
  const initialValues = { fileName, tags: formattedTags };

  const disabledTagsForEu = [
    DocumentTag.DELIVERY_ORDER_FORM,
    DocumentTag.DRAWBACK_CLAIM_RESPONSE,
    DocumentTag.DUTY_DAILY_STATEMENT,
    DocumentTag.DUTY_MONTHLY_STATEMENT,
    DocumentTag.INLT_SUBSCRIPTION,
    DocumentTag.MONTHLY_DUTY_REPORT,
    DocumentTag.PERIODIC_MONTHLY_STATEMENT_APPLICATION,
    DocumentTag.MSDS,
  ];
  const { recordId } = useParams<{ recordId?: string }>();
  if (isEuCustomsEntry(recordId)) {
    availableTags = availableTags.filter(
      (tag: any) => !disabledTagsForEu.includes(tag),
    );
  }

  const searchQuery = SearchQuery({
    recordName: RecordType.DOCUMENT,
    fields: '...documentFields',
    fragments: documentFragments,
  });
  const searchQueryVariables = createSearchQueryVariables({
    deletedTimeExists: searchDeletedDocuments,
    operatorId,
    sortOptions: [
      {
        field: 'createdTime',
      },
    ],
    tags: searchQueryTags,
  });
  const optimisticUpdateParams = {
    gqlQueryString: searchQuery,
    updatedQueryType: 'search' as SearchType,
    variables: searchQueryVariables,
  };
  const [updateDocument] = useMutation(
    updateOneMutation({
      recordName: RecordType.DOCUMENT,
      fields: '...documentFields',
      fragments: documentFragments,
    }),
    {
      onError: async (error: ApolloError) => {
        try {
          const result = await retryMutation({
            fields: '...documentFields',
            fragments: documentFragments,
            id,
            mutationType: 'update',
            mutation: updateDocument,
            mutationVariables: getMutationVariables(),
            recordType: RecordType.DOCUMENT,
            optimisticUpdateParams,
          });
          if (result) {
            message.success(
              `Tags for document ${fileName}.${extension.toLowerCase()} have been updated`,
              5.0,
            );
            closeModal();
            setLoading(false);
          }
        } catch (e) {
          message.error(
            'Sorry, an error has occurred. Please try again later.',
            5.0,
          );
          setLoading(false);
          closeModal();
        }
      },
    },
  );

  const getMutationVariables = () => {
    const fileName = form.getFieldValue('fileName');
    const tags = form.getFieldValue('tags');
    const inputTags = tags.map((tag: string) => constantCase(tag));
    return {
      id,
      version,
      input: {
        ...pick(document, documentInputFields),
        documentTags: inputTags,
        fileName,
      },
    };
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      await validateForm({ form });
      const result = await mutateRecord({
        mutationType: 'update',
        mutation: updateDocument,
        mutationVariables: getMutationVariables(),
        recordType: RecordType.DOCUMENT,
        optimisticUpdateParams,
      });
      if (result) {
        message.success(
          `Tags for document ${fileName}.${extension.toLowerCase()} have been updated`,
          5.0,
        );
        setLoading(false);
        closeModal();
      }
    } catch (e) {
      reportError(e);
      message.error('Sorry, an error has occurred', 5.0);
      setLoading(false);
      closeModal();
    }
  };

  const handleClose = () => {
    closeModal();
    form.resetFields();
  };

  const addonAfter = extension ? { addonAfter: `.${noCase(extension)}` } : {};

  return (
    <Modal
      destroyOnClose={true}
      maskClosable={false}
      visible={visible}
      width={400}
      closable={true}
      footer={null}
      onCancel={handleClose}
    >
      <StyledForm form={form} initialValues={initialValues}>
        <FormItem shouldUpdate={shouldUpdate([['tags']])}>
          {() => {
            const tags = form.getFieldValue('tags');
            const arrivalNoticeAlert = tags.includes('Arrival Notice');
            const confidentialAlert = tags.includes('Confidential');
            const containCustomsEntryAndCustonsEntryDraft =
              tags.includes('Customs Entry') &&
              tags.includes('Customs Entry Draft');
            return (
              <>
                <StyledDiv>
                  <StyledButton
                    onClick={handleSubmit}
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    size="large"
                    disabled={containCustomsEntryAndCustonsEntryDraft}
                  >
                    <SaveOutlined /> Save
                  </StyledButton>
                </StyledDiv>
                <FormItem
                  label="File Name"
                  name="fileName"
                  rules={[{ required: true }]}
                >
                  <Input disabled={loading} {...addonAfter} />
                </FormItem>
                <TagSelect
                  tags={availableTags}
                  formatTag={formatDocumentTag}
                  form={form}
                  disabled={loading}
                  maxTagCount={1}
                />
                {containCustomsEntryAndCustonsEntryDraft && (
                  <Alert
                    message="You can either have Customs Entry or Customs Entry Draft tag on one document."
                    type="info"
                  />
                )}
                {arrivalNoticeAlert && (
                  <Alert
                    message="We limit access to the Arrival Notice to the uploader's company and broker only"
                    type="info"
                  />
                )}
                {confidentialAlert && (
                  <Alert
                    message="We limit access to Confidential documents to the uploader's company and broker only"
                    type="info"
                  />
                )}
              </>
            );
          }}
        </FormItem>
      </StyledForm>
    </Modal>
  );
};

export default EditDocModal;
