// TODO We should move this to some other package, nothing is UI specific.
// Maybe a new validations package, or some existing package that has no backend or UI specific dependencies?
import { cbp } from '@xbcb/regex';
import { get } from 'lodash';

const validYears = (() => {
  const currentYear = parseInt(new Date().getFullYear().toString().substr(2));
  const years = [];
  // As far as we know, CBP started assigning using the new format in 1992.
  for (let y = 92; y <= 99; y += 1) {
    years.push(y.toString());
  }
  for (let y = 0; y <= currentYear; y += 1) {
    let yearString = y.toString();
    if (yearString.length === 1) yearString = `0${yearString}`;
    years.push(yearString);
  }
  return years;
})();

/**
 * validate CBP Assigned Number
 * @param {String} number
 * @param {Object} cbpPortCodes optional
 * @return {boolean}
 */
export const isValidCBPNumber = (
  number?: string,
  cbpPortCodes?: { [key: string]: any },
) => {
  if (!number) return false;
  const year = number.substr(0, 2);
  const portCode = number.substr(2, 4);
  return Boolean(
    cbp.test(number) &&
      // NOTE: '9900' is a virtual code, so it is only used for IOR numbers, not port codes on a shipment
      (!cbpPortCodes || get(cbpPortCodes, portCode) || portCode === '9900') &&
      validYears.includes(year),
  );
};
