// Note: These represent the DataCyPrefix use to create dataCy values. They
// should be broken up and organized as best as they can and the merged
// together in the DataCyPrefix object and type at the end of the file. Prefix
// that have more than one common prefix should group them together as we have
// done below. For prefix that don't have any related prefixes we can simply
// add them to `DataCyStandAlonePrefix` until they do.

export enum DataCyRecordTablePrefix {
  RECORD_TABLE_COLUMN_TITLE = 'recordTableColumnTitle',
  RECORD_TABLE_FILTER_ICON = 'recordTableFilterIcon',
  RECORD_TABLE_FILTER_DROPDOWN_OPERATOR = 'recordTableFilterDropdownOperator',
  RECORD_TABLE_FILTER_DROPDOWN_OPERATOR_OPTION = 'recordTableFilterDropdownOperatorOption',
  RECORD_TABLE_FILTER_DROPDOWN_VALUES = 'recordTableFilterDropdownValues',
  RECORD_TABLE_FILTER_DROPDOWN_APPLY = 'recordTableFilterDropdownApply',
}

export enum DataCyRecordSelectPrefix {
  RECORD_SELECT = 'recordSelect',
  RECORD_SELECT_OPTION = 'recordSelectOption',
}

export enum DataCyOnboardIorPrefix {
  ONBOARD_IOR_NEXT_BUTTON = 'onboardIorNextButton',
  ONBOARD_IOR_VIEW_BUTTON = 'onboardIorViewButton',
}

export enum DataCyShipperUserAccessPrefix {
  SHIPPER_USER_ACCESS_BUTTON = 'shipperUserAccessButton',
  SHIPPER_USER_ACCESS_SUBMIT = 'shipperUserAccessSubmit',
  SHIPPER_USER_NAME = 'shipperUserName',
  SHIPPER_USER_COMPANY_TITLE = 'shipperUserTitle',
}

export enum DataCyAppHeaderPrefix {
  APP_HEADER_AVATAR = 'appHeaderAvatar',
}

export enum DataCyAppSiderMenuPrefix {
  APP_SIDER_MENU_ITEM = 'appSiderMenuItem',
  APP_SIDER_MENU_SUB_MENU = 'appSiderMenuSubMenu',
  APP_SIDER_MENU_SUB_MENU_ITEM = 'appSiderMenuSubMenuItem',
}

export enum DataCyAppRecordKebabMenuPrefix {
  APP_RECORD_KEBAB_MENU_ITEM = 'appRecordKebabMenuItem',
  APP_RECORD_KEBAB_MENU_ELLIPSIS = 'appRecordKebabMenuEllipsis',
  APP_RECORD_KEBAB_MENU_SUB_MENU = 'appRecordKebabMenuSubMenu',
  APP_RECORD_KEBAB_MENU_SUB_MENU_OPTION = 'appRecordKebabMenuSubMenuOption',
}

export enum DataCyAppRecordPrefix {
  APP_RECORD_CREATE_BUTTON = 'appRecordCreateButton',
  APP_RECORD_DEFAULT_CANCEL_BUTTON = 'appRecordDefaultCancelButton',
  APP_RECORD_DEFAULT_EDIT_BUTTON = 'appRecordDefaultEditButton',
  APP_RECORD_DEFAULT_SAVE_BUTTON = 'appRecordDefaultSaveButton',
  APP_RECORD_SUBMIT_BUTTON = 'appRecordSubmitButton',
}

export enum DataCyPhonePrefix {
  PHONE_COUNTRY = 'phoneCountry',
  PHONE_NUMBER = 'phoneNumber',
}

export enum DataCySimpleSelectPrefix {
  SIMPLE_SELECT = 'simpleSelect',
  SIMPLE_SELECT_OPTION = 'simpleSelectOption',
}

export enum DataCyManifestPrefix {
  MANIFEST_MASTER_BILL_OF_LANDING = 'manifestMasterBillOfLanding',
  MANIFEST_HOUSE_BILL_OF_LADING = 'manifestHouseBillOfLading',
  MANIFEST_QUANTITY = 'manifestQuantity',
}

export enum DataCyContainerPrefix {
  CONTAINER_TYPE = 'containerType',
  CONTAINER_DESCRIPTION = 'containerDescription',
  CONTAINER_QUANTITY = 'containerQuantity',
  CONTAINER_WEIGHT = 'containerWeight',
}

export enum DataCyConveyancePrefix {
  CONVEYANCE_VESSEL_NAME = 'conveyanceVesselName',
  CONVEYANCE_VOYAGE_NUMBER = 'conveyanceVoyageNumber',
  CONVEYANCE_GROSS_WEIGHT = 'conveyanceGrossWeight',
}

export enum DataCyDeparturePrefix {
  DEPARTURE_PORT_OF_LADING = 'departurePortOfLading',
  DEPARTURE_EXPORT_COUNTRY = 'departureExportCountry',
  DEPARTURE_ETA = 'departureETA',
}

export enum DataCyArrivalPrefix {
  ARRIVAL_PORT_OF_UN_LADING = 'arrivalPortOfUnLading',
  ARRIVAL_DESTINATION = 'arrivalDestination',
  ARRIVAL_FIRMS_CODE = 'arrivalFirmsCode',
  ARRIVAL_ETA = 'arrivalETA',
}

export enum DataCyMailingAddressPrefix {
  MAILING_ADDRESS = 'mailingAddress',
  MAILING_POSTAL_CODE = 'mailingPostalCode',
  MAILING_CITY = 'mailingCity',
  MAILING_STATE_CODE = 'mailingStateCode',
  MAILING_COUNTRY_CODE = 'mailingCountryCode',
  MAILING_COUNTRY_CODE_OPTION = 'mailingCountryCodeOption',
}

export enum DataCyPhysicalAddressPrefix {
  PHYSICAL_ADDRESS = 'physicalAddress',
  PHYSICAL_POSTAL_CODE = 'physicalPostalCode',
  PHYSICAL_CITY = 'physicalCity',
  PHYSICAL_STATE_CODE = 'physicalStateCode',
  PHYSICAL_COUNTRY_CODE = 'physicalCountryCode',
  PHYSICAL_COUNTRY_CODE_OPTION = 'physicalCountryCodeOption',
}

export enum DataCyIorNumberPrefix {
  IOR_NUMBER_VALUE = 'iorNumberValue',
  IOR_NUMBER_TYPE = 'iorNumberType',
}

export enum DataCyStandAlonePrefix {
  BCO_CARD = 'bcoCard',
  GLOBAL_NAME = 'globalName',
  WORK_ORDER_DOCUMENTS = 'workOrderDocuments',
  EMAIL = 'email',
  PASSWORD = 'password',
  SWITCH = 'switch',
  APP_TABLE_CREATE_BUTTON = 'appTableCreateButton',
  MID = 'mid',
  SHIPPER_SELECT = 'shipperSelect',
  LOGIN_BUTTON = 'loginButton',
  SHIPMENT_LINK = 'shipmentLink',
  NAME = 'name',
  TITLE = 'title',
  ALTERNATE_NAME = 'alternateName',
  SCAC = 'scac',
  CHANNEL_CONNECTION_STATUS_BUTTON = 'channelConnectionStatusButton',
  CHANNEL_CONNECTION_STATUS_MODAL = 'channelConnectionStatusModal',
  CHANNEL_CONNECTION_HISTORY_EVENT = 'channelConnectionStatusHistoryEvent',
  TASK_NAME = 'taskName',
  WORK_ORDER_TASK_STEPS = 'workOrderTaskSteps',
  COMMERICAL_INVOICE_BUILDING_WORK_ORDER_TASK = 'commercialInvoiceBuildingWorkOrderTask',
  OPEN_MODAL_BUTTON = 'openModalButton',
  SUBMIT_TO_CBP_MODAL_BUTTON = 'submitToCBPModalButton',
  SUBMIT_ENTRY_CONFIRMATION_MODAL_BUTTON = 'submitEntryConfirmationModalButton',
  QUANTITY = 'quantity',
  DECIMAL_QUANTITY = 'decimalQuantity',
  CBP_PORT_SELECT = 'cbpPortSelect',
  PICK_DATE = 'pickDate',
  BUSINESS_WEBSITE = 'businessWebsite',
  INVOICE_VALUE_CHECK = 'invoiceValueCheck',
}
export enum DataCyShipmentCancellationPrefix {
  CANCEL_REASON_DROPDOWN = 'cancelReasonDropdown',
}
export enum DataCyMilestonePrefix {
  MILESTONE_NAME = 'milestoneName',
  ADD_MILESTONE_FORM = 'addMilestoneForm',
}

export enum DataCyTabPrefix {
  US_ISF_TABS = 'usIsfTabs',
  US_CONSUMPTION_ENTRY_TABS = 'usConsumptionEntryTabs',
  CUSTOMS_ENTRY_TABS = 'customsEntryTabs',
  WORK_ORDER_TABS = 'workOrderTabs',
  SHIPMENT_TABS = 'shipmentTabs',
  US_IOR_ACTIVATIONS_TAB_PANE = 'usIorActivationsTabPane',
  LINE_ITEM_TABS = 'lineInfoTabs',
  ASSIGNMENT_WORKSPACE_TABS = 'assignmentWorkspaceTabs',
}

export enum DataCyWorkOrderCardPrefix {
  WORK_ORDER_CARD = 'workOrderCard',
}

export enum DataCyAdditionalCompanyInfoPrefix {
  DUNS_NUMBER = 'dunsNumber',
  STRUCTURE_SELECT = 'structureSelect',
  STRUCTURE_SELECT_OPTION = 'structureOption',
  VERTICAL_SELECT = 'verticalSelect',
  VERTICAL_SELECT_OPTION = 'verticalSelectOption',
  AN_NUMBER = 'anNumber',
  MONEY_INPUT = 'moneyInput',
  EIN_NUMBER = 'einNumber',
  FEI_NUMBER = 'feiNumber',
}
export enum DataCyProductInfoPrefix {
  ASIN_NUMBER = 'asinNumber',
}

export enum DataCyRadioGroupPrefix {
  RADIO_BUTTON = 'radioButton',
}

export enum DataCyTitleContent {
  US_IOR_ACTIVATION_WORK_ORDER_LINK = 'usIorActionWorkOrderLink',
}

export enum DataCyCommercialInvoiceSummary {
  INVOICE_SUMMARY = 'invoiceSummary',
  COMMERCIAL_INVOICE = 'commercialInvoice',
}

export enum DataCyInquireWorkOrderModalPrefix {
  REASON_CATEGORIES = 'reasonCategories',
  REASON_CATEGORIES_OPTION = 'reasonCategoriesOption',
  REASON_CODES = 'reasonCodes',
  REASON_CODES_OPTION = 'reasonCodesOption',
  ASIN_OPTION = 'asinOption',
  DESCRIPTION = 'description',
}

export enum DataCyWorkOrderCensusWarningPrefix {
  CENSUS_WARNING = 'censusWarning',
  CENSUS_LINE_LEVEL_WARNING = 'censusLineLevelWarning',
  CENSUS_WARNING_SELECT = 'censusWarningSelect',
}

export enum DataCyCreateUserPrefix {
  SUGGESTED_ROLES_SELECT = 'suggestedRolesSelect',
  ROLE_OPTION = 'roleOption',
  PERMISSIONS_TABLE = 'permissionsTable',
  PERMISSIONS_SELECTOR = 'permissionsSelector',
}

export enum DataCyLineItemPrefix {
  AD_CVD_CASE_SELECT = 'adCvdCaseSelect',
}

export enum DataCyAssignmentTeamPrefix {
  WORK_ORDER_TASK_TYPE_OPTION = 'workOrderTaskTypeOption',
  WORK_ORDER_TASK_TYPE = 'workOrderTaskType',
  PRODUCT_TYPE_OPTION = 'productTypeOption',
  MODE_OF_TRANSPORT_OPTION = 'modeOfTransportOption',
  LOAD_TYPE_OPTION = 'loadTypeOption',
  ASSIGNMENT_TEAM_NAME = 'assignmentTeamName',
  MODE_OF_TRANSPORT = 'modeOfTransport',
  PRODUCT_TYPE = 'productType',
  LOAD_TYPE = 'loadType',
  NL_IOR_ACTIVATION = 'workOrderTaskType-NlIorActivation',
  FR_IOR_ACTIVATION = 'workOrderTaskType-FrIorActivation',
  TASK_ESCALATION_CATEGORY = 'taskEscalationCategory',
  ESCALATION_DETAILS = 'escalationDetails',
  RESOLUTION_DETAILS = 'resolutionDetails',
}

export enum DataCySmeTeamPrefix {
  SME_TEAM_NAME = 'smeTeamName',
}

export enum DataCyWorkOrderInquiryPrefix {
  INQUIRY_SUGGESTION = 'inquirySuggestion',
  INQUIRY_REASON_CODE = 'inquiryReasonCode',
}

export enum DataCyAssignmentWorkspacePrefix {
  QUEUE_TABLE_TITLE = 'queueTableTitle',
  DASHBOARD_CARD = 'dashboardCard',
  GET_NEW_ASSIGNMENT_BUTTON = 'getNewAssignmentButton',
}

export enum DataCyCreateExceptionTaskModalPrefix {
  EXCEPTION_TASK_TYPE = 'exceptionTaskType',
  EXCEPTION_TASK_TYPE_OPTION = 'exceptionTaskTypeOption',
}

export const DataCyPrefix = {
  ...DataCyRecordTablePrefix,
  ...DataCyRecordSelectPrefix,
  ...DataCyOnboardIorPrefix,
  ...DataCyAppSiderMenuPrefix,
  ...DataCyAppRecordKebabMenuPrefix,
  ...DataCyAppRecordPrefix,
  ...DataCyPhonePrefix,
  ...DataCySimpleSelectPrefix,
  ...DataCyMailingAddressPrefix,
  ...DataCyPhysicalAddressPrefix,
  ...DataCyIorNumberPrefix,
  ...DataCyStandAlonePrefix,
  ...DataCyShipmentCancellationPrefix,
  ...DataCyMilestonePrefix,
  ...DataCyTabPrefix,
  ...DataCyShipperUserAccessPrefix,
  ...DataCyWorkOrderCardPrefix,
  ...DataCyAdditionalCompanyInfoPrefix,
  ...DataCyProductInfoPrefix,
  ...DataCyRadioGroupPrefix,
  ...DataCyTitleContent,
  ...DataCyCommercialInvoiceSummary,
  ...DataCyInquireWorkOrderModalPrefix,
  ...DataCyWorkOrderCensusWarningPrefix,
  ...DataCyLineItemPrefix,
  ...DataCyAssignmentTeamPrefix,
  ...DataCySmeTeamPrefix,
  ...DataCyAppHeaderPrefix,
  ...DataCyWorkOrderInquiryPrefix,
  ...DataCyAssignmentWorkspacePrefix,
  ...DataCyCreateExceptionTaskModalPrefix,
  ...DataCyManifestPrefix,
  ...DataCyContainerPrefix,
  ...DataCyConveyancePrefix,
  ...DataCyDeparturePrefix,
  ...DataCyArrivalPrefix,
};

export type DataCyPrefix =
  | DataCyRecordTablePrefix
  | DataCyRecordSelectPrefix
  | DataCyOnboardIorPrefix
  | DataCyAppSiderMenuPrefix
  | DataCyAppRecordKebabMenuPrefix
  | DataCyAppRecordPrefix
  | DataCyPhonePrefix
  | DataCySimpleSelectPrefix
  | DataCyMailingAddressPrefix
  | DataCyPhysicalAddressPrefix
  | DataCyIorNumberPrefix
  | DataCyStandAlonePrefix
  | DataCyShipmentCancellationPrefix
  | DataCyMilestonePrefix
  | DataCyTabPrefix
  | DataCyShipperUserAccessPrefix
  | DataCyWorkOrderCardPrefix
  | DataCyAdditionalCompanyInfoPrefix
  | DataCyProductInfoPrefix
  | DataCyRadioGroupPrefix
  | DataCyTitleContent
  | DataCyCommercialInvoiceSummary
  | DataCyInquireWorkOrderModalPrefix
  | DataCyWorkOrderCensusWarningPrefix
  | DataCyCreateUserPrefix
  | DataCyLineItemPrefix
  | DataCyAssignmentTeamPrefix
  | DataCySmeTeamPrefix
  | DataCyAppHeaderPrefix
  | DataCyWorkOrderInquiryPrefix
  | DataCyAssignmentWorkspacePrefix
  | DataCyCreateExceptionTaskModalPrefix
  | DataCyManifestPrefix
  | DataCyContainerPrefix
  | DataCyConveyancePrefix
  | DataCyDeparturePrefix
  | DataCyArrivalPrefix;
