import { FormInstance } from 'antd/lib/form';
import { get } from 'lodash';
import { NamePath, AdditionalFormError } from '@xbcb/ui-types';
import { formatFormErrors } from '../formatFormErrors';
import { showValidationErrors } from '../showValidationErrors';

export const validateForm = async ({
  form,
  additionalErrors,
  validateFields,
  skipValidateFields,
}: {
  form: FormInstance;
  additionalErrors?: AdditionalFormError[];
  validateFields?: NamePath[];
  skipValidateFields?: boolean;
}) => {
  try {
    if (!skipValidateFields) {
      const fieldsToValidate = validateFields?.length
        ? validateFields
        : undefined; // undefined means validate all fields
      await form.validateFields(fieldsToValidate);
    }

    if (additionalErrors?.length) {
      // there were no form errors, but there were some additionalErrors in the custom validateUpdate
      showValidationErrors(additionalErrors);
      return false;
    } else {
      return true;
    }
  } catch (errorInfo) {
    const formattedErrors = formatFormErrors({ errorInfo });
    const validationErrors = [formattedErrors];
    if (additionalErrors) {
      validationErrors.push(...additionalErrors);
    }
    showValidationErrors(validationErrors);
    return false;
  }
};

export type ValidateFormSnapshotFieldsProps = {
  input: any;
  idPath: NamePath;
  displayName: string;
  embeddedFields: NamePath[];
};

export const validateFormSnapshotFields = (
  props: ValidateFormSnapshotFieldsProps,
) => {
  if (!get(props.input, props.idPath)) {
    return []; // only add additionalErrors if reference is selected
  }
  const path = props.idPath.slice(0, -2);
  const messages = props.embeddedFields.reduce((acc: string[], field) => {
    const fullNamePath = [...path, ...field];
    if (!get(props.input, fullNamePath)) {
      acc.push(`Missing ${fullNamePath.join('.')}`); // TODO improve formatting
    }
    return acc;
  }, []);
  const versionFullNamePath = [...props.idPath.slice(0, -1), 'version'];
  if (!get(props.input, versionFullNamePath)) {
    messages.push(`Missing ${versionFullNamePath.join('.')}`); // TODO improve formatting
  }
  if (!messages.length) return [];
  else
    return [
      {
        title: `Fields missing on the ${props.displayName} record.`,
        // TODO why is this necessary, reduce should be returning string[]
        messages: messages as string[],
      },
    ];
};

export const manifestFieldsToValidate = (
  masterBills: {
    number?: string;
    houseBills: { number?: string }[];
  }[],
) => {
  const fieldsToValidate: NamePath[] = [];
  masterBills?.forEach((masterBill, masterBillIndex) => {
    const masterBillNamePath = ['masterBills', masterBillIndex];
    fieldsToValidate.push([...masterBillNamePath, 'number']);
    masterBill.houseBills?.forEach((houseBill: any, houseBillIndex: number) => {
      const houseBillNamePath = [
        ...masterBillNamePath,
        'houseBills',
        houseBillIndex,
      ];
      fieldsToValidate.push([...houseBillNamePath, 'number']);
      // these next fields are only on the Entry, not the ISF
      fieldsToValidate.push([...houseBillNamePath, 'quantity']);
      if (houseBill?.inBondNumber)
        fieldsToValidate.push(['arrival', 'inBond', 'initiationDate']);
    });
  });
  return fieldsToValidate;
};

export type CreatePartyValidatorProps = {
  validateFields: NamePath[];
  additionalErrors: AdditionalFormError[];
  input: any;
};

export const defaultCreatePartyValidatorEmbeddedFields = [
  ['name'],
  ['address', 'address'],
  ['address', 'city'],
  ['address', 'stateCode'],
  ['address', 'countryCode'],
];

// N.B. This is a mutating function. Not ideal but it makes for easy invocation where we need it.
export const createPartyValidator =
  ({ validateFields, additionalErrors, input }: CreatePartyValidatorProps) =>
  (
    idPath: NamePath,
    displayName: string,
    embeddedFields?: NamePath[], // default is name and address
  ) => {
    validateFields.push(idPath);
    additionalErrors.push(
      ...validateFormSnapshotFields({
        input,
        idPath,
        displayName,
        embeddedFields:
          embeddedFields || defaultCreatePartyValidatorEmbeddedFields,
      }),
    );
  };
