import { FeatureFlagService } from '../featureFlagService';
import { Stage } from '@xbcb/core';

const spdsNotificationFeatureName = 'SPDS_NOTIFICATION_FEATURE';

const sellers = {
  prod: [
    'A1OC6QNZKB51HG', // prod test seller
    'A2NJJS9LBZXBUA', // Amazon seller
    // https://app.asana.com/0/1206202404449422/1206747078366863/f
    'A2RCXAQDK3T42M',
    'A3LLJPKGGJ4VSB',
    'A3MLJ4DY39TQHE',
    'A3FZL3L1FBV66P',
    'A2SHDKZCV5UHZW',
    'AP3379L24X8DJ',
    'AZTBEYIABNB3Y',
    'A3O9R7XELHQPOR',
    // 'AQ0J7DW8S90R8',
    // 'A397YOV056KOLS',
    // 'A1SS8OEB4MM4CN',
    // 'A3SWAV2CSY311L',
    // 'A3VWHZMAX0073X',
    // 'A17MEHR4XF2CRW',
  ],
};

const SPDS_NOTIFICATION_FEATURE = new FeatureFlagService([
  {
    feature: spdsNotificationFeatureName,
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA, Stage.PROD],
    sellers: {
      [Stage.PROD]: [...sellers.prod],
    },
  },
]);

export { SPDS_NOTIFICATION_FEATURE, spdsNotificationFeatureName };
