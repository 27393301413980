import CloudUploadOutlined from '@ant-design/icons/CloudUploadOutlined';
import { useMutation } from '@apollo/client';
import { documentFragments } from '@xbcb/document-queries';
import { DocumentFileExtension, DocumentTag } from '@xbcb/document-types';
import { createOneMutation } from '@xbcb/shared-queries';
import { ObjectType, Tag } from '@xbcb/shared-types';
import { locationToObjectType } from '@xbcb/ui-utils';
import { Spin, Upload } from 'antd';
import { get } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { handleUpload } from '../libs';
import { StyledDiv } from './styles';
import { DocumentFields } from '../utils';

interface UploadDocBoxProps {
  fullWidth?: boolean;
  isUploading?: boolean;
  showUploadList?: boolean;
  multiple?: boolean;
  documentList?: DocumentFields[];
  uploadLoadText?: string;
  acceptedFileTypes?: string;
  searchDeletedDocuments?: boolean;
  setIsUploading: (isUploading: boolean) => void;
  setDocumentList?: (documentList: DocumentFields[]) => void;
  tagsForNewDocuments: Tag[];
  searchQueryTags: Tag[];
  documentTags?: DocumentTag[];
  dataCy?: string;
  // Intentionally takes operatorId so that it is not tied to one way of
  // determining the operatorId. For example, in NewAppUi we often get it from
  // the currentUser but in ImportSign we get it from the queried document
  operatorId: string;
  postUpload?: () => void;
}

interface DocMapping {
  docUid: string;
  docCBMSId: string;
  docName: string;
}

const UploadDocBox = ({
  fullWidth,
  isUploading,
  showUploadList,
  multiple,
  documentList,
  uploadLoadText,
  acceptedFileTypes,
  searchDeletedDocuments,
  setIsUploading,
  setDocumentList,
  tagsForNewDocuments,
  searchQueryTags,
  documentTags,
  operatorId,
  postUpload,
  dataCy,
}: UploadDocBoxProps): JSX.Element | null => {
  const { pathname } = useLocation();
  const objectType = locationToObjectType(pathname);
  const [createDocument] = useMutation(
    createOneMutation({
      recordName: ObjectType.DOCUMENT,
      fields: '...documentFields',
      fragments: documentFragments,
    }),
  );

  const [docMappingList, setDocMappingList] = useState<DocMapping[]>([]);

  useEffect(() => {
    if (setDocumentList) {
      setDocumentList(
        docMappingList.map((doc) => ({
          id: doc.docCBMSId,
          fileName: doc.docName,
        })),
      );
    }
  }, [docMappingList]);

  if (!objectType) return null;

  const handleRemove = (file: any) => {
    const newDocMapping = docMappingList.filter(
      (obj) => obj.docUid !== file.uid,
    );
    setDocMappingList(newDocMapping);
  };
  // Disable user to upload HTML files, once this change in prod for a while, we can remove HTML from document file extension enum
  const defaultAcceptedFileTypes = Object.keys(DocumentFileExtension)
    .filter((extension) => extension !== DocumentFileExtension.HTML)
    .map((extension) => `.${extension.toLowerCase()}`)
    .join(',');
  return (
    <StyledDiv $fullWidth={fullWidth}>
      <Upload.Dragger
        beforeUpload={(file, files) => {
          handleUpload({
            file,
            files,
            setIsUploading,
            operatorId,
            createDocument,
            searchDeletedDocuments,
            tagsForNewDocuments,
            searchQueryTags,
            documentTags,
            onSuccess: (responses: any) => {
              const newDocs = responses?.map((response: any) => {
                const docId = get(response, 'data.createDocument.record.id');
                const docName = get(
                  response,
                  'data.createDocument.record.fileName',
                );
                return { docUid: file.uid, docCBMSId: docId, docName };
              });
              setDocMappingList([...docMappingList, ...newDocs]);
            },
            postUpload,
          });
          return false;
        }}
        accept={acceptedFileTypes || defaultAcceptedFileTypes}
        multiple={multiple}
        data-cy={dataCy}
        showUploadList={showUploadList}
        disabled={
          isUploading || (!multiple && documentList && documentList?.length > 0)
        }
        onRemove={(file) => handleRemove(file)}
      >
        {isUploading ? (
          <Spin />
        ) : (
          <>
            <CloudUploadOutlined />
            <p>{uploadLoadText || 'Upload documents'}</p>
          </>
        )}
      </Upload.Dragger>
    </StyledDiv>
  );
};

export default UploadDocBox;
