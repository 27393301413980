export * from './exam';

export const usCbpEntryModeOfTransportDescription =
  'The mode of transport for the goods being entered.';
export enum UsCbpEntryModeOfTransport {
  OCEAN = 'OCEAN',
  AIR = 'AIR',
  RAIL = 'RAIL',
  TRUCK = 'TRUCK',
  MAIL = 'MAIL',
}

export const usCbpEntryMerchandiseUseDescription =
  'How the imported merchandise will be used.';
export enum UsCbpEntryMerchandiseUse {
  COMMERCIAL = 'COMMERCIAL',
  SAMPLE = 'SAMPLE',
  PERSONAL = 'PERSONAL',
}

export const usCbpEntrySummaryStatusDescription =
  'Status of the Entry Summary (AE) filing with CBP.';
export enum UsCbpEntrySummaryStatus {
  SUBMITTED = 'SUBMITTED',
  FILED = 'FILED',
  REJECTED = 'REJECTED',
  DELETED = 'DELETED',
}

export const usCbpEntryReleaseStatusDescription =
  'Status of the Entry Release (SE) filing with CBP.';
export enum UsCbpEntryReleaseStatus {
  SUBMITTED = 'SUBMITTED',
  FILED = 'FILED',
  RELEASED = 'RELEASED',
  REJECTED = 'REJECTED',
  DELETED = 'DELETED',
  HELD = 'HELD',
  EXAMINED = 'EXAMINED',
}

// TODO: lateFilingStatus, censusHeaderStatusCode, and controlStatusCode should be displayed in UI Summary tab.
// https://app.asana.com/0/1161703380091928/1200046156764877

// Corresponds to JD response lateFilingStatus
export const usCbpEntryFilingTimelinessDescription =
  'Indicates the filing timeliness of the US entry summary.';
export enum UsCbpEntryFilingTimeliness {
  ON_TIME = 'ON_TIME', // 0
  OVER_10_DAYS_LATE = 'OVER_10_DAYS_LATE', // 1
  OVER_30_DAYS_LATE = 'OVER_30_DAYS_LATE', // 2
  OVER_60_DAYS_LATE = 'OVER_60_DAYS_LATE', // 3
}

// Corresponds to JD response censusHeaderStatusCode
export const usCbpEntryCensusHeaderStatusCodeDescription =
  'Indicates census warning status of the US entry summary.';
export enum UsCbpEntryCensusHeaderStatusCode {
  NO_CENSUS_WARNING = 'NO_CENSUS_WARNING', // 0
  AT_LEAST_ONE_CENSUS_WARNING_UNRESOLVED = 'AT_LEAST_ONE_CENSUS_WARNING_UNRESOLVED', // 1
  ALL_CENSUS_WARNING_RESOLVED = 'ALL_CENSUS_WARNING_RESOLVED', // 6
}

// Corresponds to JD response controlStatusCode
export const usCbpEntryControlStatusCodeDescription =
  'Indicates the control status of the US entry summary.';
export enum usCbpEntryControlStatusCode {
  TRADE = 'TRADE', // 1
  CBP = 'CBP', // 2
  ACE = 'ACE', // 3
}

export const usCbpSplitShipmentReleaseMethodDescription =
  'Indicates how to release split shipments.';
export enum UsCbpSplitShipmentReleaseMethod {
  HOLD_ALL = 'HOLD_ALL',
  INCREMENTAL = 'INCREMENTAL',
}

export const deleteUsCbpEntryReleaseReasonDescription =
  'The reason the entry release is being deleted.';
export enum DeleteUsCbpEntryReleaseReason {
  CLERICAL_ERROR = 'CLERICAL_ERROR',
  REPLACED_BY_IN_BOND = 'REPLACED_BY_IN_BOND',
  REPLACED_BY_FORMAL_ENTRY = 'REPLACED_BY_FORMAL_ENTRY',
  REPLACED_BY_FTZ_ADMISSION = 'REPLACED_BY_FTZ_ADMISSION',
  REPLACED_BY_INFORMAL_ENTRY = 'REPLACED_BY_INFORMAL_ENTRY',
  MERCHANDISE_SEIZED = 'MERCHANDISE_SEIZED',
  MERCHANDISE_DESTROYED = 'MERCHANDISE_DESTROYED',
  NON_ARRIVAL = 'NON_ARRIVAL',
  REFUSED_BY_IMPORTER = 'REFUSED_BY_IMPORTER',
  NOT_AUTHORIZED_FOR_IMPORT = 'NOT_AUTHORIZED_FOR_IMPORT',
  SYSTEM_ERROR = 'SYSTEM_ERROR',
  NO_FOREIGN_STATUS_GOOD_REMOVED_FROM_FTZ = 'NO_FOREIGN_STATUS_GOOD_REMOVED_FROM_FTZ',
}

// from page 12 of https://www.cbp.gov/sites/default/files/assets/documents/2020-Feb/ACE_CATAIR_Entry_Summary_Status_Notification_UC_V18_2020-02-13_0.pdf
export enum UsCbpEntrySummaryNotificationType {
  REQUEST_FOR_ELECTRONIC_INVOICE_DATA_ONLY = 'REQUEST_FOR_ELECTRONIC_INVOICE_DATA_ONLY',
  REQUEST_FOR_ENTRY_SUMMARY_PACKAGE = 'REQUEST_FOR_ENTRY_SUMMARY_PACKAGE',
  REQUEST_FOR_SPECIFIC_DOCUMENTS = 'REQUEST_FOR_SPECIFIC_DOCUMENTS',
  ENTRY_SUMMARY_REJECTED = 'ENTRY_SUMMARY_REJECTED',
  ACE_ENTRY_SUMMARY_INACTIVATED = 'ACE_ENTRY_SUMMARY_INACTIVATED',
  ENTRY_SUMMARY_CANCELED = 'ENTRY_SUMMARY_CANCELED',
  DOCUMENT_RECEIPT_ACKNOWLEDGEMENT = 'DOCUMENT_RECEIPT_ACKNOWLEDGEMENT',
  PSC_PRESENTED_BY_ANOTHER_FILER = 'PSC_PRESENTED_BY_ANOTHER_FILER',
  PGA_PROCESSING_STATUS_INFORMATION = 'PGA_PROCESSING_STATUS_INFORMATION',
  QUOTA_ACCEPTED = 'QUOTA_ACCEPTED',
  TIB_EXTENSION_DENIED = 'TIB_EXTENSION_DENIED',
  PSC_REVERTED = 'PSC_REVERTED',
}

export enum UsCbpEntrySummaryNotificationSource {
  MANUAL_REQUEST = 'MANUAL_REQUST',
  AUTOMATED_REQUEST = 'AUTOMATED_REQUEST',
}

export enum UsCbpEntrySummarySeverityCode {
  CENSUS_WARNING = 'W',
  FATAL = 'F',
  PARTNER_GOVERNMENT_AGENCY = 'P',
  INFORMATIONAL_NOTICE = 'I',
}
