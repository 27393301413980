import React, { useState } from 'react';
import { Img } from 'react-image';
import { noCase } from 'change-case';
import { Tooltip, Spin, Tag } from 'antd';
import FileExcelOutlined from '@ant-design/icons/FileExcelOutlined';
import FileImageOutlined from '@ant-design/icons/FileImageOutlined';
import FileJpgOutlined from '@ant-design/icons/FileJpgOutlined';
import FileOutlined from '@ant-design/icons/FileOutlined';
import FilePdfOutlined from '@ant-design/icons/FilePdfOutlined';
import FileWordOutlined from '@ant-design/icons/FileWordOutlined';
import { client } from '@xbcb/apollo-client';
import { DocumentFileExtension, DocumentTag } from '@xbcb/document-types';
import { Tag as TagType } from '@xbcb/shared-types';
import DocumentActions from '../DocumentActions';
import { useModal, formatDocumentTag, handleDownload } from '@xbcb/ui-utils';
import {
  StyledCard,
  StyledDocumentDetailsDiv,
  StyledDocumentTagsDiv,
  StyledDocumentTitleDiv,
  StyledDocumentPreviewDiv,
  StyledMissingPreviewDiv,
} from './styles';
import { DocumentPermissions, ModalKey } from '@xbcb/ui-types';
import { Document, WorkOrderInquiry } from '@xbcb/api-gateway-client';
import { WorkOrderStatus } from '@xbcb/work-order-types';

const fileExtensionIconMap = {
  [DocumentFileExtension.DOC]: FileWordOutlined,
  [DocumentFileExtension.DOCX]: FileWordOutlined,
  [DocumentFileExtension.RTF]: FileWordOutlined,
  [DocumentFileExtension.XLS]: FileExcelOutlined,
  [DocumentFileExtension.XLSX]: FileExcelOutlined,
  [DocumentFileExtension.PDF]: FilePdfOutlined,
  [DocumentFileExtension.JPG]: FileJpgOutlined,
  [DocumentFileExtension.JPEG]: FileJpgOutlined,
  [DocumentFileExtension.GIF]: FileImageOutlined,
  [DocumentFileExtension.PNG]: FileImageOutlined,
  [DocumentFileExtension.TIF]: FileImageOutlined,
  [DocumentFileExtension.TIFF]: FileImageOutlined,
  [DocumentFileExtension.CSV]: FileOutlined,
  [DocumentFileExtension.HTML]: FileOutlined,
  [DocumentFileExtension.TXT]: FileOutlined,
};

export interface DocProps {
  document: any;
  fullWidth?: boolean;
  hide?: boolean;
  isUploading?: boolean;
  readOnly?: boolean;
  searchDeletedDocuments?: boolean;
  searchQueryTags: TagType[];
  showInquireWorkOrderKebabMenuItem?: boolean;
  showInvoiceLineCountKebabMenuItem?: boolean;
  workOrderStatus?: WorkOrderStatus;
  workOrderInquiries?: WorkOrderInquiry[];
  // Intentionally takes operatorId so that it is not tied to one way of
  // determining the operatorId. For example, in NewAppUi we often get it from
  // the currentUser but in ImportSign we get it from the queried document
  operatorId: string;
  // Intentionally takes permissions so that it is not tied to one way of
  // determining the permissions. For example, in NewAppUi we often get it from
  // the currentUser but in ImportSign we always allow full permissions since there
  // is not a signed in user
  permissions: DocumentPermissions;
}

const Doc: React.FC<DocProps> = ({
  document,
  fullWidth,
  hide,
  isUploading,
  readOnly,
  searchDeletedDocuments,
  searchQueryTags,
  showInquireWorkOrderKebabMenuItem,
  showInvoiceLineCountKebabMenuItem,
  workOrderStatus,
  workOrderInquiries,
  operatorId,
  permissions,
}) => {
  const { documentTags, extension, id, fileName, preview, version } = document;

  const [loading, setLoading] = useState(false);
  const { openModal, setModal } = useModal(ModalKey.EDIT_DOCUMENT);
  if (hide) return null;

  let clarifiedDocument: Document | undefined = undefined;
  // find the document in the most recent inquiry that is causing the WO to be blocked
  if (
    workOrderStatus === WorkOrderStatus.BLOCKED &&
    workOrderInquiries &&
    workOrderInquiries.length > 0
  ) {
    const recentInquiry = workOrderInquiries[workOrderInquiries.length - 1];
    clarifiedDocument = recentInquiry.clarifyDocuments.find(
      (clarifyDocument) => clarifyDocument.id === id,
    );
  }

  const handleEdit = () => {
    if (!loading) {
      setModal({ documentTags, extension, fileName, id, version });
      openModal();
    }
  };

  const lowercaseExtension = noCase(extension);
  const Icon =
    fileExtensionIconMap[extension as keyof typeof fileExtensionIconMap];
  const notLoaded = (
    <StyledMissingPreviewDiv title={`${fileName}.${lowercaseExtension}`}>
      <Icon />
    </StyledMissingPreviewDiv>
  );
  const makeTag = (documentTag: DocumentTag) => {
    const tag = (
      <Tag onClick={handleEdit} key={documentTag}>
        {formatDocumentTag(documentTag)}
      </Tag>
    );
    const newTag =
      documentTag === DocumentTag.ARRIVAL_NOTICE ||
      documentTag === DocumentTag.CONFIDENTIAL ? (
        <Tooltip
          key={documentTag}
          title="Limits access to the uploader's company and broker only"
          placement="right"
          trigger="hover"
        >
          {tag}
        </Tooltip>
      ) : (
        tag
      );
    return newTag;
  };

  return (
    <StyledCard $fullWidth={fullWidth}>
      {loading ? (
        <Spin />
      ) : (
        <>
          <StyledDocumentPreviewDiv
            onClick={() => {
              // TODO uncomment when antivirus scanning is enabled in DIW
              // if (
              //   antivirusScanStatus === DocumentAntivirusScanStatus.CLEAN &&
              //   !loading
              // )
              if (!isUploading) handleDownload(document, client);
            }}
          >
            {isUploading ? (
              <Spin />
            ) : preview?.downloadLink ? (
              <Img
                title={`${fileName}.${lowercaseExtension}`}
                src={preview.downloadLink}
                alt={`${fileName}.${lowercaseExtension}`}
                loader={<Spin />}
                unloader={notLoaded}
              />
            ) : (
              notLoaded
            )}
          </StyledDocumentPreviewDiv>
          <StyledDocumentDetailsDiv>
            <StyledDocumentTitleDiv>
              <span title={`${fileName}.${lowercaseExtension}`}>
                <b>
                  {fileName}.{lowercaseExtension}
                </b>
              </span>
            </StyledDocumentTitleDiv>
            <StyledDocumentTagsDiv>
              {documentTags.length ? (
                documentTags.map((tag: DocumentTag) => makeTag(tag))
              ) : (
                <Tag onClick={handleEdit} color="red">
                  No tags yet
                </Tag>
              )}
              {/* If we found the document in the most recent inquiry that is causing the WO to be blocked, we should mark this document with a Inquired tag so that it is clear to the user */}
              {clarifiedDocument && <Tag color="red">Inquired</Tag>}
            </StyledDocumentTagsDiv>

            <DocumentActions
              document={document}
              handleEdit={handleEdit}
              isUploading={isUploading}
              readOnly={readOnly}
              searchDeletedDocuments={searchDeletedDocuments}
              setLoading={setLoading}
              searchQueryTags={searchQueryTags}
              showInquireWorkOrderKebabMenuItem={
                showInquireWorkOrderKebabMenuItem
              }
              showInvoiceLineCountKebabMenuItem={
                showInvoiceLineCountKebabMenuItem
              }
              operatorId={operatorId}
              permissions={permissions}
              documentTags={documentTags.length ? documentTags : []}
            />
          </StyledDocumentDetailsDiv>
        </>
      )}
    </StyledCard>
  );
};

export default Doc;
