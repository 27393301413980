import { EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const defaultHeaderStyles = `
  font-weight: 400;
`;

export const StyledH1 = styled.h1`
  ${defaultHeaderStyles}
  font-size: 50px;
`;

export const StyledH2 = styled.h2`
  ${defaultHeaderStyles}
  font-size: 26px;
  line-height: normal;
  padding: 25px;
`;

export const StyledEditOutlined = styled(EditOutlined)`
  font-size: 128px;
`;
