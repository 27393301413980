import { CrudPermissionName, UsIorPermissionName } from '../base';
import { ObjectType } from '@xbcb/shared-types';

const { READ } = CrudPermissionName;
const { SUB_POA_AUTHORITY } = UsIorPermissionName;

export const ForwarderUserShipperPermissionName = {
  ...CrudPermissionName,
};
export const ForwarderUserUsConsigneePermissionName = {
  ...CrudPermissionName,
};
export const ForwarderUserUsIorPermissionName = {
  ...CrudPermissionName,
  SUB_POA_AUTHORITY,
};

export const ForwarderUserTruckerPermissionName = {
  ...CrudPermissionName,
};

export const ForwarderUserFacilityPermissionName = {
  ...CrudPermissionName,
};

export const ForwarderUserSupplierPermissionName = {
  ...CrudPermissionName,
};

export const ForwarderUserCustomsAgentPermissionName = {
  ...CrudPermissionName,
};

export const ForwarderUserUsCustomsBrokerPermissionName = {
  READ,
};

export default {
  [ObjectType.SHIPPER]: ForwarderUserShipperPermissionName,
  [ObjectType.US_CONSIGNEE]: ForwarderUserUsConsigneePermissionName,
  [ObjectType.US_IOR]: ForwarderUserUsIorPermissionName,
  [ObjectType.TRUCKER]: ForwarderUserTruckerPermissionName,
  [ObjectType.FACILITY]: ForwarderUserFacilityPermissionName,
  [ObjectType.SUPPLIER]: ForwarderUserSupplierPermissionName,
  [ObjectType.CUSTOMS_AGENT]: ForwarderUserCustomsAgentPermissionName,
  [ObjectType.US_CUSTOMS_BROKER]: ForwarderUserUsCustomsBrokerPermissionName,
};
