import { recordActionFields, recordFields } from '@xbcb/shared-queries';

export const additionalCaseFields = `caseNumber
name
countryCode
status
effectiveDate
scopeUrl
manufacturerName
sellerName
rates {
  effectiveDate
  addedDate
  inactivatedDate
  adValorem
  specific
  uom
  uomDescription
}
events {
  determination
  event
  federalRegisterCitation
  effectiveDate
  addedDate
  inactivatedDate
}
tariffs {
  htsNumber
  addedDate
}
bondOrCash {
  indicator
  addedDate
  effectiveDate
}
liquidationSuspensions {
  action
  addedDate
  effectiveDate
}`;
export const adCvdCaseFields = `
fragment adCvdCaseFields on AdCvdCase {
    ...recordFields
    ${additionalCaseFields}
  }`;

export const adCvdCaseFragments = `
  ${adCvdCaseFields}
  ${recordFields}
  ${recordActionFields}
`;
