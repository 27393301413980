import { constantCase } from 'change-case';
import { getRecordType, isObjectType } from '@xbcb/core';
import { pluralize } from '@xbcb/js-utils';
import { ObjectType } from '@xbcb/shared-types';

export const locationToObjectType: (
  pathname: string,
) => ObjectType | undefined = (pathname: string) => {
  // if (path.startsWith('/questionnaire')) return 'questionnaire';
  if (pathname === '/assignments' || pathname === '/assignment-workspace')
    return ObjectType.WORK_ORDER_TASK;
  if (pathname === '/sme-teams') return ObjectType.SUBJECT_MATTER_EXPERT_TEAM;

  const kebabCasedObjectType = pathname.split('/')[1];
  const maybeObjectType = constantCase(
    pluralize.singular(kebabCasedObjectType),
  );
  const recordType = getRecordType(kebabCasedObjectType);
  // This covers the case for the NewAppUi as we always have the pluralized
  // record type in the url when visiting a record's page or table
  if (isObjectType(maybeObjectType)) {
    return ObjectType[maybeObjectType];
    // This covers ths case where we don't have the pluralized record type in
    // the url. Currently being used in import sign as the url is typically:
    // /templateDocumentSignRequest_aa96aa78-e63e-4528-be1a-b0902b1ea263
    // so if we can get the record type from the ID, that's acceptable and we
    // return it
  } else if (recordType) {
    return ObjectType[recordType];
    // This covers the sign page in NewAppUi
  } else if (kebabCasedObjectType === 'sign') {
    return getRecordType(pathname.split('/')[2]);
  } else {
    return undefined;
  }
};
