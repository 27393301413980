import React from 'react';
import { Input } from 'antd';
import FormItem from '../FormItem';
import { email as emailRegex } from '@xbcb/regex';
import {
  CssSize,
  NamePath,
  createDataCyValue,
  DataCyPrefix,
  DataCySuffix,
} from '@xbcb/ui-types';

interface EmailProps {
  localNamePath: NamePath;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  size?: CssSize;
  debounce?: boolean;
  readOnly?: boolean;
  $inline?: boolean;
  $spaceBottom?: boolean;
  dataCySuffix?: DataCySuffix;
}

const getValueFromEvent = (e: any) => {
  if (!e || !e.target) {
    return e;
  }
  const val = e.target.value.toLowerCase();
  return val;
};

const Email: React.FC<EmailProps> = ({
  placeholder,
  localNamePath,
  label = 'Email Address',
  required,
  disabled,
  size = CssSize.SHORT,
  debounce = true,
  readOnly,
  $inline,
  $spaceBottom = true,
  dataCySuffix,
}) => {
  return (
    <FormItem
      $itemSize={size}
      label={label}
      rules={[{ required, message: ' ', pattern: emailRegex }]}
      getValueFromEvent={getValueFromEvent}
      name={localNamePath}
      $inline={$inline}
      $removeSpaceBottom={!$spaceBottom}
      $readOnly={readOnly}
      debounce={debounce}
      data-cy={createDataCyValue(DataCyPrefix.EMAIL, dataCySuffix)}
    >
      <Input
        autoComplete="off" // avoid lastpass autofill
        disabled={disabled}
        placeholder={placeholder}
      />
    </FormItem>
  );
};

export default Email;
