export const extensionContentTypeMap = {
  RTF: 'application/rtf',
  HTML: 'text/html',
  GIF: 'image/gif',
  TIF: 'image/tiff',
  TIFF: 'image/tiff',
  DOC: 'application/msword',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLS: 'application/vnd.ms-excel',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PDF: 'application/pdf',
  JPG: 'image/jpg',
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  TXT: 'text/plain',
  CSV: 'text/csv',
};
