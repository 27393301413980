import { CrudPermissionName } from '../base';
import { ObjectType } from '@xbcb/shared-types';

export const OperatorUserOperatorUserPermissionName = {
  ...CrudPermissionName,
};
export const OperatorUserAssignmentTeamPermissionName = {
  ...CrudPermissionName,
};
export const OperatorUserMemoPermissionName = {
  DELETE: 'DELETE',
  READ: 'READ',
};

export default {
  [ObjectType.OPERATOR_USER]: OperatorUserOperatorUserPermissionName,
  [ObjectType.ASSIGNMENT_TEAM]: OperatorUserAssignmentTeamPermissionName,
  [ObjectType.MEMO]: OperatorUserMemoPermissionName,
};
