import React from 'react';
import { FormInstance } from 'antd/lib/form';
import { Select, Option } from '../ClickSelect';
import { selectFilter, show } from '@xbcb/ui-utils';
import { mapStructureCode } from '@xbcb/party-utils';
import { UsBusinessStructure } from '@xbcb/party-types';
import {
  createDataCyValue,
  CssSize,
  DataCyPrefix,
  NamePath,
} from '@xbcb/ui-types';
import FormItem from '../FormItem';

interface StructureProps {
  form: FormInstance;
  localNamePath: NamePath;
  fullNamePath?: NamePath;
  label?: string;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  onChange?: any;
  dataCySuffix?: string;
}
const Structure = ({
  form,
  label = 'Structure',
  localNamePath,
  fullNamePath,
  required,
  readOnly,
  disabled,
  onChange,
  dataCySuffix,
}: StructureProps) => {
  const structureOptions = Object.values(UsBusinessStructure).map(
    (structureCode) => (
      <Option
        key={structureCode}
        value={structureCode}
        data-cy={createDataCyValue(
          DataCyPrefix.STRUCTURE_SELECT_OPTION,
          structureCode as string,
        )}
      >
        {mapStructureCode(structureCode)}
      </Option>
    ),
  );
  // Use localNamePath if fullNamePath was not provided, we assume they are the same
  if (!show({ readOnly, form, field: fullNamePath || localNamePath })) {
    return null;
  }
  return (
    <FormItem
      $itemSize={CssSize.TINY_SHORT}
      name={localNamePath}
      rules={[{ required: required, message: ' ' }]}
      label={label}
      $readOnly={readOnly}
      $inline
      data-cy={createDataCyValue(DataCyPrefix.STRUCTURE_SELECT, dataCySuffix)}
    >
      <Select
        showSearch
        filterOption={selectFilter}
        onChange={onChange}
        notFoundContent="Structure Not Found"
        allowClear
        disabled={disabled}
      >
        {structureOptions}
      </Select>
    </FormItem>
  );
};
export default Structure;
