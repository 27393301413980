import './App.less';
import { Layout } from 'antd';
import React from 'react';
import { ErrorBoundary } from '@xbcb/feedback-components';
import { primaryTheme } from '@xbcb/ui-styles';
import Routes from 'components/Routes';
import { StyledLayout } from './styles';
import { ThemeProvider } from 'styled-components';

const { Content } = Layout;

const App = (): JSX.Element => (
  <ThemeProvider theme={primaryTheme}>
    <StyledLayout>
      <Content
        style={{
          overflowY: 'scroll',
          minHeight: '100%',
          textAlign: 'center',
        }}
      >
        <ErrorBoundary>
          <Routes />
        </ErrorBoundary>
      </Content>
    </StyledLayout>
  </ThemeProvider>
);

export default App;
