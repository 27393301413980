import { createDocuments } from './createDocuments';
import { RcFile } from 'antd/lib/upload';
import { Tag } from '@xbcb/shared-types';
import { DocumentTag } from '@xbcb/document-types';
import { message } from 'antd';
import { reportError, uploadDocuments } from '@xbcb/ui-utils';

type UploadProps = {
  file: RcFile;
  files: RcFile[];
  setIsUploading: any;
  operatorId: string;
  createDocument: any;
  tagsForNewDocuments: Tag[];
  searchDeletedDocuments?: boolean;
  searchQueryTags: Tag[];
  documentTags?: DocumentTag[];
  onSuccess?: any;
  postUpload?: any;
};
export const handleUpload = async ({
  file,
  files,
  setIsUploading,
  operatorId,
  createDocument,
  tagsForNewDocuments,
  searchDeletedDocuments,
  searchQueryTags,
  documentTags,
  onSuccess,
  postUpload,
}: UploadProps) => {
  if (files.indexOf(file) === 0) {
    // prevents duplicate uploads when multiple files are uploaded
    setIsUploading(true);
    try {
      const responses: any = await createDocuments({
        createDocument,
        files,
        operatorId,
        tagsForNewDocuments,
        searchDeletedDocuments,
        searchQueryTags,
        documentTags,
      });

      if (responses) {
        await uploadDocuments(files, responses);
      }
      if (postUpload) {
        await postUpload();
      }
      if (onSuccess) {
        await onSuccess(responses);
      }
    } catch (e) {
      reportError(e);
      message.error('Sorry, an error occurred', 5.0);
    }
    setIsUploading(false);
  }
};
