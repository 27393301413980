import { fromTemporaryCredentials } from '@aws-sdk/credential-providers';
import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import { Cognito } from '@xbcb/aws-utils';
import { Account, AccountType } from '@xbcb/core';
import { UiStage } from '@xbcb/ui-types';

export interface RumCustomAttributes {
  [key: string]: string | number | boolean;
}

export class AwsRumClient {
  client: AwsRum | null;
  accountType: string;
  accountId: string;
  applicationID: string;
  sessionDuration: number;
  stage: UiStage;
  version: string;

  constructor() {
    this.sessionDuration = 3600;
  }

  addSessionAttributes(attributes: RumCustomAttributes): void {
    this.client?.addSessionAttributes(attributes);
  }

  recordPageView(path: string): void {
    this.client?.recordPageView(path);
  }

  recordEvent(eventName: string, details: Record<string, unknown>): void {
    this.client?.recordEvent(eventName, details);
  }

  recordError(error: Error): void {
    this.client?.recordError(error);
  }

  setCredentials(): void {
    if (!this.client) {
      return;
    }

    if (!Cognito.credentialProvider) {
      // Compile error otherwise since this could be null.
      return;
    }

    const provider = fromTemporaryCredentials({
      params: {
        RoleArn: `arn:aws:iam::${this.accountId}:role/CloudWatchRum-CrossAccountSharingRole-${this.accountType}`,
        RoleSessionName: 'rumMonitorSession',
        DurationSeconds: this.sessionDuration,
      },
      clientConfig: {
        region: 'us-west-2',
      },
      masterCredentials: Cognito.credentialProvider,
    });
    this.client?.setAwsCredentials(provider);
  }
  initClient(stage: UiStage, version: string): void {
    if (stage === UiStage.LOCAL) {
      return;
    }
    this.stage = stage;
    this.version = version;
    const isProd = stage === UiStage.PROD;
    this.accountType = isProd ? AccountType.PROD : AccountType.DEV;
    this.accountId = Account[`xbcb-new-app-ui-${this.accountType}`];
    this.applicationID = process.env.REACT_APP_RUM_MONITOR_ID as string;

    if (!this.applicationID) {
      console.warn(
        'Unable to fetch rum settings. Skipping rum client initialisation ',
      );
      return;
    }
    try {
      const config: AwsRumConfig = {
        // Default options generated by RUM
        sessionSampleRate: 1,
        endpoint: 'https://dataplane.rum.us-west-2.amazonaws.com',
        telemetries: [
          'performance',
          'errors',
          ['http', { recordAllRequests: true }],
        ],
        allowCookies: true,
        enableXRay: false,
        // Custom option is not available in aws v1, until we move to v2 we'll set it manually.
        sessionEventLimit: 0, // Disable limit per session
        disableAutoPageView: true, // We manually track page views in order to merge slugs
        sessionAttributes: {
          applicationVersion: this.version,
        },
      };
      const APPLICATION_ID = this.applicationID;
      const APPLICATION_VERSION = '1.0.0';
      const APPLICATION_REGION = 'us-west-2';

      const client = new AwsRum(
        APPLICATION_ID,
        APPLICATION_VERSION,
        APPLICATION_REGION,
        config,
      );

      this.client = client;
      this.setCredentials();
    } catch (error) {
      this.client = null;
      if (this.stage !== UiStage.PROD) {
        console.error(error);
      }
    }
  }
}

export const awsRumClient = new AwsRumClient();
