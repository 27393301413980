import React, { useRef, useCallback } from 'react';
import { Input, Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { LabelAbove } from '@xbcb/static-text-components';
import { PhoneMask, getCodes, show, shouldUpdate } from '@xbcb/ui-utils';
import {
  NamePath,
  createDataCyValue,
  DataCyPrefix,
  DataCySuffix,
} from '@xbcb/ui-types';
import { Option } from '../ClickSelect';
import FormItem from '../FormItem';
import { StyledInputGroup, StyledSelect, StyledDiv } from './styles';

interface PhoneNumberProps {
  form: FormInstance;
  readOnly?: boolean;
  disabled?: boolean;
  required?: boolean;
  localNamePath: NamePath;
  fullNamePath: NamePath;
  label?: string;
  $inline?: boolean;
  $spaceBottom?: boolean;
  defaultCountryCode?: string;
  allowClearCountryCode?: boolean;
  dataCySuffix?: DataCySuffix;
}

const PhoneNumber: React.FC<PhoneNumberProps> = ({
  form,
  readOnly,
  disabled,
  required,
  localNamePath,
  fullNamePath,
  label = 'Phone Number',
  $inline,
  $spaceBottom = true,
  defaultCountryCode,
  allowClearCountryCode = true,
  dataCySuffix,
}) => {
  const Phone = useRef(new PhoneMask());
  const dialingCodes = getCodes().ITU.phone.dial;

  const dialingOptions = Object.keys(dialingCodes).map((dial, index) => (
    <Option key={index} value={dial}>
      +{dial}
    </Option>
  ));

  const countryFieldName = 'country';
  const numberFieldName = 'number';
  const countryFullNamePath = [...fullNamePath, countryFieldName];
  const numberFullNamePath = [...fullNamePath, numberFieldName];

  const getValueFromEvent = useCallback(
    (e: any) =>
      Phone.current.handlePhoneChange(
        e,
        form.getFieldValue([...fullNamePath, countryFieldName]),
      ),
    [form, fullNamePath],
  );

  return show({ readOnly, form, field: fullNamePath }) ? (
    <StyledDiv $inline={$inline} $spaceBottom={$spaceBottom}>
      <LabelAbove text={label} />
      <Form.Item
        shouldUpdate={shouldUpdate([countryFullNamePath, numberFullNamePath])}
        noStyle
      >
        {() => {
          const countryValue = form.getFieldValue(countryFullNamePath);
          const numberValue = form.getFieldValue(numberFullNamePath);
          // Number is required if:
          //  1. required was set to true
          //  2. defaultCountryCode was _not_ provided and the user has
          //     selected a country (aka countryValue is defined)
          // If defaultCountryCode was provided, we don't want to force number
          // to be required unless required was also provided
          const numberRequired =
            required || (!defaultCountryCode && countryValue);
          return (
            <StyledInputGroup compact>
              <FormItem
                rules={[{ required: required || numberValue, message: ' ' }]}
                name={[...localNamePath, countryFieldName]}
                $removeSpaceBottom={!$spaceBottom}
                $readOnly={readOnly}
                initialValue={countryValue ? undefined : defaultCountryCode}
                data-cy={createDataCyValue(
                  DataCyPrefix.PHONE_COUNTRY,
                  dataCySuffix,
                )}
              >
                <StyledSelect
                  showSearch
                  placeholder="+1"
                  showArrow={false}
                  disabled={disabled}
                  filterOption={(input: string, option: any) =>
                    option.props.children
                      .join('')
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  notFoundContent="None"
                  dropdownMatchSelectWidth={false}
                  allowClear={allowClearCountryCode}
                >
                  {dialingOptions}
                </StyledSelect>
              </FormItem>
              <FormItem
                style={{ flex: 1 }}
                name={[...localNamePath, numberFieldName]}
                rules={[{ required: numberRequired, message: ' ' }]}
                $removeSpaceBottom={!$spaceBottom}
                $readOnly={readOnly}
                debounce
                getValueFromEvent={getValueFromEvent}
                data-cy={createDataCyValue(
                  DataCyPrefix.PHONE_NUMBER,
                  dataCySuffix,
                )}
              >
                <Input disabled={disabled} />
              </FormItem>
            </StyledInputGroup>
          );
        }}
      </Form.Item>
    </StyledDiv>
  ) : null;
};

export default PhoneNumber;
