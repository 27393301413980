import { GbIor, DeIor, NlIor, FrIor } from '@xbcb/api-gateway-client';
import { ActivationStatus } from '@xbcb/party-types';

type Ior = GbIor | DeIor | NlIor | FrIor;

export const isEuIorStatusNonMutable = ({
  ior,
  customsBrokerId,
}: {
  ior?: Ior;
  customsBrokerId: string;
}): boolean =>
  Boolean(
    ior?.activations?.some(
      ({ status, customsBroker }) =>
        (status === ActivationStatus.ACTIVATED ||
          status === ActivationStatus.REJECTED) &&
        customsBroker?.id === customsBrokerId,
    ),
  );
