import { partyFragments } from './party';
import { billingDetailsFields } from './billingDetails';
import { forwarderWorkOrderConfirmationConfigurationsFields } from './workOrderConfirmationConfigurations';

const autoSetReadyRequirementsFields = `
  autoSetReadyRequirements {
    documents {
        documentTags
        criteria
        modeOfTransport
    }
  }
`;

const customsEntryServiceLevelAgreementFields = `
  serviceLevelAgreements {
    businessHoursAfterReady
    calendarDaysBeforeArrival
    matchingCriteria {
      modesOfTransport
      badges
    }
  }
`;

export const workOrderConfigFields = `
  workOrderConfig {
    usIsf {
      autoSetReadyStatus
      ${autoSetReadyRequirementsFields}
      serviceLevelAgreements {
        businessHoursAfterReady
      }
    }
    usConsumptionEntry {
      autoSetReadyStatus
      ${autoSetReadyRequirementsFields}
      serviceLevelAgreements {
        businessHoursAfterReady
        calendarDaysBeforeArrival
        matchingCriteria {
          modesOfTransport
          badges
        }
      }
    }
    gbCustomsEntry {
      autoSetReadyStatus
      ${autoSetReadyRequirementsFields}
      ${customsEntryServiceLevelAgreementFields}
    }
    deCustomsEntry {
      autoSetReadyStatus
      ${autoSetReadyRequirementsFields}
      ${customsEntryServiceLevelAgreementFields}
    }
    nlCustomsEntry {
      autoSetReadyStatus
      ${autoSetReadyRequirementsFields}
      ${customsEntryServiceLevelAgreementFields}
    }
    frCustomsEntry {
      autoSetReadyStatus
      ${autoSetReadyRequirementsFields}
      ${customsEntryServiceLevelAgreementFields}
    }
    usType86Entry {
      autoSetReadyStatus
      ${autoSetReadyRequirementsFields}
      serviceLevelAgreements {
        businessHoursAfterReady
        calendarDaysBeforeArrival
        matchingCriteria {
            modesOfTransport
        }
      }
    }
    usIorContinuousBondRequest {
      autoSetReadyStatus
      ${autoSetReadyRequirementsFields}
      serviceLevelAgreements {
        businessHoursAfterReady
      }
      invoiceShipper
    }
    deliveryOrder {
      autoSetReadyStatus
      ${autoSetReadyRequirementsFields}
      serviceLevelAgreements {
        businessHoursAfterReady
      }
    }
  }
`;

const forwarderFragment = `
fragment forwarderFields on Forwarder {
  ...recordFields
  ...partyFields
  ein
  scac
  billingDetails {
   ...billingDetailsFields
  }
  supportsPersonalEffects
  poaMethod
  defaultWorkOrdersConfig {
    us {
      modesOfTransport
      workOrderTypes
    }
  }
  workOrderConfirmationConfigurations {
    ...forwarderWorkOrderConfirmationConfigurationsFields
  }
  ${workOrderConfigFields}
}
`;

export const forwarderFragments = `
${forwarderFragment}
${partyFragments}
${billingDetailsFields}
${forwarderWorkOrderConfirmationConfigurationsFields}
`;
