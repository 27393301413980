const reverse = (promise: Promise<unknown>) => {
  return new Promise((resolve, reject) =>
    Promise.resolve(promise).then(reject, resolve),
  );
};

// returns the first resolved promise. Throw if all promises reject.
// NOTE: This is alternative of 'promise.any()' https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/any. Since ES6 does not support this, it is the alternative.
// referenced: https://stackoverflow.com/questions/39940152/get-first-fulfilled-promise
export const promiseAny = (iterable: Promise<unknown>[]) => {
  return reverse(Promise.all([...iterable].map(reverse)));
};
