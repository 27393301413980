import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { NotFound } from '@xbcb/feedback-components';
import Home from 'components/Home';
import SignPage from 'components/SignPage';

const Routes = () => (
  <Switch>
    <Route path="/" exact component={Home} />
    <Route path="/:recordId" exact component={SignPage} />
    <Route component={NotFound} />
  </Switch>
);

export default Routes;
