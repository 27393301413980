export enum WorkOrderTab {
  Bond = 'Bond',
  Charges = 'Charges',
  CommercialInvoices = 'Commercial Invoices',
  Documents = 'Documents',
  ISF = 'ISF',
  Tasks = 'Tasks',
  Transportation = 'Transportation',
  Summary = 'Summary',
  PSC = 'PSC',
  CbpInBonds = 'CbpInBonds',
  DeliveryOrder = 'Delivery Order',
  Milestones = 'Milestones',
  Exams = 'Exams',
}
