import React from 'react';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import { NamePath } from '@xbcb/ui-types';
import { UsIorNumberType } from '@xbcb/party-types';
import AppRadioGroup from '../AppRadioGroup';

interface NumberIORTypeProps {
  disabled?: boolean;
  readOnly?: boolean;
  showTypes: ShowTypesInterface;
  required?: boolean;
  onChange: (e: RadioChangeEvent) => void;
  $inline?: boolean;
  localNamePath: NamePath;
  initialValue?: any;
}
export interface ShowTypesInterface {
  ein: boolean;
  ssn: boolean;
  cbp: boolean;
}

const IORNumberType = ({
  required,
  showTypes,
  disabled,
  readOnly,
  onChange,
  $inline,
  localNamePath,
  initialValue,
}: NumberIORTypeProps) => {
  const options = [];
  const { EIN, CBP, SSN } = UsIorNumberType;
  if (showTypes.ein) options.push({ value: EIN, label: EIN });
  if (showTypes.ssn) options.push({ value: SSN, label: SSN });
  if (showTypes.cbp) options.push({ value: CBP, label: CBP });
  return (
    <AppRadioGroup
      onChange={onChange}
      size="small"
      disabled={disabled}
      options={options}
      localNamePath={localNamePath}
      rules={[{ required, message: '' }]}
      label="IOR Number Type"
      $inline={$inline}
      readOnly={readOnly}
      initialValue={initialValue}
    />
  );
};

export default IORNumberType;
