export enum ChargeCode {
  AD_DUTY = 'AD_DUTY', // Duty associated with adCase
  ADDITIONAL_CUSTOMS_HANDLING_FEE = 'ADDITIONAL_CUSTOMS_HANDLING_FEE', // ACU
  ADDITIONAL_CUSTOMS_INVOICE = 'ADDITIONAL_CUSTOMS_INVOICE', // INV
  ADDITIONAL_CUSTOMS_LINE = 'ADDITIONAL_CUSTOMS_LINE', // LIN
  AGENCY_FEE = 'AGENCY_FEE', // AGF
  AMENDMENT_FEE = 'AMENDMENT_FEE', // CEA
  AMS_QUERY = 'AMS_QUERY',
  BOND_SETUP_FEE = 'BOND_SETUP_FEE', // BON
  CANADIAN_ENTRY_FEE = 'CANADIAN_ENTRY_FEE',
  CANCELATION_FEE = 'CANCELATION_FEE',
  CLASSIFICATION = 'CLASSIFICATION',
  CONSOLIDATED_ENTRY_FEE = 'CONSOLIDATED_ENTRY_FEE',
  COMPLIANCE_CONSULTING = 'COMPLIANCE_CONSULTING',
  CONTINUOUS_BOND = 'CONTINUOUS_BOND', // BOA
  COURIER_FEE = 'COURIER_FEE', // COU
  CUSTOMS_FINES_PASSTHROUGH = 'CUSTOMS_FINES_PASSTHROUGH', // CFN
  CV_DUTY = 'CV_DUTY', // Duty associated with cvCase
  DEMURRAGE = 'DEMURRAGE', // EXD
  DESTINATION_PORT_INFRASTRUCTURE_FEE = 'DESTINATION_PORT_INFRASTRUCTURE_FEE', // DIA/DPI
  DESTINATION_PORT_SECURITY_FEE = 'DESTINATION_PORT_SECURITY_FEE', // DPS/DSA
  DESTRUCTION_FEE = 'DESTRUCTION_FEE', // DEF
  DIRECT_TRADER_INPUT_FEE = 'DIRECT_TRADER_INPUT_FEE', // DTA
  DISBURSEMENT_FEE = 'DISBURSEMENT_FEE', // DIS
  DUTY = 'DUTY', // Duty for EU cases DUT
  DUTY_DAILY = 'DUTY_DAILY', // Duty associated with a Daily Statement
  DUTY_PMS = 'DUTY_PMS', // Duty associated with a Periodic Monthly Statement
  ENTRY_FEE = 'ENTRY_FEE', // CUS
  EXAM_FEE = 'EXAM_FEE', // EXM
  VACIS_EXAM_FEE = 'VACIS_EXAM_FEE', // EXMV
  MET_EXAM_FEE = 'MET_EXAM_FEE', // EXMM
  AT_CET_EXAM_FEE = 'AT_CET_EXAM_FEE', // EXMA
  IBET_EXAM_FEE = 'IBET_EXAM_FEE', // EXMI
  AGRICULTURE_EXAM_FEE = 'AGRICULTURE_EXAM_FEE', // EXMG
  CPSC_EXAM_FEE = 'CPSC_EXAM_FEE', // EXMC
  EXAM_SITE_STORAGE = 'EXAM_SITE_STORAGE', // EXMS
  FDA_EXAMS = 'FDA_EXAMS', // EXMF
  RECONDITIONING_FEES = 'RECONDITIONING_FEES', // REC
  EXAM_HANDLING = 'EXAM_HANDLING', // EXH
  HAZMAT = 'HAZMAT', // HZT
  HOT_ENTRY_FEE = 'HOT_ENTRY_FEE',
  HOT_IN_BOND_FEE = 'HOT_IN_BOND_FEE',
  HOT_ISF_FEE = 'HOT_ISF_FEE',
  HTS_CLASSIFICATION_ADDITIONAL_TARRIF_HEADINGS = 'HTS_CLASSIFICATION_ADDITIONAL_TARRIF_HEADINGS', // HTS
  IMPORTER_SURCHARGE = 'IMPORTER_SURCHARGE',
  IN_BOND_FEE = 'IN_BOND_FEE',
  ISF_BOND = 'ISF_BOND',
  ISF_FEE = 'ISF_FEE',
  IMPORT_VALUE_ADDED_TAX = 'IMPORT_VALUE_ADDED_TAX',
  MANAGEMENT_FEE = 'MANAGEMENT_FEE',
  MANUAL_CREATION_FEE = 'MANUAL_CREATION_FEE',
  MERCHANT_FEE = 'MERCHANT_FEE',
  MERCHANDISE_PROCESSING_FEE = 'MERCHANDISE_PROCESSING_FEE', // MPF
  ONSITE_FEE = 'ONSITE_FEE', // OSF
  PALLETIZATION_DESTINATION_FEE = 'PALLETIZATION_DESTINATION_FEE', // PAL
  PALLET_EXCHANGE_CHARGE = 'PALLET_EXCHANGE_CHARGE', // PEC
  PARTNER_GOVERNMENT_AGENCY_FEE = 'PARTNER_GOVERNMENT_AGENCY_FEE', // PGC
  PARTNER_GOVERNMENT_AGENCY_HANDLING_FEE = 'PARTNER_GOVERNMENT_AGENCY_HANDLING_FEE', // PGH
  PIERPASS_ADMIN = 'PIERPASS_ADMIN',
  PIERPASS_TMF = 'PIERPASS_TMF',
  POA_SETUP_FEE = 'POA_SETUP_FEE', // POA
  POST_SUMMARY_CORRECTION_FEE = 'POST_SUMMARY_CORRECTION_FEE',
  PROTEST_FEE = 'PROTEST_FEE',
  SELF_FILED_ENTRY_FEE = 'SELF_FILED_ENTRY_FEE',
  SELF_FILED_IN_BOND_FEE = 'SELF_FILED_IN_BOND_FEE',
  SELF_FILED_ISF_FEE = 'SELF_FILED_ISF_FEE',
  SELLER_SUPPORT_FEE = 'SELLER_SUPPORT_FEE', // SEF
  SINGLE_ENTRY_BOND = 'SINGLE_ENTRY_BOND',
  SUBSCRIPTION_FEE = 'SUBSCRIPTION_FEE', // external user subscription fee
  TAX = 'TAX', // TAX
  TERMINAL_FEES = 'TERMINAL_FEES',
  TRUCKING = 'TRUCKING',
  // this covers edge cases in our finance integrations where we are unable to identify upstream
  // charges from other systems (e.g. AP/AR) but still need to supply a charge code in CBMS
  UNCATEGORIZED_CHARGE = 'UNCATEGORIZED_CHARGE',
  VALUE_ADDED_TAX = 'VALUE_ADDED_TAX', // VAT
  WEEKEND_FEE = 'WEEKEND_FEE',
}
