import React from 'react';
import { Input } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { createDataCyValue, DataCyPrefix, DataCySuffix } from '@xbcb/ui-types';
import { formatString } from '@xbcb/ui-utils';
import FormItem from '../FormItem';
import { StyledDiv } from './styles';

export type GlobalNameProps = {
  form: FormInstance;
  disabled?: boolean;
  readOnly?: boolean;
  hideLabel?: boolean;
  label?: React.ReactNode;
  dataCySuffix?: DataCySuffix;
};

const GlobalName: React.FC<GlobalNameProps> = ({
  form,
  disabled,
  readOnly,
  hideLabel,
  label,
  dataCySuffix,
}) => (
  <StyledDiv>
    <FormItem
      // Default to `Name`, but allow for custom label while still providing
      // the ability to hide the label
      label={hideLabel ? undefined : label || 'Name'}
      name="name"
      rules={[{ required: true, message: ' ' }]}
      $readOnly={readOnly}
      data-cy={createDataCyValue(DataCyPrefix.GLOBAL_NAME, dataCySuffix)}
    >
      <Input disabled={disabled} onBlur={() => formatString(form, ['name'])} />
    </FormItem>
  </StyledDiv>
);

export default GlobalName;
