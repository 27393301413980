import { ModeOfTransport } from './modeOfTransport';

export enum LoadType {
  LCL = 'LCL',
  FCL = 'FCL',
  AIR_FREIGHT = 'AIR_FREIGHT',
  SMALL_PARCEL = 'SMALL_PARCEL',
}

export const ModeOfTransportLoadTypeMap = {
  [ModeOfTransport.OCEAN]: [LoadType.FCL, LoadType.LCL],
  [ModeOfTransport.AIR]: [LoadType.AIR_FREIGHT, LoadType.SMALL_PARCEL],
  [ModeOfTransport.RAIL]: [],
  [ModeOfTransport.TRUCK]: [],
};
