import {
  createStore,
  applyMiddleware,
  Middleware,
  Dispatch,
  AnyAction,
} from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { UiStage } from '@xbcb/ui-types';
import { getEnv } from '@xbcb/ui-utils';

export const create = () => {
  const logMiddleWare = createLogger({
    predicate: (getState, action) => {
      if (
        action.type.startsWith('FETCH_') ||
        action.type.startsWith('@@router/') ||
        action.type.endsWith('_LOADING')
      ) {
        return false;
      } else {
        return true;
      }
    },
    collapsed: true,
  }) as Middleware<{}, any, Dispatch<AnyAction>>;

  const middleware: Middleware<any, any, any>[] = [];
  middleware.push(thunkMiddleware);

  const { stage } = getEnv();
  if (stage !== UiStage.PROD) middleware.push(logMiddleWare);

  const composeEnhancers = composeWithDevTools({
    name: 'INLT',
    maxAge: 10,
    latency: 500,
  });

  const enhancer = composeEnhancers(applyMiddleware(...middleware));

  return createStore(rootReducer, enhancer);
};
