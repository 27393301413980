import {
  CrudPermissionName,
  UsInBondPermissionName,
  UsIsfPermissionName,
} from '../base';
import { ObjectType } from '@xbcb/shared-types';

// TODO does it make sense to allow Create permissions for system generated WOs?

export const OperatorUserUsIsfPermissionName = {
  ...UsIsfPermissionName,
};

export const OperatorUserUsIorContinuousBondRequestPermissionName = {
  ...CrudPermissionName,
};

export const OperatorUserUsIorActivationPermissionName = {
  ...CrudPermissionName,
};

export const OperatorUserWorkOrderTaskPermissionName = {
  ADMIN: 'ADMIN',
  READ: 'READ',
  UPDATE: 'UPDATE',
};

export const OperatorUserUsConsumptionEntryPermissionName = {
  ...CrudPermissionName,

  UPSERT_CBP_TRANSACTION: 'UPSERT_SUMMARY_CBP_TRANSACTION',
  DELETE_CBP_TRANSACTION: 'DELETE_SUMMARY_CBP_TRANSACTION',
  QUERY_CBP_TRANSACTION: 'QUERY_SUMMARY_CBP_TRANSACTION',

  UPDATE_RELEASE_CBP_TRANSACTION: 'UPDATE_RELEASE_CBP_TRANSACTION',
  REPLACE_RELEASE_CBP_TRANSACTION: 'REPLACE_RELEASE_CBP_TRANSACTION',
  DELETE_RELEASE_CBP_TRANSACTION: 'DELETE_CBP_TRANSACTION',

  UPDATE_PGA_CBP_TRANSACTION: 'UPDATE_PGA_CBP_TRANSACTION',

  AUTHORIZE_STATEMENT_CBP_TRANSACTION: 'AUTHORIZE_STATEMENT_CBP_TRANSACTION',
  UPDATE_STATEMENT_CBP_TRANSACTION: 'UPDATE_STATEMENT_CBP_TRANSACTION',
};

export const OperatorUserUsType86EntryPermissionName = {
  ...CrudPermissionName,

  CREATE_RELEASE_CBP_TRANSACTION: 'CREATE_RELEASE_CBP_TRANSACTION',
  UPDATE_RELEASE_CBP_TRANSACTION: 'UPDATE_RELEASE_CBP_TRANSACTION',
  DELETE_RELEASE_CBP_TRANSACTION: 'DELETE_RELEASE_CBP_TRANSACTION',

  UPDATE_PGA_CBP_TRANSACTION: 'UPDATE_PGA_CBP_TRANSACTION',
};

export const OperatorUserUsPostSummaryCorrectionPermissionName = {
  ...CrudPermissionName,

  UPSERT_CBP_TRANSACTION: 'UPSERT_POST_SUMMARY_CORRECTION_CBP_TRANSACTION',
  // Cannot modify release, pga, or statement data w/ a PSC
  // Cannot delete a PSC w/ CBP (or any disposed Entry Summary)
  // Querying a PSC w/ CBP is identical to the querying an entry, so the permission is not duplicated.
};

export const OperatorUserUsInBondPermissionName = {
  ...UsInBondPermissionName,
};

export const OperatorUserUsDrawbackClaimPermissionName = {
  ...CrudPermissionName,
};

export const OperatorUserDeliveryOrderPermissionName = {
  ...CrudPermissionName,
};

export const OperatorUserGbCustomsEntryPermissionName = {
  ...CrudPermissionName,
};

export const OperatorUserDeCustomsEntryPermissionName = {
  ...CrudPermissionName,
};

export const OperatorUserNlCustomsEntryPermissionName = {
  ...CrudPermissionName,
};

export const OperatorUserFrCustomsEntryPermissionName = {
  ...CrudPermissionName,
};

export const OperatorUserGbIorActivationPermissionName = {
  ...CrudPermissionName,
};

export const OperatorUserDeIorActivationPermissionName = {
  ...CrudPermissionName,
};

export const OperatorUserNlIorActivationPermissionName = {
  ...CrudPermissionName,
};

export const OperatorUserFrIorActivationPermissionName = {
  ...CrudPermissionName,
};

export default {
  [ObjectType.WORK_ORDER_TASK]: OperatorUserWorkOrderTaskPermissionName,
  [ObjectType.US_ISF]: OperatorUserUsIsfPermissionName,
  [ObjectType.US_CONSUMPTION_ENTRY]:
    OperatorUserUsConsumptionEntryPermissionName,
  [ObjectType.US_IOR_CONTINUOUS_BOND_REQUEST]:
    OperatorUserUsIorContinuousBondRequestPermissionName,
  [ObjectType.US_POST_SUMMARY_CORRECTION]:
    OperatorUserUsPostSummaryCorrectionPermissionName,
  [ObjectType.US_IN_BOND]: OperatorUserUsInBondPermissionName,
  [ObjectType.US_DRAWBACK_CLAIM]: OperatorUserUsDrawbackClaimPermissionName,
  [ObjectType.US_IOR_ACTIVATION]: OperatorUserUsIorActivationPermissionName,
  [ObjectType.DELIVERY_ORDER]: OperatorUserDeliveryOrderPermissionName,
  [ObjectType.GB_CUSTOMS_ENTRY]: OperatorUserGbCustomsEntryPermissionName,
  [ObjectType.DE_CUSTOMS_ENTRY]: OperatorUserDeCustomsEntryPermissionName,
  [ObjectType.NL_CUSTOMS_ENTRY]: OperatorUserNlCustomsEntryPermissionName,
  [ObjectType.FR_CUSTOMS_ENTRY]: OperatorUserFrCustomsEntryPermissionName,
  [ObjectType.US_TYPE86_ENTRY]: OperatorUserUsType86EntryPermissionName,
  [ObjectType.GB_IOR_ACTIVATION]: OperatorUserGbIorActivationPermissionName,
  [ObjectType.DE_IOR_ACTIVATION]: OperatorUserDeIorActivationPermissionName,
  [ObjectType.NL_IOR_ACTIVATION]: OperatorUserNlIorActivationPermissionName,
  [ObjectType.FR_IOR_ACTIVATION]: OperatorUserFrIorActivationPermissionName,
};
