import React from 'react';
import { Input } from 'antd';
import { show } from '@xbcb/ui-utils';
import { FormInstance } from 'antd/lib/form';
import { StyledDiv, StyledTooltip } from './styles';
import {
  createDataCyValue,
  CssSize,
  DataCyPrefix,
  DataCySuffix,
} from '@xbcb/ui-types';
import FormItem from '../FormItem';
import { Rule } from 'rc-field-form/lib/interface';

interface DUNSNumberProps {
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  form: FormInstance;
  $inline?: boolean;
  dataCySuffix?: DataCySuffix;
}

const validateDunsNumber = async (rule: Rule, value: string | number) => {
  const dunsRegex = /^\d{9}$/;
  if (
    value &&
    'UNK' !== value.toString() &&
    !dunsRegex.test(value.toString())
  ) {
    throw new Error('Invalid DUNS Number');
  }
};

const DUNSNumber: React.FC<DUNSNumberProps> = ({
  readOnly = false,
  disabled,
  form,
  $inline,
  className,
  dataCySuffix,
}: DUNSNumberProps) => {
  const field = 'duns';
  const showComponent = show({ readOnly, form, field });
  return showComponent ? (
    <StyledDiv $inline={$inline} className={className}>
      <FormItem
        $itemSize={CssSize.TINY_SHORT}
        $inline
        name={field}
        rules={[
          {
            validator: validateDunsNumber,
            required: false,
            message: ' ',
          },
        ]}
        // TODO For better UX, allow only valid characters to be input in duns number field i.e. UNK or 9 digit number
        // For this, need to fix getDunsNumber and remove validateDunsNumber()
        // getValueFromEvent={getDunsNumber}
        label="DUNS Number"
        $readOnly={readOnly}
        data-cy={createDataCyValue(DataCyPrefix.DUNS_NUMBER, dataCySuffix)}
      >
        <Input placeholder="NNNNNNNNN" disabled={disabled} />
      </FormItem>
      <StyledTooltip
        spaceTop
        title="The Food and Drug Administration (FDA) has adopted the use of a DUNS number to identify importers of food products. If this party is an importer of food products, enter their DUNS number here. FDA is rapidly expanding the use of DUNS numbers and encourages foreign suppliers to register, as well. If your supplier has a DUNS, please enter it here, as well."
      />
    </StyledDiv>
  ) : null;
};

export default DUNSNumber;
