import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import uiReducer from './uiReducer';
import dataReducer from './dataReducer';

const fetchingReducer = handleActions(
  {
    FETCH_START: (state, action) => true,
    FETCH_STOP: (state, action) => false,
  },
  true,
);

export default combineReducers({
  isFetching: fetchingReducer,
  data: dataReducer,
  ui: uiReducer,
});
