export enum InvoiceDocumentGenerationType {
  AMAZON_GLOBAL_LOGISTICS_INVOICE = 'AMAZON_GLOBAL_LOGISTICS_INVOICE',
  BROKER_BILLING_PARTY_INVOICE = 'BROKER_BILLING_PARTY_INVOICE',
  EXTERNAL_INVOICE = 'EXTERNAL_INVOICE',
  REQUEST_FOR_PAYMENT = 'REQUEST_FOR_PAYMENT', // used for EU invoices
}

export enum InvoiceCreditNoteDocumentGenerationType {
  EXTERNAL_CREDIT_NOTE = 'EXTERNAL_CREDIT_NOTE',
  BROKER_BILLING_PARTY_CREDIT_NOTE = 'BROKER_BILLING_PARTY_CREDIT_NOTE',
  REQUEST_FOR_PAYMENT_CREDIT_NOTE = 'REQUEST_FOR_PAYMENT_CREDIT_NOTE', // used for EU invoices
}
