import React from 'react';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import { Popconfirm } from 'antd';
import { StyledButton } from './styles';

export interface MinusButtonProps {
  label: string;
  disabled?: boolean;
  lineItem?: boolean;
  firstRow?: boolean;
  onRemove: () => void;
  className?: string;
  tabIndex?: number;
  dataCyPrefix?: string;
}

const MinusButton: React.FC<MinusButtonProps> = ({
  label,
  dataCyPrefix,
  disabled,
  lineItem,
  onRemove,
  firstRow,
  className,
  tabIndex,
}) => {
  return (
    <Popconfirm
      title={`Remove ${label}?`}
      onConfirm={(e) => {
        if (e !== undefined) {
          e.stopPropagation();
          onRemove();
        }
      }}
      onCancel={(e) => {
        if (e !== undefined) e.stopPropagation();
      }}
      okText="Yes"
      okButtonProps={{ danger: true }}
      cancelText="No"
      placement="right"
    >
      <StyledButton
        className={className}
        $firstRow={firstRow}
        $lineItem={lineItem}
        disabled={disabled}
        type="danger"
        size={lineItem ? 'small' : undefined}
        ghost
        onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
          e.stopPropagation()
        }
        shape="circle"
        icon={<CloseCircleOutlined />}
        tabIndex={tabIndex}
      />
    </Popconfirm>
  );
};

export default MinusButton;
