import React from 'react';
import { FormInstance } from 'antd/lib/form';
import { selectFilter } from '@xbcb/ui-utils';
import FormItem from '../FormItem';
import { Select } from '../ClickSelect';
import { CssSize, NamePath } from '@xbcb/ui-types';

interface YearSelectProps {
  form: FormInstance;
  localNamePath: NamePath;
  hideLabel?: boolean;
  label: string;
  readOnly?: boolean;
  disabled?: boolean;
  $inline?: boolean;
  required?: any;
  startYear: number;
  endYear: number;
  size?: CssSize;
  restProps?: any[];
}

const YearSelect = ({
  form,
  localNamePath,
  hideLabel,
  label = 'Year',
  readOnly,
  disabled,
  $inline,
  required,
  startYear,
  endYear,
  size = CssSize.TINY,
  ...restProps
}: YearSelectProps) => {
  const options = [];
  for (let index = endYear; index >= startYear; index--) {
    options.push(
      <Select.Option value={index} key={`${index}`}>
        {index}
      </Select.Option>,
    );
  }
  return (
    <FormItem
      $itemSize={size}
      label={!hideLabel && label}
      name={localNamePath}
      rules={[{ required, message: ' ' }]}
      getValueFromEvent={(year) => year && parseInt(year, 10)}
      $readOnly={readOnly}
      $inline={$inline}
    >
      <Select
        showSearch
        allowClear
        disabled={disabled}
        filterOption={selectFilter}
        notFoundContent="Invalid year"
        dropdownMatchSelectWidth={false}
        {...restProps}
      >
        {options}
      </Select>
    </FormItem>
  );
};

export default YearSelect;
