import { FeatureFlagService } from '../featureFlagService';
import { Stage } from '@xbcb/core';

export const MilestoneNotificationFeatures = {
  NEW_MILESTONE_NOTIFICATION_PROCESS: 'NEW_MILESTONE_NOTIFICATION_PROCESS',
};

export const MILESTONE_NOTIFICATION_FEATURE = new FeatureFlagService([
  {
    feature: MilestoneNotificationFeatures.NEW_MILESTONE_NOTIFICATION_PROCESS,
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA, Stage.PROD],
  },
]);
