import { split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { batchHttpLink, httpLink } from './batchHttp';
import { wsLink } from './ws';

const nonBatchedQueries = ['searchWorkOrderTasks'];

export const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  split(
    ({ operationName }) => nonBatchedQueries.includes(operationName),
    httpLink,
    batchHttpLink,
  ),
);
