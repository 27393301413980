import React from 'react';
import { render } from 'react-dom';
import { register as registerServiceWorker } from './registerAppServiceWorker';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { client } from '@xbcb/apollo-client'; // not using redux currently
import { Provider } from 'react-redux';
import { create } from './store';
import App from 'components/App';
import { polyfillLoader } from 'polyfill-io-feature-detection';
import allSettled from 'promise.allsettled';

function main() {
  const store = create();
  render(
    <Provider store={store}>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ApolloProvider>
    </Provider>,
    document.getElementById('root'),
  );

  registerServiceWorker({
    onUpdate: async (registration) => {
      // We want to run this code only if we detect a new service worker is
      // waiting to be activated.
      // Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
      if (registration && registration.waiting) {
        // Makes Workbox call skipWaiting()
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      }
    },
  });
}

allSettled.shim(); // will be a no-op if not needed

if (!Object.values || !Element.prototype.prepend) {
  polyfillLoader({
    features:
      'fetch,Set,Object.assign,Element.prototype.prepend,String.prototype.includes,Array.prototype.includes,Object.values,String.prototype.startsWith,String.prototype.endsWith,Array.prototype.find,String.fromCodePoint,Object.entries',
    onCompleted: main,
  });
} else {
  main();
}
