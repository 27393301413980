import { FeatureFlagService } from '../featureFlagService';
import { Stage } from '@xbcb/core';

const largeEntryUXFeatureName = 'LARGE_ENTRY_UX_FEATURE';

export const LargeEntryUXFeatures = {
  LARGE_ENTRY_UX_FEATURE: largeEntryUXFeatureName,
};

const LARGE_ENTRY_UX_FEATURE = new FeatureFlagService([
  {
    feature: largeEntryUXFeatureName,
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA],
    operators: {
      [Stage.GAMMA]: [],
    },
  },
]);

export { LARGE_ENTRY_UX_FEATURE, largeEntryUXFeatureName };
