export const workOrderInquiryReasonCodeDescription =
  'The reason code for the work order inquiry';

export enum UsIsfInquiryReasonCode {
  // # UsIsf
  // ## UsIsf - Documentation
  US_ISF_MISSING_ISF_INFO = 'US_ISF_MISSING_ISF_INFO', // deprecated
  US_ISF_MISSING_COUNTRY_OF_ORIGION = 'US_ISF_MISSING_COUNTRY_OF_ORIGION',
  US_ISF_MISSING_HTS_CODE = 'US_ISF_MISSING_HTS_CODE',
  US_ISF_MISSING_MBL_OR_HBL = 'US_ISF_MISSING_MBL_OR_HBL',
  US_ISF_MISSING_SELLER_OR_MANUFACTURER_INFO = 'US_ISF_MISSING_SELLER_OR_MANUFACTURER_INFO',
  US_ISF_INFORMATION_NOT_IN_ENGLISH = 'US_ISF_INFORMATION_NOT_IN_ENGLISH',
  US_ISF_INVALID_HTS_CODE = 'US_ISF_INVALID_HTS_CODE',
  US_ISF_INVALID_BOND = 'US_ISF_INVALID_BOND',
  US_ISF_IOR_MISMATCH = 'US_ISF_IOR_MISMATCH',
  US_ISF_MISSING_ASI_INFO = 'US_ISF_MISSING_ASI_INFO',
  US_ISF_MISSING_ISF_FORM = 'US_ISF_MISSING_ISF_FORM',
  US_ISF_MISSING_ASI_FORM = 'US_ISF_MISSING_ASI_FORM',
  US_ISF_OTHER_DATA_MISSING = 'US_ISF_OTHER_DATA_MISSING',
  US_ISF_TECH_ISSUES = 'US_ISF_TECH_ISSUES',
}

export enum UsConsumptionEntryInquiryReasonCode {
  // # UsConsumptionEntry
  // ## UsConsumptionEntry - Commercial Invoice & Packing List Data
  US_CONSUMPTION_ENTRY_INVOICE_IS_NOT_IN_ENGLISH = 'US_CONSUMPTION_ENTRY_INVOICE_IS_NOT_IN_ENGLISH',
  US_CONSUMPTION_ENTRY_MISSING_PRODUCT_CLASSIFICATIONS = 'US_CONSUMPTION_ENTRY_MISSING_PRODUCT_CLASSIFICATIONS',
  US_CONSUMPTION_ENTRY_MISSING_COUNTRY_OF_ORIGIN_DATA = 'US_CONSUMPTION_ENTRY_MISSING_COUNTRY_OF_ORIGIN_DATA',
  US_CONSUMPTION_ENTRY_FULL_NAME_AND_ADDRESS_OF_SELLING_PARTY_NOT_LISTED = 'US_CONSUMPTION_ENTRY_FULL_NAME_AND_ADDRESS_OF_SELLING_PARTY_NOT_LISTED',
  US_CONSUMPTION_ENTRY_FULL_NAME_AND_ADDRESS_OF_MANUFACTURER_NOT_LISTED = 'US_CONSUMPTION_ENTRY_FULL_NAME_AND_ADDRESS_OF_MANUFACTURER_NOT_LISTED',
  US_CONSUMPTION_ENTRY_MISSING_CURRENCY = 'US_CONSUMPTION_ENTRY_MISSING_CURRENCY',
  US_CONSUMPTION_ENTRY_MISSING_LINE_QUANTITY = 'US_CONSUMPTION_ENTRY_MISSING_LINE_QUANTITY',
  US_CONSUMPTION_ENTRY_MISSING_UNIT_COST = 'US_CONSUMPTION_ENTRY_MISSING_UNIT_COST',
  US_CONSUMPTION_ENTRY_MISSING_EXTENDED_COST = 'US_CONSUMPTION_ENTRY_MISSING_EXTENDED_COST',
  US_CONSUMPTION_ENTRY_MISSING_REQUIRED_NET_WEIGHT = 'US_CONSUMPTION_ENTRY_MISSING_REQUIRED_NET_WEIGHT',
  US_CONSUMPTION_ENTRY_MISSING_HTS_REPORTING_QUANTITY = 'US_CONSUMPTION_ENTRY_MISSING_HTS_REPORTING_QUANTITY',
  US_CONSUMPTION_ENTRY_CARTON_COUNT_DOES_NOT_MATCH_PIECE_COUNT = 'US_CONSUMPTION_ENTRY_CARTON_COUNT_DOES_NOT_MATCH_PIECE_COUNT',
  US_CONSUMPTION_ENTRY_INCORRECT_HTS_CODE = 'US_CONSUMPTION_ENTRY_INCORRECT_HTS_CODE',
  US_CONSUMPTION_ENTRY_PENDING_CONSIGNEE_EIN_NUMBER = 'US_CONSUMPTION_ENTRY_PENDING_CONSIGNEE_EIN_NUMBER',
  US_CONSUMPTION_ENTRY_COMMERCIAL_INVOICES_AND_PACKING_LIST_DATA_OTHER_DATA_MISSING = 'US_CONSUMPTION_ENTRY_COMMERCIAL_INVOICES_AND_PACKING_LIST_DATA_OTHER_DATA_MISSING',
  // ## UsConsumptionEntry - PGA Documentation
  // ### UsConsumptionEntry - PGA Documentation (FDA)
  US_CONSUMPTION_ENTRY_FDA_MISSING_MEDICAL_DEVICE_INFO = 'US_CONSUMPTION_ENTRY_FDA_MISSING_MEDICAL_DEVICE_INFO',
  US_CONSUMPTION_ENTRY_FDA_MISSING_DRUG_INFO = 'US_CONSUMPTION_ENTRY_FDA_MISSING_DRUG_INFO',
  US_CONSUMPTION_ENTRY_FDA_MISSING_RADIATION_EMITTING_DEVICE_INFO = 'US_CONSUMPTION_ENTRY_FDA_MISSING_RADIATION_EMITTING_DEVICE_INFO',
  US_CONSUMPTION_ENTRY_FDA_MISSING_FOODS_INFO = 'US_CONSUMPTION_ENTRY_FDA_MISSING_FOODS_INFO',
  US_CONSUMPTION_ENTRY_FDA_MISSING_COSMETICS_INFO = 'US_CONSUMPTION_ENTRY_FDA_MISSING_COSMETICS_INFO',
  US_CONSUMPTION_ENTRY_FDA_MISSING_TOBACCO_INFO = 'US_CONSUMPTION_ENTRY_FDA_MISSING_TOBACCO_INFO',
  US_CONSUMPTION_ENTRY_FDA_MISSING_BIOLOGICS_INFO = 'US_CONSUMPTION_ENTRY_FDA_MISSING_BIOLOGICS_INFO',
  US_CONSUMPTION_ENTRY_FDA_MISSING_ANIMAL_DRUGS_AND_DEVICES_INFO = 'US_CONSUMPTION_ENTRY_FDA_MISSING_ANIMAL_DRUGS_AND_DEVICES_INFO',
  // ### UsConsumptionEntry - PGA Documentation (EPA)
  US_CONSUMPTION_ENTRY_EPA_MISSING_TSCA_INFO = 'US_CONSUMPTION_ENTRY_EPA_MISSING_TSCA_INFO',
  US_CONSUMPTION_ENTRY_EPA_MISSING_PESTICIDES_INFO = 'US_CONSUMPTION_ENTRY_EPA_MISSING_PESTICIDES_INFO',
  US_CONSUMPTION_ENTRY_EPA_MISSING_VEHICLES_AND_ENGINES_INFO = 'US_CONSUMPTION_ENTRY_EPA_MISSING_VEHICLES_AND_ENGINES_INFO',
  // ### UsConsumptionEntry - PGA Documentation (NHTSA)
  US_CONSUMPTION_ENTRY_NHTSA_MISSING_HS7 = 'US_CONSUMPTION_ENTRY_NHTSA_MISSING_HS7',
  // ### UsConsumptionEntry - PGA Documentation (FWS)
  US_CONSUMPTION_ENTRY_FWS_MISSING_FWS_DOCUMENTATION = 'US_CONSUMPTION_ENTRY_FWS_MISSING_FWS_DOCUMENTATION',
  // ### UsConsumptionEntry - PGA Documentation (APHIS)
  US_CONSUMPTION_ENTRY_APHIS_MISSING_LACEY_ACT_INFO = 'US_CONSUMPTION_ENTRY_APHIS_MISSING_LACEY_ACT_INFO',
  US_CONSUMPTION_ENTRY_APHIS_MISSING_APHIS_CORE_INFO = 'US_CONSUMPTION_ENTRY_APHIS_MISSING_APHIS_CORE_INFO',
  US_CONSUMPTION_ENTRY_APHIS_MISSING_MISCELLANEOUS_AND_PROCESSED_PRODUCTS_INFO = 'US_CONSUMPTION_ENTRY_APHIS_MISSING_MISCELLANEOUS_AND_PROCESSED_PRODUCTS_INFO',
  US_CONSUMPTION_ENTRY_APHIS_MISSING_ANIMAL_PRODUCTS_AND_BYPRODUCTS_INFO = 'US_CONSUMPTION_ENTRY_APHIS_MISSING_ANIMAL_PRODUCTS_AND_BYPRODUCTS_INFO',
  US_CONSUMPTION_ENTRY_APHIS_MISSING_RELATED_ANIMAL_PRODUCTS_INFO = 'US_CONSUMPTION_ENTRY_APHIS_MISSING_RELATED_ANIMAL_PRODUCTS_INFO',
  US_CONSUMPTION_ENTRY_APHIS_MISSING_SEEDS_NOT_FOR_PLANTING_INFO = 'US_CONSUMPTION_ENTRY_APHIS_MISSING_SEEDS_NOT_FOR_PLANTING_INFO',
  US_CONSUMPTION_ENTRY_APHIS_MISSING_CUT_FLOWERS_INFO = 'US_CONSUMPTION_ENTRY_APHIS_MISSING_CUT_FLOWERS_INFO',
  US_CONSUMPTION_ENTRY_APHIS_MISSING_PROPAGATIVE_MATERIAL_INFO = 'US_CONSUMPTION_ENTRY_APHIS_MISSING_PROPAGATIVE_MATERIAL_INFO',
  US_CONSUMPTION_ENTRY_APHIS_MISSING_FRUITS_AND_VEGETABLES_INFO = 'US_CONSUMPTION_ENTRY_APHIS_MISSING_FRUITS_AND_VEGETABLES_INFO',
  US_CONSUMPTION_ENTRY_APHIS_MISSING_GENETICALLY_ENGINEERED_ORGANISMS_INFO = 'US_CONSUMPTION_ENTRY_APHIS_MISSING_GENETICALLY_ENGINEERED_ORGANISMS_INFO',
  US_CONSUMPTION_ENTRY_APHIS_MISSING_LIVE_ANIMALS_INFO = 'US_CONSUMPTION_ENTRY_APHIS_MISSING_LIVE_ANIMALS_INFO',
  // ### UsConsumptionEntry - PGA Documentation (TTB)
  US_CONSUMPTION_ENTRY_TTB_MISSING_BEER_INFO = 'US_CONSUMPTION_ENTRY_TTB_MISSING_BEER_INFO',
  US_CONSUMPTION_ENTRY_TTB_MISSING_WINE_INFO = 'US_CONSUMPTION_ENTRY_TTB_MISSING_WINE_INFO',
  US_CONSUMPTION_ENTRY_TTB_MISSING_DISTILLED_SPIRITS_INFO = 'US_CONSUMPTION_ENTRY_TTB_MISSING_DISTILLED_SPIRITS_INFO',
  US_CONSUMPTION_ENTRY_TTB_MISSING_TOBACCO_INFO = 'US_CONSUMPTION_ENTRY_TTB_MISSING_TOBACCO_INFO',
  // ### UsConsumptionEntry - PGA Documentation (Other PGA)
  US_CONSUMPTION_ENTRY_OTHER_PGA_OTHER_DATA_MISSING = 'US_CONSUMPTION_ENTRY_OTHER_PGA_OTHER_DATA_MISSING',
  // ## UsConsumptionEntry - Product Data
  US_CONSUMPTION_ENTRY_NEED_PRODUCT_DESCRIPTION = 'US_CONSUMPTION_ENTRY_NEED_PRODUCT_DESCRIPTION',
  US_CONSUMPTION_ENTRY_NEED_MATERIAL_COMPOSITION_OF_ITEM = 'US_CONSUMPTION_ENTRY_NEED_MATERIAL_COMPOSITION_OF_ITEM',
  US_CONSUMPTION_ENTRY_NEED_INTENDED_USE_OF_PRODUCT = 'US_CONSUMPTION_ENTRY_NEED_INTENDED_USE_OF_PRODUCT',
  US_CONSUMPTION_ENTRY_MULTIPLE_DETAILS_REQUIRED_FOR_CLASSIFICATION = 'US_CONSUMPTION_ENTRY_MULTIPLE_DETAILS_REQUIRED_FOR_CLASSIFICATION',
  US_CONSUMPTION_ENTRY_AD_CVD_INQUIRY = 'US_CONSUMPTION_ENTRY_AD_CVD_INQUIRY',
  US_CONSUMPTION_ENTRY_MISSING_AD_CVD_NON_REIMBURSEMENT_CERTIFICATE = 'US_CONSUMPTION_ENTRY_MISSING_AD_CVD_NON_REIMBURSEMENT_CERTIFICATE',
  US_CONSUMPTION_ENTRY_PRODUCT_DATA_OTHER_DATA_MISSING = 'US_CONSUMPTION_ENTRY_PRODUCT_DATA_OTHER_DATA_MISSING',
  // ## UsConsumptionEntry - Transportation Info
  US_CONSUMPTION_ENTRY_MISSING_MASTER_BILL = 'US_CONSUMPTION_ENTRY_MISSING_MASTER_BILL',
  US_CONSUMPTION_ENTRY_MISSING_HOUSE_BILL = 'US_CONSUMPTION_ENTRY_MISSING_HOUSE_BILL',
  US_CONSUMPTION_ENTRY_NO_BILL_MATCH = 'US_CONSUMPTION_ENTRY_NO_BILL_MATCH',
  US_CONSUMPTION_ENTRY_MISSING_CONTAINER_INFO = 'US_CONSUMPTION_ENTRY_MISSING_CONTAINER_INFO',
  US_CONSUMPTION_ENTRY_MISSING_GROSS_WEIGHT = 'US_CONSUMPTION_ENTRY_MISSING_GROSS_WEIGHT',
  US_CONSUMPTION_ENTRY_MISSING_IT_INFO = 'US_CONSUMPTION_ENTRY_MISSING_IT_INFO',
  US_CONSUMPTION_ENTRY_MISSING_PORT_OF_UNLADING_ENTRY_INFO = 'US_CONSUMPTION_ENTRY_MISSING_PORT_OF_UNLADING_ENTRY_INFO',
  US_CONSUMPTION_ENTRY_MISSING_FIRMS_CODE = 'US_CONSUMPTION_ENTRY_MISSING_FIRMS_CODE',
  US_CONSUMPTION_ENTRY_MANIFESTED_QUANTITY_DISCREPANCY = 'US_CONSUMPTION_ENTRY_MANIFESTED_QUANTITY_DISCREPANCY',
  US_CONSUMPTION_ENTRY_NOT_DEPARTED_ORIGIN = 'US_CONSUMPTION_ENTRY_NOT_DEPARTED_ORIGIN',
  US_CONSUMPTION_ENTRY_TRANSPORTATION_INFO_OTHER_DATA_MISSING = 'US_CONSUMPTION_ENTRY_TRANSPORTATION_INFO_OTHER_DATA_MISSING',
  // ## UsConsumptionEntry - Other
  US_CONSUMPTION_ENTRY_BOND_ISSUE = 'US_CONSUMPTION_ENTRY_BOND_ISSUE',
  US_CONSUMPTION_ENTRY_IOR_SANCTIONED = 'US_CONSUMPTION_ENTRY_IOR_SANCTIONED',
  US_CONSUMPTION_ENTRY_PRE_ALERT_DOCUMENTS_NOT_RECEIVED = 'US_CONSUMPTION_ENTRY_PRE_ALERT_DOCUMENTS_NOT_RECEIVED',
  US_CONSUMPTION_ENTRY_PRE_ALERT_DOCUMENTS_DELAYED = 'US_CONSUMPTION_ENTRY_PRE_ALERT_DOCUMENTS_DELAYED',
  US_CONSUMPTION_ENTRY_MANUAL_HOLD_SEE_MEMO = 'US_CONSUMPTION_ENTRY_MANUAL_HOLD_SEE_MEMO',
  US_CONSUMPTION_ENTRY_OTHER_OTHER_DATA_MISSING = 'US_CONSUMPTION_ENTRY_OTHER_OTHER_DATA_MISSING',
  // ## UsConsumptionEntry - Tech Fix
  US_CONSUMPTION_ENTRY_PENDING_BUG_FIX = 'US_CONSUMPTION_ENTRY_PENDING_BUG_FIX',
  US_CONSUMPTION_ENTRY_PENDING_DATA_FIX = 'US_CONSUMPTION_ENTRY_PENDING_DATA_FIX',
  // ## UsConsumptionEntry - Entry Summary Rejections
  US_CONSUMPTION_ENTRY_PGA_DATA_MISSING_PER_PGA_FLAG = 'US_CONSUMPTION_ENTRY_PGA_DATA_MISSING_PER_PGA_FLAG',
  US_CONSUMPTION_ENTRY_TRFF_ADJUSTMENT_HTS_OR_EXCLSN_MISSING = 'US_CONSUMPTION_ENTRY_TRFF_ADJUSTMENT_HTS_OR_EXCLSN_MISSING',
  US_CONSUMPTION_ENTRY_SUMMARY_DELETED_OR_REPLACED = 'US_CONSUMPTION_ENTRY_SUMMARY_DELETED_OR_REPLACED',
  US_CONSUMPTION_ENTRY_CONTINUOUS_BOND_TERMINATED = 'US_CONSUMPTION_ENTRY_CONTINUOUS_BOND_TERMINATED',
  US_CONSUMPTION_ENTRY_MFGR_CODE_UNKNOWN = 'US_CONSUMPTION_ENTRY_MFGR_CODE_UNKNOWN',
  US_CONSUMPTION_ENTRY_EST_ENTRY_DUTY_GREATER_THAN_STMT_DUTY = 'US_CONSUMPTION_ENTRY_EST_ENTRY_DUTY_GREATER_THAN_STMT_DUTY',
  US_CONSUMPTION_ENTRY_TRFF_ADJUSTMENT_HTS_NOT_ALLOWED = 'US_CONSUMPTION_ENTRY_TRFF_ADJUSTMENT_HTS_NOT_ALLOWED',
  US_CONSUMPTION_ENTRY_MSNG_OR_INVLD_SCIENT_SPECIES = 'US_CONSUMPTION_ENTRY_MSNG_OR_INVLD_SCIENT_SPECIES',
  US_CONSUMPTION_ENTRY_CARRIER_NOT_A_KNOWN_IATA_CODE = 'US_CONSUMPTION_ENTRY_CARRIER_NOT_A_KNOWN_IATA_CODE',
  US_CONSUMPTION_ENTRY_REPORTED_SURETY_OR_BOND_SURETY_MISMATCH = 'US_CONSUMPTION_ENTRY_REPORTED_SURETY_OR_BOND_SURETY_MISMATCH',
  US_CONSUMPTION_ENTRY_ESV_FAILURE = 'US_CONSUMPTION_ENTRY_ESV_FAILURE',
  US_CONSUMPTION_ENTRY_FOREIGN_EXPORTER_UNKNOWN = 'US_CONSUMPTION_ENTRY_FOREIGN_EXPORTER_UNKNOWN',
  US_CONSUMPTION_ENTRY_INVALID_CATEGORY_CODE = 'US_CONSUMPTION_ENTRY_INVALID_CATEGORY_CODE',
  US_CONSUMPTION_ENTRY_MSNG_OR_INVLD_CONST_ELEMENT_QTY_PER_PGA = 'US_CONSUMPTION_ENTRY_MSNG_OR_INVLD_CONST_ELEMENT_QTY_PER_PGA',
  US_CONSUMPTION_ENTRY_OTHER_ADD_COMMENT = 'US_CONSUMPTION_ENTRY_OTHER_ADD_COMMENT',
  // ## UsConsumptionEntry - Pga Rejections
  US_CONSUMPTION_ENTRY_CANCELLED_FACILITY_REGISTRATION = 'US_CONSUMPTION_ENTRY_CANCELLED_FACILITY_REGISTRATION',
  US_CONSUMPTION_ENTRY_INVALID_DII = 'US_CONSUMPTION_ENTRY_INVALID_DII',
  US_CONSUMPTION_ENTRY_INVALID_PNC_NUMBER = 'US_CONSUMPTION_ENTRY_INVALID_PNC_NUMBER',
  US_CONSUMPTION_ENTRY_INVALID_PRODUCT_CODE = 'US_CONSUMPTION_ENTRY_INVALID_PRODUCT_CODE',
  US_CONSUMPTION_ENTRY_MISMATCH_IN_REGISTRATION = 'US_CONSUMPTION_ENTRY_MISMATCH_IN_REGISTRATION',
  US_CONSUMPTION_ENTRY_REGISTRATION_NOT_ON_FILE = 'US_CONSUMPTION_ENTRY_REGISTRATION_NOT_ON_FILE',
  US_CONSUMPTION_ENTRY_INVALID_COUNTRY_OF_PRODUCTION = 'US_CONSUMPTION_ENTRY_INVALID_COUNTRY_OF_PRODUCTION',
  // ##UsConsumptionEntry - CBP Hold and Exam
  US_CONSUMPTION_ENTRY_FDA_EXAM = 'US_CONSUMPTION_ENTRY_FDA_EXAM',
  US_CONSUMPTION_ENTRY_EPA_EXAM = 'US_CONSUMPTION_ENTRY_EPA_EXAM',
  US_CONSUMPTION_ENTRY_CPSC_EXAM = 'US_CONSUMPTION_ENTRY_CPSC_EXAM',
  US_CONSUMPTION_ENTRY_CPSC_HOLD = 'US_CONSUMPTION_ENTRY_CPSC_HOLD',
  US_CONSUMPTION_ENTRY_LINE_HOLD = 'US_CONSUMPTION_ENTRY_LINE_HOLD',
  US_CONSUMPTION_ENTRY_FDA_LINE_HOLD = 'US_CONSUMPTION_ENTRY_FDA_LINE_HOLD',
  US_CONSUMPTION_ENTRY_BOL_MISMATCH = 'US_CONSUMPTION_ENTRY_BOL_MISMATCH',
  US_CONSUMPTION_ENTRY_PIECE_COUNT_DISCREPANCY = 'US_CONSUMPTION_ENTRY_PIECE_COUNT_DISCREPANCY',
  US_CONSUMPTION_ENTRY_CBMS_SYSTEM_ISSUE = 'US_CONSUMPTION_ENTRY_CBMS_SYSTEM_ISSUE',
  // ##UsConsumptionEntry - Payment Processing
  US_CONSUMPTION_ENTRY_PAYMENT_PENDING_FINANCE_REVIEW = 'US_CONSUMPTION_ENTRY_PAYMENT_PENDING_FINANCE_REVIEW',
  US_CONSUMPTION_ENTRY_PAYMENT_PENDING_CBP_REVIEW = 'US_CONSUMPTION_ENTRY_PAYMENT_PENDING_CBP_REVIEW',
}

export enum UsType86EntryInquiryReasonCode {
  // # UsType86Entry
  // ## UsType86Entry - Commercial Invoice & Packing List Data
  US_TYPE86_ENTRY_INVOICE_IS_NOT_IN_ENGLISH = 'US_TYPE86_ENTRY_INVOICE_IS_NOT_IN_ENGLISH',
  US_TYPE86_ENTRY_MISSING_PRODUCT_CLASSIFICATIONS = 'US_TYPE86_ENTRY_MISSING_PRODUCT_CLASSIFICATIONS',
  US_TYPE86_ENTRY_MISSING_COUNTRY_OF_ORIGIN_DATA = 'US_TYPE86_ENTRY_MISSING_COUNTRY_OF_ORIGIN_DATA',
  US_TYPE86_ENTRY_FULL_NAME_AND_ADDRESS_OF_SELLING_PARTY_NOT_LISTED = 'US_TYPE86_ENTRY_FULL_NAME_AND_ADDRESS_OF_SELLING_PARTY_NOT_LISTED',
  US_TYPE86_ENTRY_FULL_NAME_AND_ADDRESS_OF_MANUFACTURER_NOT_LISTED = 'US_TYPE86_ENTRY_FULL_NAME_AND_ADDRESS_OF_MANUFACTURER_NOT_LISTED',
  US_TYPE86_ENTRY_MISSING_CURRENCY = 'US_TYPE86_ENTRY_MISSING_CURRENCY',
  US_TYPE86_ENTRY_MISSING_LINE_QUANTITY = 'US_TYPE86_ENTRY_MISSING_LINE_QUANTITY',
  US_TYPE86_ENTRY_MISSING_UNIT_COST = 'US_TYPE86_ENTRY_MISSING_UNIT_COST',
  US_TYPE86_ENTRY_MISSING_EXTENDED_COST = 'US_TYPE86_ENTRY_MISSING_EXTENDED_COST',
  US_TYPE86_ENTRY_MISSING_REQUIRED_NET_WEIGHT = 'US_TYPE86_ENTRY_MISSING_REQUIRED_NET_WEIGHT',
  US_TYPE86_ENTRY_MISSING_HTS_REPORTING_QUANTITY = 'US_TYPE86_ENTRY_MISSING_HTS_REPORTING_QUANTITY',
  US_TYPE86_ENTRY_CARTON_COUNT_DOES_NOT_MATCH_PIECE_COUNT = 'US_TYPE86_ENTRY_CARTON_COUNT_DOES_NOT_MATCH_PIECE_COUNT',
  US_TYPE86_ENTRY_INCORRECT_HTS_CODE = 'US_TYPE86_ENTRY_INCORRECT_HTS_CODE',
  US_TYPE86_ENTRY_PENDING_CONSIGNEE_EIN_NUMBER = 'US_TYPE86_ENTRY_PENDING_CONSIGNEE_EIN_NUMBER',
  US_TYPE86_ENTRY_COMMERCIAL_INVOICES_AND_PACKING_LIST_DATA_OTHER_DATA_MISSING = 'US_TYPE86_ENTRY_COMMERCIAL_INVOICES_AND_PACKING_LIST_DATA_OTHER_DATA_MISSING',
  // ## UsType86Entry - PGA Documentation
  // ### UsType86Entry - PGA Documentation (FDA)
  US_TYPE86_ENTRY_FDA_MISSING_MEDICAL_DEVICE_INFO = 'US_TYPE86_ENTRY_FDA_MISSING_MEDICAL_DEVICE_INFO',
  US_TYPE86_ENTRY_FDA_MISSING_DRUG_INFO = 'US_TYPE86_ENTRY_FDA_MISSING_DRUG_INFO',
  US_TYPE86_ENTRY_FDA_MISSING_RADIATION_EMITTING_DEVICE_INFO = 'US_TYPE86_ENTRY_FDA_MISSING_RADIATION_EMITTING_DEVICE_INFO',
  US_TYPE86_ENTRY_FDA_MISSING_FOODS_INFO = 'US_TYPE86_ENTRY_FDA_MISSING_FOODS_INFO',
  US_TYPE86_ENTRY_FDA_MISSING_COSMETICS_INFO = 'US_TYPE86_ENTRY_FDA_MISSING_COSMETICS_INFO',
  US_TYPE86_ENTRY_FDA_MISSING_TOBACCO_INFO = 'US_TYPE86_ENTRY_FDA_MISSING_TOBACCO_INFO',
  US_TYPE86_ENTRY_FDA_MISSING_BIOLOGICS_INFO = 'US_TYPE86_ENTRY_FDA_MISSING_BIOLOGICS_INFO',
  US_TYPE86_ENTRY_FDA_MISSING_ANIMAL_DRUGS_AND_DEVICES_INFO = 'US_TYPE86_ENTRY_FDA_MISSING_ANIMAL_DRUGS_AND_DEVICES_INFO',
  // ### UsType86Entry - PGA Documentation (EPA)
  US_TYPE86_ENTRY_EPA_MISSING_TSCA_INFO = 'US_TYPE86_ENTRY_EPA_MISSING_TSCA_INFO',
  US_TYPE86_ENTRY_EPA_MISSING_PESTICIDES_INFO = 'US_TYPE86_ENTRY_EPA_MISSING_PESTICIDES_INFO',
  US_TYPE86_ENTRY_EPA_MISSING_VEHICLES_AND_ENGINES_INFO = 'US_TYPE86_ENTRY_EPA_MISSING_VEHICLES_AND_ENGINES_INFO',
  // ### UsType86Entry - PGA Documentation (NHTSA)
  US_TYPE86_ENTRY_NHTSA_MISSING_HS7 = 'US_TYPE86_ENTRY_NHTSA_MISSING_HS7',
  // ### UsType86Entry - PGA Documentation (FWS)
  US_TYPE86_ENTRY_FWS_MISSING_FWS_DOCUMENTATION = 'US_TYPE86_ENTRY_FWS_MISSING_FWS_DOCUMENTATION',
  // ### UsType86Entry - PGA Documentation (APHIS)
  US_TYPE86_ENTRY_APHIS_MISSING_LACEY_ACT_INFO = 'US_TYPE86_ENTRY_APHIS_MISSING_LACEY_ACT_INFO',
  US_TYPE86_ENTRY_APHIS_MISSING_APHIS_CORE_INFO = 'US_TYPE86_ENTRY_APHIS_MISSING_APHIS_CORE_INFO',
  US_TYPE86_ENTRY_APHIS_MISSING_MISCELLANEOUS_AND_PROCESSED_PRODUCTS_INFO = 'US_TYPE86_ENTRY_APHIS_MISSING_MISCELLANEOUS_AND_PROCESSED_PRODUCTS_INFO',
  US_TYPE86_ENTRY_APHIS_MISSING_ANIMAL_PRODUCTS_AND_BYPRODUCTS_INFO = 'US_TYPE86_ENTRY_APHIS_MISSING_ANIMAL_PRODUCTS_AND_BYPRODUCTS_INFO',
  US_TYPE86_ENTRY_APHIS_MISSING_RELATED_ANIMAL_PRODUCTS_INFO = 'US_TYPE86_ENTRY_APHIS_MISSING_RELATED_ANIMAL_PRODUCTS_INFO',
  US_TYPE86_ENTRY_APHIS_MISSING_SEEDS_NOT_FOR_PLANTING_INFO = 'US_TYPE86_ENTRY_APHIS_MISSING_SEEDS_NOT_FOR_PLANTING_INFO',
  US_TYPE86_ENTRY_APHIS_MISSING_CUT_FLOWERS_INFO = 'US_TYPE86_ENTRY_APHIS_MISSING_CUT_FLOWERS_INFO',
  US_TYPE86_ENTRY_APHIS_MISSING_PROPAGATIVE_MATERIAL_INFO = 'US_TYPE86_ENTRY_APHIS_MISSING_PROPAGATIVE_MATERIAL_INFO',
  US_TYPE86_ENTRY_APHIS_MISSING_FRUITS_AND_VEGETABLES_INFO = 'US_TYPE86_ENTRY_APHIS_MISSING_FRUITS_AND_VEGETABLES_INFO',
  US_TYPE86_ENTRY_APHIS_MISSING_GENETICALLY_ENGINEERED_ORGANISMS_INFO = 'US_TYPE86_ENTRY_APHIS_MISSING_GENETICALLY_ENGINEERED_ORGANISMS_INFO',
  US_TYPE86_ENTRY_APHIS_MISSING_LIVE_ANIMALS_INFO = 'US_TYPE86_ENTRY_APHIS_MISSING_LIVE_ANIMALS_INFO',
  // ### UsType86Entry - PGA Documentation (TTB)
  US_TYPE86_ENTRY_TTB_MISSING_BEER_INFO = 'US_TYPE86_ENTRY_TTB_MISSING_BEER_INFO',
  US_TYPE86_ENTRY_TTB_MISSING_WINE_INFO = 'US_TYPE86_ENTRY_TTB_MISSING_WINE_INFO',
  US_TYPE86_ENTRY_TTB_MISSING_DISTILLED_SPIRITS_INFO = 'US_TYPE86_ENTRY_TTB_MISSING_DISTILLED_SPIRITS_INFO',
  US_TYPE86_ENTRY_TTB_MISSING_TOBACCO_INFO = 'US_TYPE86_ENTRY_TTB_MISSING_TOBACCO_INFO',
  // ### UsType86Entry - PGA Documentation (Other PGA)
  US_TYPE86_ENTRY_OTHER_PGA_OTHER_DATA_MISSING = 'US_TYPE86_ENTRY_OTHER_PGA_OTHER_DATA_MISSING',
  // ## UsType86Entry - Product Data
  US_TYPE86_ENTRY_NEED_PRODUCT_DESCRIPTION = 'US_TYPE86_ENTRY_NEED_PRODUCT_DESCRIPTION',
  US_TYPE86_ENTRY_NEED_MATERIAL_COMPOSITION_OF_ITEM = 'US_TYPE86_ENTRY_NEED_MATERIAL_COMPOSITION_OF_ITEM',
  US_TYPE86_ENTRY_NEED_INTENDED_USE_OF_PRODUCT = 'US_TYPE86_ENTRY_NEED_INTENDED_USE_OF_PRODUCT',
  US_TYPE86_ENTRY_MULTIPLE_DETAILS_REQUIRED_FOR_CLASSIFICATION = 'US_TYPE86_ENTRY_MULTIPLE_DETAILS_REQUIRED_FOR_CLASSIFICATION',
  US_TYPE86_ENTRY_PRODUCT_DATA_OTHER_DATA_MISSING = 'US_TYPE86_ENTRY_PRODUCT_DATA_OTHER_DATA_MISSING',
  // ## UsType86Entry - Transportation Info
  US_TYPE86_ENTRY_MISSING_MASTER_BILL = 'US_TYPE86_ENTRY_MISSING_MASTER_BILL',
  US_TYPE86_ENTRY_MISSING_HOUSE_BILL = 'US_TYPE86_ENTRY_MISSING_HOUSE_BILL',
  US_TYPE86_ENTRY_NO_BILL_MATCH = 'US_TYPE86_ENTRY_NO_BILL_MATCH',
  US_TYPE86_ENTRY_MISSING_CONTAINER_INFO = 'US_TYPE86_ENTRY_MISSING_CONTAINER_INFO',
  US_TYPE86_ENTRY_MISSING_GROSS_WEIGHT = 'US_TYPE86_ENTRY_MISSING_GROSS_WEIGHT',
  US_TYPE86_ENTRY_MISSING_IT_INFO = 'US_TYPE86_ENTRY_MISSING_IT_INFO',
  US_TYPE86_ENTRY_MISSING_PORT_OF_UNLADING_ENTRY_INFO = 'US_TYPE86_ENTRY_MISSING_PORT_OF_UNLADING_ENTRY_INFO',
  US_TYPE86_ENTRY_MISSING_FIRMS_CODE = 'US_TYPE86_ENTRY_MISSING_FIRMS_CODE',
  US_TYPE86_ENTRY_MANIFESTED_QUANTITY_DISCREPANCY = 'US_TYPE86_ENTRY_MANIFESTED_QUANTITY_DISCREPANCY',
  US_TYPE86_ENTRY_NOT_DEPARTED_ORIGIN = 'US_TYPE86_ENTRY_NOT_DEPARTED_ORIGIN',
  US_TYPE86_ENTRY_TRANSPORTATION_INFO_OTHER_DATA_MISSING = 'US_TYPE86_ENTRY_TRANSPORTATION_INFO_OTHER_DATA_MISSING',
  // ## UsType86Entry - Other
  US_TYPE86_ENTRY_BOND_ISSUE = 'US_TYPE86_ENTRY_BOND_ISSUE',
  US_TYPE86_ENTRY_IOR_SANCTIONED = 'US_TYPE86_ENTRY_IOR_SANCTIONED',
  US_TYPE86_ENTRY_PRE_ALERT_DOCUMENTS_NOT_RECEIVED = 'US_TYPE86_ENTRY_PRE_ALERT_DOCUMENTS_NOT_RECEIVED',
  US_TYPE86_ENTRY_PRE_ALERT_DOCUMENTS_DELAYED = 'US_TYPE86_ENTRY_PRE_ALERT_DOCUMENTS_DELAYED',
  US_TYPE86_ENTRY_MANUAL_HOLD_SEE_MEMO = 'US_TYPE86_ENTRY_MANUAL_HOLD_SEE_MEMO',
  US_TYPE86_ENTRY_OTHER_OTHER_DATA_MISSING = 'US_TYPE86_ENTRY_OTHER_OTHER_DATA_MISSING',
  // ## UsType86Entry - Tech Fix
  US_TYPE86_ENTRY_PENDING_BUG_FIX = 'US_TYPE86_ENTRY_PENDING_BUG_FIX',
  US_TYPE86_ENTRY_PENDING_DATA_FIX = 'US_TYPE86_ENTRY_PENDING_DATA_FIX',
  // ## UsType86Entry - Entry Cargo Release Rejections
  US_TYPE86_ENTRY_PGA_DATA_MISSING_PER_PGA_FLAG = 'US_TYPE86_ENTRY_PGA_DATA_MISSING_PER_PGA_FLAG',
  US_TYPE86_ENTRY_TRFF_ADJUSTMENT_HTS_OR_EXCLSN_MISSING = 'US_TYPE86_ENTRY_TRFF_ADJUSTMENT_HTS_OR_EXCLSN_MISSING',
  US_TYPE86_ENTRY_CARGO_RELEASE_DELETED_OR_REPLACED = 'US_TYPE86_ENTRY_CARGO_RELEASE_DELETED_OR_REPLACED',
  US_TYPE86_ENTRY_CONTINUOUS_BOND_TERMINATED = 'US_TYPE86_ENTRY_CONTINUOUS_BOND_TERMINATED',
  US_TYPE86_ENTRY_MFGR_CODE_UNKNOWN = 'US_TYPE86_ENTRY_MFGR_CODE_UNKNOWN',
  US_TYPE86_ENTRY_EST_ENTRY_DUTY_GREATER_THAN_STMT_DUTY = 'US_TYPE86_ENTRY_EST_ENTRY_DUTY_GREATER_THAN_STMT_DUTY',
  US_TYPE86_ENTRY_TRFF_ADJUSTMENT_HTS_NOT_ALLOWED = 'US_TYPE86_ENTRY_TRFF_ADJUSTMENT_HTS_NOT_ALLOWED',
  US_TYPE86_ENTRY_MSNG_OR_INVLD_SCIENT_SPECIES = 'US_TYPE86_ENTRY_MSNG_OR_INVLD_SCIENT_SPECIES',
  US_TYPE86_ENTRY_CARRIER_NOT_A_KNOWN_IATA_CODE = 'US_TYPE86_ENTRY_CARRIER_NOT_A_KNOWN_IATA_CODE',
  US_TYPE86_ENTRY_REPORTED_SURETY_OR_BOND_SURETY_MISMATCH = 'US_TYPE86_ENTRY_REPORTED_SURETY_OR_BOND_SURETY_MISMATCH',
  US_TYPE86_ENTRY_ESV_FAILURE = 'US_TYPE86_ENTRY_ESV_FAILURE',
  US_TYPE86_ENTRY_FOREIGN_EXPORTER_UNKNOWN = 'US_TYPE86_ENTRY_FOREIGN_EXPORTER_UNKNOWN',
  US_TYPE86_ENTRY_INVALID_CATEGORY_CODE = 'US_TYPE86_ENTRY_INVALID_CATEGORY_CODE',
  US_TYPE86_ENTRY_MSNG_OR_INVLD_CONST_ELEMENT_QTY_PER_PGA = 'US_TYPE86_ENTRY_MSNG_OR_INVLD_CONST_ELEMENT_QTY_PER_PGA',
  US_TYPE86_ENTRY_OTHER_ADD_COMMENT = 'US_TYPE86_ENTRY_OTHER_ADD_COMMENT',
  // ## UsType86Entry - Pga Rejections
  US_TYPE86_ENTRY_CANCELLED_FACILITY_REGISTRATION = 'US_TYPE86_ENTRY_CANCELLED_FACILITY_REGISTRATION',
  US_TYPE86_ENTRY_INVALID_DII = 'US_TYPE86_ENTRY_INVALID_DII',
  US_TYPE86_ENTRY_INVALID_PNC_NUMBER = 'US_TYPE86_ENTRY_INVALID_PNC_NUMBER',
  US_TYPE86_ENTRY_INVALID_PRODUCT_CODE = 'US_TYPE86_ENTRY_INVALID_PRODUCT_CODE',
  US_TYPE86_ENTRY_MISMATCH_IN_REGISTRATION = 'US_TYPE86_ENTRY_MISMATCH_IN_REGISTRATION',
  US_TYPE86_ENTRY_REGISTRATION_NOT_ON_FILE = 'US_TYPE86_ENTRY_REGISTRATION_NOT_ON_FILE',
  US_TYPE86_ENTRY_INVALID_COUNTRY_OF_PRODUCTION = 'US_TYPE86_ENTRY_INVALID_COUNTRY_OF_PRODUCTION',
  // ##UsType86Entry - CBP Hold and Exam
  US_TYPE86_ENTRY_FDA_EXAM = 'US_TYPE86_ENTRY_FDA_EXAM',
  US_TYPE86_ENTRY_EPA_EXAM = 'US_TYPE86_ENTRY_EPA_EXAM',
  US_TYPE86_ENTRY_CPSC_EXAM = 'US_TYPE86_ENTRY_CPSC_EXAM',
  US_TYPE86_ENTRY_CPSC_HOLD = 'US_TYPE86_ENTRY_CPSC_HOLD',
  US_TYPE86_ENTRY_LINE_HOLD = 'US_TYPE86_ENTRY_LINE_HOLD',
  US_TYPE86_ENTRY_FDA_LINE_HOLD = 'US_TYPE86_ENTRY_FDA_LINE_HOLD',
  US_TYPE86_ENTRY_BOL_MISMATCH = 'US_TYPE86_ENTRY_BOL_MISMATCH',
  US_TYPE86_ENTRY_PIECE_COUNT_DISCREPANCY = 'US_TYPE86_ENTRY_PIECE_COUNT_DISCREPANCY',
  US_TYPE86_ENTRY_CBMS_SYSTEM_ISSUE = 'US_TYPE86_ENTRY_CBMS_SYSTEM_ISSUE',
  // ##UsType86Entry - Payment Processing
  US_TYPE86_ENTRY_PAYMENT_PENDING_FINANCE_REVIEW = 'US_TYPE86_ENTRY_PAYMENT_PENDING_FINANCE_REVIEW',
  US_TYPE86_ENTRY_PAYMENT_PENDING_CBP_REVIEW = 'US_TYPE86_ENTRY_PAYMENT_PENDING_CBP_REVIEW',
}

export enum UsIorActivationInquiryReasonCode {
  // # UsIorActivation
  // ## UsIorActivation - Missing Documentation
  US_IOR_ACTIVATION_MISSING_MASTER_POWER_OF_ATTORNEY = 'US_IOR_ACTIVATION_MISSING_MASTER_POWER_OF_ATTORNEY',
  US_IOR_ACTIVATION_MISSING_SUB_POWER_OF_ATTORNEY = 'US_IOR_ACTIVATION_MISSING_SUB_POWER_OF_ATTORNEY',
  US_IOR_ACTIVATION_MISSING_DIRECT_POWER_OF_ATTORNEY = 'US_IOR_ACTIVATION_MISSING_DIRECT_POWER_OF_ATTORNEY',
  US_IOR_ACTIVATION_MISSING_CORPORATE_CERTIFICATION = 'US_IOR_ACTIVATION_MISSING_CORPORATE_CERTIFICATION',
  US_IOR_ACTIVATION_MISSING_OFFICER_VERIFICATION = 'US_IOR_ACTIVATION_MISSING_OFFICER_VERIFICATION',
  US_IOR_ACTIVATION_MISSING_BUSINESS_REGISTRATION = 'US_IOR_ACTIVATION_MISSING_BUSINESS_REGISTRATION',
  US_IOR_ACTIVATION_MISSING_SOI_SCREENING = 'US_IOR_ACTIVATION_MISSING_SOI_SCREENING',
  US_IOR_ACTIVATION_MISSING_CONTINUOUS_BOND = 'US_IOR_ACTIVATION_MISSING_CONTINUOUS_BOND',
  US_IOR_ACTIVATION_MISSING_PMS_APPLICATION = 'US_IOR_ACTIVATION_MISSING_PMS_APPLICATION',
  US_IOR_ACTIVATION_OTHER_DATA_MISSING = 'US_IOR_ACTIVATION_OTHER_DATA_MISSING',
  // ## UsIorActivation - Reject Documentation
  US_IOR_ACTIVATION_REJECT_MASTER_POWER_OF_ATTORNEY = 'US_IOR_ACTIVATION_REJECT_MASTER_POWER_OF_ATTORNEY',
  US_IOR_ACTIVATION_REJECT_SUB_POWER_OF_ATTORNEY = 'US_IOR_ACTIVATION_REJECT_SUB_POWER_OF_ATTORNEY',
  US_IOR_ACTIVATION_REJECT_DIRECT_POWER_OF_ATTORNEY = 'US_IOR_ACTIVATION_REJECT_DIRECT_POWER_OF_ATTORNEY',
  US_IOR_ACTIVATION_REJECT_CORPORATE_CERTIFICATION = 'US_IOR_ACTIVATION_REJECT_CORPORATE_CERTIFICATION',
}

export enum UsInBondInquiryReasonCode {
  // # UsInBond
  // ## UsInBond - Commercial Invoice & Packing List Data
  US_IN_BOND_MISSING_CONSIGNEE = 'US_IN_BOND_MISSING_CONSIGNEE',
  US_IN_BOND_MISSING_TOTAL_VALUE = 'US_IN_BOND_MISSING_TOTAL_VALUE',
  US_IN_BOND_MISSING_CURRENCY = 'US_IN_BOND_MISSING_CURRENCY',
  US_IN_BOND_CARTON_COUNT_DOES_NOT_MATCH_PIECE_COUNT = 'US_IN_BOND_CARTON_COUNT_DOES_NOT_MATCH_PIECE_COUNT',
  US_IN_BOND_COMMERCIAL_INVOICE_AND_PACKING_LIST_DATA_OTHER_DATA_MISSING = 'US_IN_BOND_COMMERCIAL_INVOICE_AND_PACKING_LIST_DATA_OTHER_DATA_MISSING',
  // ## UsInBond - Transportation Info
  US_IN_BOND_MISSING_BONDED_CARRIER_CODE = 'US_IN_BOND_MISSING_BONDED_CARRIER_CODE',
  US_IN_BOND_MISSING_BONDED_CARRIER_TAX_ID = 'US_IN_BOND_MISSING_BONDED_CARRIER_TAX_ID',
  US_IN_BOND_MISSING_PORT_OF_UNLADING_ENTRY_INFO = 'US_IN_BOND_MISSING_PORT_OF_UNLADING_ENTRY_INFO',
  US_IN_BOND_MISSING_PREVIOUS_IN_BOND_NUMBER = 'US_IN_BOND_MISSING_PREVIOUS_IN_BOND_NUMBER',
  US_IN_BOND_MISSING_PREVIOUS_IN_BOND_ORIGIN_PORT = 'US_IN_BOND_MISSING_PREVIOUS_IN_BOND_ORIGIN_PORT',
  US_IN_BOND_MISSING_PREVIOUS_IN_BOND_DATE = 'US_IN_BOND_MISSING_PREVIOUS_IN_BOND_DATE',
  US_IN_BOND_MISSING_FIRMS_CODE = 'US_IN_BOND_MISSING_FIRMS_CODE',
  US_IN_BOND_MISSING_MASTER_BILL = 'US_IN_BOND_MISSING_MASTER_BILL',
  US_IN_BOND_MISSING_HOUSE_BILL = 'US_IN_BOND_MISSING_HOUSE_BILL',
  US_IN_BOND_MISSING_CONTAINER_INFO = 'US_IN_BOND_MISSING_CONTAINER_INFO',
  US_IN_BOND_MANIFESTED_QUANTITY_DISCREPANCY = 'US_IN_BOND_MANIFESTED_QUANTITY_DISCREPANCY',
  US_IN_BOND_TRANSPORTATION_INFO_OTHER_DATA_MISSING = 'US_IN_BOND_TRANSPORTATION_INFO_OTHER_DATA_MISSING',
}

// The reason codes and category are same for the three countries. The prefix is removed on UI, so the reason codes will not have EU shown. https://quip-amazon.com/l9CnA3oeQjyx/EUUK-Inquire-Reasons
export enum EuCustomsEntryInquiryReasonCode {
  // Commercial Invoice
  EU_CUSTOMS_ENTRY_COMMERCIAL_INVOICES_MISSING_DATA = 'EU_CUSTOMS_ENTRY_COMMERCIAL_INVOICES_MISSING_DATA',
  EU_CUSTOMS_ENTRY_COMMERCIAL_INVOICES_INCORRECT_VALUE = 'EU_CUSTOMS_ENTRY_COMMERCIAL_INVOICES_INCORRECT_VALUE',
  EU_CUSTOMS_ENTRY_COMMERCIAL_INVOICES_INCORRECT_CLASSIFICATION = 'EU_CUSTOMS_ENTRY_COMMERCIAL_INVOICES_INCORRECT_CLASSIFICATION',
  EU_CUSTOMS_ENTRY_COMMERCIAL_INVOICES_INCORRECT_INCOTERMS = 'EU_CUSTOMS_ENTRY_COMMERCIAL_INVOICES_INCORRECT_INCOTERMS',
  EU_CUSTOMS_ENTRY_COMMERCIAL_INVOICES_INCORRECT_EORI = 'EU_CUSTOMS_ENTRY_COMMERCIAL_INVOICES_INCORRECT_EORI',
  EU_CUSTOMS_ENTRY_COMMERCIAL_INVOICES_INCORRECT_VAT = 'EU_CUSTOMS_ENTRY_COMMERCIAL_INVOICES_INCORRECT_VAT',
  EU_CUSTOMS_ENTRY_COMMERCIAL_INVOICES_INCORRECT_ENTITY = 'EU_CUSTOMS_ENTRY_COMMERCIAL_INVOICES_INCORRECT_ENTITY',
  EU_CUSTOMS_ENTRY_COMMERCIAL_INVOICES_OTHER_PROBLEMS = 'EU_CUSTOMS_ENTRY_COMMERCIAL_INVOICES_OTHER_PROBLEMS',

  // Packing List
  EU_CUSTOMS_ENTRY_PACKING_LIST_MISSING_DATA = 'EU_CUSTOMS_ENTRY_PACKING_LIST_MISSING_DATA',
  EU_CUSTOMS_ENTRY_PACKING_LIST_INCORRECT_DATA = 'EU_CUSTOMS_ENTRY_PACKING_LIST_INCORRECT_DATA',
  EU_CUSTOMS_ENTRY_PACKING_LIST_INCORRECT_EORI = 'EU_CUSTOMS_ENTRY_PACKING_LIST_INCORRECT_EORI',
  EU_CUSTOMS_ENTRY_PACKING_LIST_INCORRECT_VAT = 'EU_CUSTOMS_ENTRY_PACKING_LIST_INCORRECT_VAT',
  EU_CUSTOMS_ENTRY_PACKING_LIST_INCORRECT_ENTITY = 'EU_CUSTOMS_ENTRY_PACKING_LIST_INCORRECT_ENTITY',
  EU_CUSTOMS_ENTRY_PACKING_LIST_OTHER_PROBLEMS = 'EU_CUSTOMS_ENTRY_PACKING_LIST_OTHER_PROBLEMS',

  // Bill of Lading
  EU_CUSTOMS_ENTRY_BILL_OF_LADING_MISSING_DATA = 'EU_CUSTOMS_ENTRY_BILL_OF_LADING_MISSING_DATA',
  EU_CUSTOMS_ENTRY_BILL_OF_LADING_INCORRECT_EORI = 'EU_CUSTOMS_ENTRY_BILL_OF_LADING_INCORRECT_EORI',
  EU_CUSTOMS_ENTRY_BILL_OF_LADING_INCORRECT_VAT = 'EU_CUSTOMS_ENTRY_BILL_OF_LADING_INCORRECT_VAT',
  EU_CUSTOMS_ENTRY_BILL_OF_LADING_INCORRECT_ENTITY = 'EU_CUSTOMS_ENTRY_BILL_OF_LADING_INCORRECT_ENTITY',
  EU_CUSTOMS_ENTRY_BILL_OF_LADING_OTHER_PROBLEMS = 'EU_CUSTOMS_ENTRY_BILL_OF_LADING_OTHER_PROBLEMS',

  // Regulatory Documents
  EU_CUSTOMS_ENTRY_REGULATORY_DOCUMENTS_MISSING = 'EU_CUSTOMS_ENTRY_REGULATORY_DOCUMENTS_MISSING',
  EU_CUSTOMS_ENTRY_REGULATORY_DOCUMENTS_INCORRECT = 'EU_CUSTOMS_ENTRY_REGULATORY_DOCUMENTS_INCORRECT',
  EU_CUSTOMS_ENTRY_REGULATORY_DOCUMENTS_INCORRECT_ENTITY = 'EU_CUSTOMS_ENTRY_REGULATORY_DOCUMENTS_INCORRECT_ENTITY',
  EU_CUSTOMS_ENTRY_REGULATORY_DOCUMENTS_OTHER_PROBLEMS = 'EU_CUSTOMS_ENTRY_REGULATORY_DOCUMENTS_OTHER_PROBLEMS',

  // Import Control Documents
  EU_CUSTOMS_ENTRY_IMPORT_CONTROL_DOCUMENTS_MISSING = 'EU_CUSTOMS_ENTRY_IMPORT_CONTROL_DOCUMENTS_MISSING',
  EU_CUSTOMS_ENTRY_IMPORT_CONTROL_DOCUMENTS_INCORRECT = 'EU_CUSTOMS_ENTRY_IMPORT_CONTROL_DOCUMENTS_INCORRECT',
  EU_CUSTOMS_ENTRY_IMPORT_CONTROL_DOCUMENTS_INCORRECT_ENTITY = 'EU_CUSTOMS_ENTRY_IMPORT_CONTROL_DOCUMENTS_INCORRECT_ENTITY',
  EU_CUSTOMS_ENTRY_IMPORT_CONTROL_DOCUMENTS_OTHER_PROBLEMS = 'EU_CUSTOMS_ENTRY_IMPORT_CONTROL_DOCUMENTS_OTHER_PROBLEMS',

  // FTA Documents
  EU_CUSTOMS_ENTRY_FTA_DOCUMENTS_INCORRECT_DETAILS = 'EU_CUSTOMS_ENTRY_FTA_DOCUMENTS_INCORRECT_DETAILS',
  EU_CUSTOMS_ENTRY_FTA_DOCUMENTS_INCORRECT = 'EU_CUSTOMS_ENTRY_FTA_DOCUMENTS_INCORRECT',
  EU_CUSTOMS_ENTRY_FTA_DOCUMENTS_INCORRECT_ENTITY = 'EU_CUSTOMS_ENTRY_FTA_DOCUMENTS_INCORRECT_ENTITY',
  EU_CUSTOMS_ENTRY_FTA_DOCUMENTS_OTHER_PROBLEMS = 'EU_CUSTOMS_ENTRY_FTA_DOCUMENTS_OTHER_PROBLEMS',

  // GSP Documents
  EU_CUSTOMS_ENTRY_GSP_DOCUMENTS_INCORRECT_DETAILS = 'EU_CUSTOMS_ENTRY_GSP_DOCUMENTS_INCORRECT_DETAILS',
  EU_CUSTOMS_ENTRY_GSP_DOCUMENTS_INCORRECT = 'EU_CUSTOMS_ENTRY_GSP_DOCUMENTS_INCORRECT',
  EU_CUSTOMS_ENTRY_GSP_DOCUMENTS_INCORRECT_ENTITY = 'EU_CUSTOMS_ENTRY_GSP_DOCUMENTS_INCORRECT_ENTITY',
  EU_CUSTOMS_ENTRY_GSP_DOCUMENTS_OTHER_PROBLEMS = 'EU_CUSTOMS_ENTRY_GSP_DOCUMENTS_OTHER_PROBLEMS',

  // Other Problems
  EU_CUSTOMS_ENTRY_OTHER_PROBLEMS = 'EU_CUSTOMS_ENTRY_OTHER_PROBLEMS',
}

export enum EuAbacusReasonCode {
  EU_CUSTOMS_ENTRY_EORI_NUMBER_INACCURATE = 'EU_CUSTOMS_ENTRY_EORI_NUMBER_INACCURATE',
  EU_CUSTOMS_ENTRY_VAT_NUMBER_INACCURATE = 'EU_CUSTOMS_ENTRY_VAT_NUMBER_INACCURATE',
  EU_CUSTOMS_ENTRY_UNPAID_DUTY = 'EU_CUSTOMS_ENTRY_UNPAID_DUTY',
  EU_CUSTOMS_ENTRY_ADDITIONAL_INFORMATION_NEEDED = 'EU_CUSTOMS_ENTRY_ADDITIONAL_INFORMATION_NEEDED',
}

export const WorkOrderInquiryReasonCode = {
  ...UsIsfInquiryReasonCode,
  ...UsConsumptionEntryInquiryReasonCode,
  ...UsType86EntryInquiryReasonCode,
  ...UsIorActivationInquiryReasonCode,
  ...UsInBondInquiryReasonCode,
  ...EuCustomsEntryInquiryReasonCode,
  ...EuAbacusReasonCode,
};

export type WorkOrderInquiryReasonCode =
  | UsIsfInquiryReasonCode
  | UsConsumptionEntryInquiryReasonCode
  | UsType86EntryInquiryReasonCode
  | UsIorActivationInquiryReasonCode
  | UsInBondInquiryReasonCode
  | EuCustomsEntryInquiryReasonCode
  | EuAbacusReasonCode;
