import { omit } from 'lodash';
import { RecordType } from '@xbcb/shared-types';
import { nameMap } from './nameMap';
export * from './mapToAllUserTypes';
export * from './nameMap';

export const isNameMapKey = (
  recordType: string,
): recordType is keyof typeof nameMap => {
  return !!nameMap[recordType as keyof typeof nameMap];
};

export const spreadsheetFields = (recordType: RecordType) => {
  if (isNameMapKey(recordType)) {
    let displayMap = JSON.parse(JSON.stringify(nameMap[recordType]));
    delete displayMap.shipperName;
    delete displayMap.shipperId;
    delete displayMap.supplierName;
    delete displayMap.countryName;
    if (recordType === RecordType.PRODUCT) {
      delete displayMap.htsNumber;
      delete displayMap.unitValue;
      delete displayMap.unitAssist;
      displayMap = {
        ...omit(displayMap, [
          'htsNumber',
          'unitValue',
          'duty',
          'createdByName',
          'cbpDescription',
        ]),
        htsNumber1: 'HTS Number',
        unitValue1: 'Unit Value',
        unitAssist1: 'Unit Assist',
        uom1: 'First Unit of Measure',
        uom2: 'Second Unit of Measure',
        uom3: 'Third Unit of Measure',
        disclaimPga: 'Disclaim PGA',
        fdaProductCode: 'FDA Product Code',
        poNumber: 'PO Number',
      };
    } else if (recordType === RecordType.SUPPLIER) {
      displayMap = {
        ...omit(displayMap, ['stateName']),
        stateCode: 'State Code',
      };
    }
    return displayMap;
  } else {
    return {};
  }
};
