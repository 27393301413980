import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';

const defaultSign = {};

const signReducer = handleActions(
  {
    SIGN_CHANGE: (state, { payload }) => ({ ...state, ...payload }),
  },
  defaultSign,
);

export default combineReducers({ sign: signReducer });
