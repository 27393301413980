import React from 'react';
import { FormInstance } from 'antd/lib/form';
import { CssSize } from '@xbcb/ui-types';
import SimpleSelect from '../SimpleSelect';

interface CBPNumberReasonSelectProps {
  readOnly?: boolean;
  disabled?: boolean;
  form: FormInstance;
  initialValue?: string[];
}

const CBPNumberReasonSelect: React.FC<CBPNumberReasonSelectProps> = ({
  readOnly,
  form,
  disabled,
  initialValue,
}) => (
  <SimpleSelect
    label="Indicate the reason(s) you are applying for a CBP-assigned number. Check all that apply."
    multiple={true}
    required={true}
    localNamePath={['cbpNumberReasons']}
    fullNamePath={['cbpNumberReasons']}
    readOnly={readOnly}
    disabled={disabled}
    form={form}
    dropdownMatchSelectWidth={false}
    initialValue={initialValue}
    map={{
      HAS_SSN_NEEDS_CBP:
        'I have an SSN, but wish to use a CBP-Assigned Number on all my entry documents',
      NO_SSN: 'I have no Social Security Number',
      NO_IRS: 'I have no IRS Number',
      NO_IRS_OR_SSN: 'I have not applied for an IRS number or SSN',
      NOT_US_RESIDENT: 'I am not a US Resident',
    }}
    $itemSize={CssSize.MEDIUM_LARGE}
  />
);

export default CBPNumberReasonSelect;
